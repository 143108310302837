import { routes } from '../constants';
import {
  Call,
  Conversation,
  DetailFemale,
  FollowingPage,
  PaymentMethod,
  Home,
  Login,
  Message,
  MyPage,
  NotFound,
  Search,
  Signup,
  Payment,
  GamePage,
  FemaleMet,
  OrderHistory,
  CreatePost,
  ReviewGirl,
  HowToUse,
  FAQ,
  AppInfo,
  PreviewMale,
  EditMale,
  StarDetail,
  EditMalePage,
  Travel,
} from 'pages';

const routers = [
  {
    path: routes.SEARCH,
    component: Search,
  },
  {
    path: routes.GAME_PAGE,
    component: GamePage,
  },
  {
    path: routes.DETAIL_FEMALE,
    component: DetailFemale,
  },
  {
    path: routes.HOME,
    component: Home,
  },
  {
    path: routes.CREATE_POST,
    component: CreatePost,
    isProtected: true,
  },
  {
    path: routes.MY_PAGE,
    component: MyPage,
    isProtected: true,
  },
  {
    path: routes.EDIT_MALE,
    component: EditMalePage,
    isProtected: true,
    children: [
      {
        path: '',
        component: EditMale,
        index: true,
      },
      {
        path: 'preview',
        component: PreviewMale,
      },
    ],
  },
  {
    path: routes.STAR_DETAIL,
    component: StarDetail,
    isProtected: true,
  },
  {
    path: routes.HOW_TO_USE,
    component: HowToUse,
    isProtected: true,
  },
  {
    path: routes.FAQ,
    component: FAQ,
    isProtected: true,
  },
  {
    path: routes.APP_INFO,
    component: AppInfo,
  },
  {
    path: routes.FOLLOWING,
    component: FollowingPage,
    isProtected: true,
  },
  {
    path: routes.FEMALE_MET,
    component: FemaleMet,
    isProtected: true,
  },
  {
    path: routes.ORDER_HISTORY,
    component: OrderHistory,
    isProtected: true,
  },
  {
    path: routes.PAYMENT_METHOD,
    component: PaymentMethod,
    isProtected: true,
  },
  {
    path: routes.CALL,
    component: Call,
  },
  {
    path: routes.ORDER_TRAVEL,
    component: Travel,
  },
  {
    path: routes.MESSAGE,
    component: Message,
  },
  {
    path: routes.CONVERSATION,
    component: Conversation,
  },
  {
    path: routes.PAYMENT,
    component: Payment,
    isProtected: true,
  },
  {
    path: routes.REVIEW_GIRL,
    component: ReviewGirl,
  },
  {
    path: routes.LOGIN,
    component: Login,
  },
  {
    path: routes.SIGNUP,
    component: Signup,
  },
  {
    path: '*',
    component: NotFound,
  },
];

export default routers;
