import { useQuery } from '@tanstack/react-query';
import { TEXT_STRING, queryKeys } from '../../../../constants';
import { useCallback, useMemo } from 'react';
import { getProjectGame } from 'api';
import CardGame from 'components/OrderScreen/CardGame';
import ListItem from 'components/MyPage/ListItem';
import { Loading, Text } from 'components';
import { EGameType, TGames, TProjectFemale } from 'types';
import OrderFooter from 'components/OrderScreen/OrderFooter';
import { getFormattedAmount } from 'utils';

const CHAT_STR = TEXT_STRING.CHAT;
const COMMON_STR = TEXT_STRING.COMMON;

type Props = {
  projectId: number;
  listGirlInMeeting: TProjectFemale[];
  onContinue: () => void;
  onSelectGame: (game: TGames) => void;
  onGoToListGame: () => void;
  selectedGame: TGames[];
  disabledAllGame: boolean;
};

function SelectGame({
  projectId,
  listGirlInMeeting,
  onContinue,
  onSelectGame,
  selectedGame,
  disabledAllGame,
  onGoToListGame,
}: Props) {
  // Hook
  const { data, isLoading } = useQuery({
    queryKey: [queryKeys.GAMES, projectId],
    queryFn: () => getProjectGame(projectId),
  });

  const totalPriceGame = useMemo(() => {
    let total = 0;
    const listGirlNotPlayedKingGame = listGirlInMeeting.filter(
      (girl) => girl.specialGameType !== EGameType.GROUP_KING
    );

    selectedGame.forEach((game) => {
      if (game.type === EGameType.GROUP_SPECIAL) {
        total +=
          listGirlNotPlayedKingGame.filter(
            (girl) => game.id !== girl.specialGameId
          ).length * game.price;
      } else {
        total += game.price * listGirlNotPlayedKingGame.length;
      }
    });

    return total;
  }, [listGirlInMeeting, selectedGame]);

  const isDisabledGame = useCallback(
    (game: TGames) => {
      const isDisabledKingGame = data?.some(
        (game) => game.type === EGameType.GROUP_KING && game.disabled
      );

      if (isDisabledKingGame) {
        return true;
      } else {
        return disabledAllGame
          ? game.type !== EGameType.GROUP_KING
          : game.disabled;
      }
    },
    [data, disabledAllGame]
  );

  return (
    <>
      <ListItem
        text={
          <Text bold fontSize={14}>
            {CHAT_STR.ORDERED_GAME}
          </Text>
        }
        className="font-bold cursor-pointer"
        onClick={onGoToListGame}
      />
      <div className="flex my-12px">
        <Text bold fontSize={14}>
          {CHAT_STR.LIST_GAME}
        </Text>
        <Text fontSize={14} textColor="text-neutral">
          {COMMON_STR.MULTIPLE_CHOICE}
        </Text>
      </div>
      {isLoading ? (
        <Loading />
      ) : (
        <div className="grid grid-cols-3 gap-8px">
          {data?.map((game) => {
            return (
              <CardGame
                key={game.id}
                data={game}
                isActive={selectedGame.some((item) => item.id === game.id)}
                onChoose={() => onSelectGame(game)}
                disabled={isDisabledGame(game)}
              />
            );
          })}
        </div>
      )}
      <OrderFooter
        onSubmit={onContinue}
        textButton={CHAT_STR.ADD_GAME}
        className="mt-16px"
        estimatePrice={
          <Text fontSize={14}>
            {getFormattedAmount(totalPriceGame)}
            {COMMON_STR.YEN}
          </Text>
        }
        disabled={!selectedGame?.length}
      />
    </>
  );
}

export default SelectGame;
