import NoAvatar from '../../assets/images/avatar.svg';
type Props = {
  size?: `w-${string}` | `h-${string}`;
  avatars: Array<{ url?: string; disabled?: boolean }>;
};

const MAX_IMAGES = 3;

function AvatarGroup({ size = `w-full`, avatars }: Props) {
  return (
    <div className={`flex items-center -space-x-4 ${size}`}>
      {avatars.slice(0, MAX_IMAGES).map(({ url, disabled }, index) => {
        return (
          <img
            key={index}
            alt=""
            src={url || NoAvatar}
            className={`relative inline-block h-full aspect-square object-cover object-center rounded-full border border-white hover:z-10 focus:z-10 ${
              disabled ? 'opacity-50' : ''
            }`}
            onError={(e) => (e.currentTarget.src = NoAvatar)}
          />
        );
      })}
    </div>
  );
}

export default AvatarGroup;
