import React from 'react';

type Props = {
  steps: { img: string; text: string; reverse?: boolean }[];
};

function TutorialList({ steps }: Props) {
  return (
    <ul className="flex flex-col justify-between flex-1 list-decimal gap-5">
      {steps.map((step, index) => {
        const isReverse = step.reverse;
        const haveImage = !!step.img;

        return (
          <div
            key={index}
            className={`flex items-center ${
              isReverse ? 'flex-row-reverse pl-7 gap-10' : 'gap-14'
            } ${!haveImage && 'pl-7'}`}
          >
            {haveImage && (
              <img
                src={step.img}
                alt=""
              />
            )}
            <li>{step.text}</li>
          </div>
        );
      })}
    </ul>
  );
}

export default TutorialList;
