import IconButton from 'components/IconButton';
import TextField from 'components/TextField';
import React from 'react';
import { TTextFieldChangeEvent } from 'types';
import UploadFile from '../UploadFile';
import { IMAGE_ACCEPTED, TEXT_STRING } from '../../../constants';
import FileView from 'components/FileView';

type Props = {
  sendMessage: (message: string, file?: File) => Promise<void>;
  onTyping: (status: boolean) => void;
  showTyping: boolean;
  onBlur?: () => void;
  onFocus?: () => void;
  onDating?: () => void;
};

let timeout: NodeJS.Timeout;

const TYPING_TIME = 3000;

const MessageInput = ({
  sendMessage,
  onTyping,
  showTyping,
  onBlur,
  onFocus,
  onDating,
}: Props) => {
  const ref = React.useRef(true);
  const inputRef = React.useRef<HTMLInputElement>(null);

  const [message, setMessage] = React.useState<string>('');
  const [files, setFiles] = React.useState<File[]>([]);
  const [isTyping, setIsTyping] = React.useState<boolean>(false);
  const [isSending, setIsSending] = React.useState<boolean>(false);
  const [buttonClicked, setButtonClicked] = React.useState<boolean>(false);

  const onChangeFiles = (files: File[]) => {
    setFiles(files);
  };

  const onChangeMessage = React.useCallback(
    (event: TTextFieldChangeEvent) => {
      setMessage(event.target.value);
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(() => {
        setIsTyping(false);
      }, TYPING_TIME);
      setIsTyping(true);
    },
    [setMessage]
  );

  const onSendMessage = React.useCallback(() => {
    if (inputRef.current) inputRef.current.focus();

    if (message.trim() || files.length) {
      setIsSending(true);
      sendMessage(message?.trim(), files?.[0])
        .then(() => {
          onTyping(false);
          setMessage('');
          setFiles([]);
        })
        .finally(() => {
          setIsSending(false);
        });
    }
  }, [message, files, sendMessage, setMessage, onTyping, setFiles]);

  const handleBlur = React.useCallback(() => {
    if (buttonClicked) return setButtonClicked(false);
    onBlur && onBlur();
  }, [buttonClicked, onBlur]);

  React.useEffect(() => {
    if (!ref.current) {
      onTyping(isTyping);
    } else ref.current = false;
    return () => {};
  }, [isTyping, onTyping]);

  return (
    <div>
      {!!files?.length && (
        <div className="flex gap-4 overflow-x-auto p-2 h-24">
          {files.map((file, index) => (
            <FileView
              className="h-full"
              key={index}
              file={file}
              onRemove={() => {
                setFiles((prev) => prev.filter((_, idx) => idx !== index));
              }}
            />
          ))}
        </div>
      )}
      {showTyping && (
        <div className="flex space-x-2 items-end px-2">
          <p className="">{TEXT_STRING.COMMON.TYPING}</p>
          <div className="w-2 h-2 bg-gray-500 rounded-full animate-pulse [animation-delay:-0.05s] mb-1" />
          <div className="w-2 h-2 bg-gray-500 rounded-full animate-pulse [animation-delay:-0.025s] mb-1" />
          <div className="w-2 h-2 bg-gray-500 rounded-full animate-pulse mb-1" />
        </div>
      )}

      <div className="flex gap-6 items-center pl-6 pr-5 py-6 rounded-lg relative">
        {onDating && (
          <IconButton
            icon="calendarHeart"
            onClick={onDating}
            className="scale-[1.75] mb-4px"
            transparent
            disabled={isSending}
          />
        )}
        <UploadFile
          onChangeFiles={onChangeFiles}
          accept={IMAGE_ACCEPTED.join(',')}
          disabled={isSending}
          buttonClass="scale-[1.75]"
        />
        <TextField
          type="textarea"
          name="message"
          onChange={onChangeMessage}
          value={message}
          rows={Math.min(message.split('\n').length || 1, 5)}
          className="w-full"
          inputClass="resize-none py-4"
          placeholder={TEXT_STRING.MESSAGE.INPUT_PLACEHOLDER}
          // disabled={isSending}
          inputRef={inputRef}
          onBlur={handleBlur}
          onFocus={onFocus}
        />
        <div onMouseDown={() => setButtonClicked(true)}>
          <IconButton
            icon="sendMessage"
            onClick={onSendMessage}
            className="text-blue-600 scale-[1.75]"
            transparent
            disabled={isSending}
          />
        </div>
      </div>
    </div>
  );
};

export default MessageInput;
