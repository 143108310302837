import axios from 'axios';
import { TSignedUrl } from 'types';

const getSignUrl = async (body: {
  filename: string;
  contentType: string;
  size: number;
}): Promise<TSignedUrl> => {
  return await axios.post('upload/signed-url', body).then((response) => {
    return response.data;
  });
};

const uploadFile = async (preSignedUrl: string, file: File) => {
  return await fetch(preSignedUrl, { method: 'PUT', body: file });
};

export { getSignUrl, uploadFile };
