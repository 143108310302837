import { Avatar, Button, Header, Loading, Text } from 'components';
import { PAGE_SIZE, TEXT_STRING, queryKeys } from '../../../constants';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { formatDate } from 'utils';
import { useFollow } from 'hooks';
import { useInfiniteQuery } from '@tanstack/react-query';
import { getFemalesMet } from 'api';
import InfiniteScroll from 'react-infinite-scroll-component';
import { TFemaleMet } from 'types';

const STR = TEXT_STRING.MY_PAGE;
const DETAIL_STR = TEXT_STRING.CAST_PAGE;

function FemaleMet() {
  // State
  const [listFollowed, setListFollowed] = useState<number[]>([]);

  // Query
  const { data, isLoading, fetchNextPage, hasNextPage, isFetching } =
    useInfiniteQuery({
      queryKey: [queryKeys.FEMALES_MET],
      queryFn: async ({ pageParam }) => getFemalesMet(pageParam, PAGE_SIZE),
      initialPageParam: 1,
      getNextPageParam: (lastPage, _, currentPage) => {
        return lastPage?.totalPage > currentPage ? currentPage + 1 : undefined;
      },
    });

  // Hook
  const { handleFollow, loadingFollow } = useFollow();

  const listView = useMemo(
    () => data?.pages.map(({ data }) => data).flat(),
    [data?.pages]
  );

  const listViewFiltered = useMemo(() => {
    const groupedData: {
      date: string;
      area: { id: number; name: string };
      data: TFemaleMet[];
    }[] = [];

    listView?.forEach((item) => {
      const dateTime = new Date(item.startTime).toDateString();

      let group = groupedData.find(
        (g) => g.date === dateTime && g.area.id === item.area?.id
      );

      if (!group) {
        group = {
          date: dateTime,
          area: { id: item.area?.id, name: item.area?.name },
          data: [],
        };
        groupedData.push(group);
      }

      group.data.push(item);
    });

    return groupedData;
  }, [listView]);

  const handleFollowing = useCallback(
    (userId: number, follow: boolean) => {
      setListFollowed((prev) => {
        if (prev.find((id) => id === userId)) {
          return prev.filter((id) => id !== userId);
        } else {
          return [...prev, userId];
        }
      });
      handleFollow(userId, follow);
    },
    [handleFollow]
  );

  // Effect
  useEffect(() => {
    if (!!listView?.length && !isFetching) {
      const list = listView.reduce((result: number[], current: TFemaleMet) => {
        if (current.isFollowed) {
          result.push(current.femaleId);
        }
        return result;
      }, []);
      setListFollowed((prev) => [...prev, ...list]);
    }
  }, [isFetching, listView]);

  if (isLoading) {
    return <Loading fullScreen />;
  }

  return (
    <div className="h-full w-full overflow-y-auto" id="scrollFemaleMet">
      <Header
        title={
          <Text fontSize={14} bold>
            {STR.FEMALE_MET_TEXT}
          </Text>
        }
      />
      <InfiniteScroll
        next={fetchNextPage}
        hasMore={hasNextPage}
        loader={
          <div className="p-4 bg-neutral-25 flex items-center gap-4">
            <div className="w-24 h-24 rounded-full animate-pulse bg-gray-400"></div>
            <div className="flex-1 h-10 animate-pulse bg-gray-400"></div>
          </div>
        }
        dataLength={listView?.length || 0}
        scrollThreshold={0.5}
        scrollableTarget="scrollFemaleMet"
        className="flex flex-col gap-8"
      >
        {listViewFiltered?.length ? (
          listViewFiltered.map((item, index) => {
            return (
              <div className="w-full mt-5" key={index}>
                <Text className="mb-2 ml-4">{`${formatDate(
                  item.date,
                  'LL'
                )} - ${item?.area?.name || ''}`}</Text>
                {item.data?.map((female) => {
                  const isFollowing = listFollowed.includes(female.femaleId);
                  const isLoadingFollow = loadingFollow?.includes(
                    female.femaleId
                  );

                  return (
                    <div
                      className="bg-neutral-25 flex items-center justify-between w-full p-4"
                      key={female.femaleId}
                    >
                      <div
                        className={`flex items-center gap-4 ${
                          female.inactive ? 'opacity-50' : ''
                        }`}
                      >
                        <Avatar url={female.avatar} size="w-24" circle />
                        <Text className="line-clamp-1">{female.nickname}</Text>
                      </div>
                      <Button
                        onClick={() =>
                          handleFollowing(female.femaleId, isFollowing)
                        }
                        bgColor={
                          isLoadingFollow
                            ? 'bg-error-200'
                            : isFollowing
                            ? ''
                            : 'bg-error-400'
                        }
                        textColor={
                          isFollowing && !isLoadingFollow
                            ? 'text-error-400'
                            : 'text-white'
                        }
                        border={`${
                          !isLoadingFollow
                            ? 'border-error-400'
                            : 'pointer-events-none'
                        } ${isFollowing ? 'border' : ''}`}
                        className="text-[14px] w-1/3"
                        bold
                        disabled={female.inactive}
                      >
                        {isFollowing ? DETAIL_STR.FOLLOWING : DETAIL_STR.FOLLOW}
                      </Button>
                    </div>
                  );
                })}
              </div>
            );
          })
        ) : (
          <Text bold center className="py-16">
            {TEXT_STRING.COMMON.NO_DATA}
          </Text>
        )}
      </InfiniteScroll>
    </div>
  );
}

export default FemaleMet;
