import { Avatar, Button, Modal, Text } from 'components';
import { TEXT_STRING } from '../../../constants';
import React from 'react';

const COMMON_STR = TEXT_STRING.COMMON;
const MY_PAGE_STR = TEXT_STRING.MY_PAGE;

type Props = {
  image?: string | File;
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  loading?: boolean;
};

function ModalConfirmImage({
  image,
  open = false,
  onClose,
  onConfirm,
  loading = false,
}: Props) {
  return (
    <Modal
      open={open}
      className="p-8 text-center mx-5 bg-white w-full"
      onClose={onClose}
    >
      <div className="flex flex-col items-center gap-10">
        <Text
          fontSize={14}
          bold
        >
          {MY_PAGE_STR.PREVIEW_IMAGE}
        </Text>
        <div>
          {image && (
            <Avatar
              url={
                typeof image === 'string' ? image : URL.createObjectURL(image)
              }
              circle
              size="w-48"
            />
          )}
        </div>
        <div className="flex gap-8 justify-center w-full">
          <Button
            onClick={onClose}
            bgColor="bg-white"
            textColor="text-neutral"
            border="border border-neutral"
            className="basis-1/3"
            textSize="text-sm"
          >
            {COMMON_STR.CANCEL}
          </Button>
          <Button
            onClick={onConfirm}
            className="basis-1/3"
            loading={loading}
            disabled={loading}
            textSize="text-sm"
          >
            {COMMON_STR.KEEP}
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default ModalConfirmImage;
