import React, { useCallback, useMemo } from 'react';
import Modal from '../Modal';
import { storageKeys, TEXT_STRING, tutorialModal } from '../../constants';
import Button from 'components/Button';
import Text from 'components/Text';
import { detectSystemMobile } from 'utils';
import { ESystemMobile } from 'types';
import TutorialList from './TutorialList';
import { useSettingStore } from 'store';

function TutorialModal() {
  // Hook
  const { openTutorial, setOpenTutorial } = useSettingStore();
  const tutorial = useMemo(
    () =>
      detectSystemMobile() === ESystemMobile.IOS
        ? tutorialModal.iphone
        : tutorialModal.android,
    []
  );

  const onConfirm = useCallback(() => {
    setOpenTutorial(false);
    localStorage.setItem(storageKeys.IS_CONFIRM_TUTORIAL, 'true');
  }, [setOpenTutorial]);

  return (
    <Modal
      open={openTutorial}
      onClose={() => {}}
      className="bg-white h-[90dvh] p-6 mx-6 flex flex-col justify-between gap-8 overflow-y-auto text-sm"
    >
      <Text
        bold
        center
      >
        {tutorial.title}
      </Text>
      <TutorialList steps={tutorial.steps} />
      <div className="sticky bottom-0 w-full">
        <Button
          onClick={onConfirm}
          className="p-4 w-full"
          bold
        >
          {TEXT_STRING.COMMON.CONTINUE}
        </Button>
      </div>
    </Modal>
  );
}

export default TutorialModal;
