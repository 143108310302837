import Icon from 'components/Icon';
import React from 'react';

type ButtonProps = React.PropsWithChildren<{
  className?: string;
  onClick(): void;
  disabled?: boolean;
  loading?: boolean;
  rounded?: `rounded-${string}`;
  outline?: boolean;
  iconLeft?: string;
  iconRight?: string;
  block?: boolean;
  bold?: boolean;
  textColor?: `text-${string}`;
  bgColor?: string;
  border?: string;
  textSize?: string;
  iconColor?: string;
  padding?: string;
  loadingBorder?: `border-${string}`;
}>;

const Button = ({
  children,
  className = '',
  onClick,
  disabled = false,
  loading,
  iconLeft,
  iconRight,
  rounded = 'rounded-[8px]',
  block,
  bold,
  textColor = 'text-white',
  bgColor = 'bg-primary hover:bg-primary-400',
  border = '',
  textSize = '',
  iconColor,
  padding = 'p-4',
  loadingBorder = 'border-white',
}: ButtonProps): JSX.Element => {
  return (
    <button
      disabled={disabled || loading}
      onClick={onClick}
      className={`${className} 
            relative flex justify-center items-center gap-2.5 ${padding}
            ${bold ? 'font-bold' : 'font-normal'}
            ${block ? 'w-full' : ''}
            ${rounded}
            ${textColor} ${bgColor} ${border} ${textSize}
            ${disabled ? 'opacity-50 pointer-events-none' : ''}`}
    >
      {loading && (
        <div
          className={`absolute flex justify-center items-center w-full h-full ${rounded} ${bgColor}`}
        >
          <div
            className={`h-5 w-5 border-t-transparent border-solid animate-spin rounded-full border-2 ${loadingBorder}`}
          ></div>
        </div>
      )}
      <>
        {iconLeft && <Icon icon={iconLeft} color={iconColor} />}
        {children}
        {iconRight && <Icon icon={iconRight} color={iconColor} />}
      </>
    </button>
  );
};

export default Button;
