import React, { useMemo } from 'react';
import { Fragment, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { detectMobile } from 'utils';

const POSITIONS = {
  top: 'items-top',
  center: 'items-center',
  bottom: 'items-bottom',
};

type Props = React.PropsWithChildren<{
  className?: string;
  open: boolean;
  onClose: () => void;
  position?: keyof typeof POSITIONS;
  rounded?: `rounded-${string}`;
  w?: `w-${string}`;
}>;

function Modal({
  className = '',
  children,
  open,
  onClose,
  position = 'center',
  rounded = 'rounded-lg',
  w = 'w-full',
}: Props) {
  const panelRef = useRef(null);
  const isMobile = useMemo(() => detectMobile(), []);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className={`relative z-10`}
        initialFocus={panelRef}
        onClose={onClose}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div
          className={`fixed inset-0 z-10 overflow-hidden ${
            !isMobile ? `w-[50vh] mx-auto translate-x-0` : `w-full`
          }`}
        >
          <div
            className={`flex min-h-full justify-center text-center sm:p-0 ${POSITIONS[position]}`}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                ref={panelRef}
                className={`${className} ${rounded} ${w} relative transform overflow-hidden text-left shadow-xl transition-all`}
              >
                {children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default Modal;
