import Icon from 'components/Icon';
import { TEXT_STRING } from '../../../constants';
import Rating from 'components/Rating';
import Text from 'components/Text';
import React from 'react';

type Props = {
  star?: number;
  onRedirect?: () => void;
  className?: string;
};

function ProfileRank({ star, onRedirect, className }: Props) {
  return (
    <div
      className={`${className} w-full rounded-2xl shadow-lg border border-solid border-neutral-50`}
    >
      <div className="w-full bg-black py-2 rounded-t-2xl">
        <Text fontSize={16} textColor="text-white" bold center>
          {TEXT_STRING.PROFILE_RANK.GUEST_RANK}
        </Text>
      </div>
      <div
        className="rounded-b-2xl bg-primary-50 py-6 relative flex justify-center items-center cursor-pointer"
        onClick={onRedirect}
      >
        <Rating value={star} isBlingStar className="justify-center gap-4" />
        {onRedirect && (
          <div className="absolute right-6 scale-150">
            <Icon icon={'chevronRight'} />
          </div>
        )}
      </div>
    </div>
  );
}

export default ProfileRank;
