import moment, { Moment } from 'moment';

type TDateTime = string | Date | Moment;

const combineDateTime = (d: TDateTime, t: TDateTime) => {
  const date = moment(d);
  const time = moment(t, 'HH:mm');
  const dateTime = date.set({
    hour: time.hour(),
    minute: time.minute(),
  });

  return dateTime;
};

export default combineDateTime;
