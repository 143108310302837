import { createContext } from 'react';

type TPostContext = {
  galleryWidth?: number;
  setGalleryWidth: React.Dispatch<React.SetStateAction<number | undefined>>;
};

const PostContext = createContext<TPostContext>({ setGalleryWidth: () => {} });

export default PostContext;
