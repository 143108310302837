import Text from 'components/Text';
import { TEXT_STRING } from '../../constants';
import { useCallback, useEffect, useMemo, useState } from 'react';
import Select from 'components/Select';
import DatePicker from 'components/DatePicker';
import TimePicker from 'components/TimePicker';
import { TFemale, TProjectInfo } from 'types';
import moment, { Moment } from 'moment';
import Label from 'components/Label';
import OrderFooter from './OrderFooter';
import { FIELD_NAME } from 'helpers';
import SearchFemale from './SearchFemale';

const STR = TEXT_STRING.CALL_WOMAN;
const COMMON_STR = TEXT_STRING.COMMON;
const ORDER_STR = TEXT_STRING.ORDER_DETAIL;

type Props = {
  projectInfo: TProjectInfo | undefined;
  formState: Record<string, string>;
  setFormState: React.Dispatch<React.SetStateAction<Record<string, string>>>;
  listGirl: TFemale[];
  onChooseGirl: (value: TFemale) => void;
  clearListGirl: () => void;
  estimatePrice: string;
  setListGirl: React.Dispatch<React.SetStateAction<TFemale[]>>;
  onOrder: () => void;
  textFooterTitle?: string;
  textFooterButton?: string;
  loadingButton?: boolean;
  loadingFemale?: boolean;
  fetchMore: () => void;
  hasMore: boolean;
  listView?: TFemale[];
  isCallNewGirl?: boolean;
  searchFemale: string;
  setSearchFemale: React.Dispatch<React.SetStateAction<string>>;
  disabledArea?: boolean;
};

const CallWoman = ({
  projectInfo,
  formState,
  setFormState,
  listGirl,
  onChooseGirl,
  clearListGirl,
  estimatePrice,
  setListGirl,
  onOrder,
  textFooterTitle = STR.ESTIMATED_AMOUNT,
  textFooterButton = STR.ENTER_DETAIL,
  loadingButton = false,
  loadingFemale = false,
  hasMore,
  fetchMore,
  listView = [],
  isCallNewGirl = false,
  searchFemale,
  setSearchFemale,
  disabledArea = false,
}: Props): JSX.Element => {
  // State
  const [errorFields, setErrorFields] = useState<string[]>([]);
  const [errorMessage, setErrorMessageTime] = useState<string>('');

  // Memo & Callbacks
  const optionSelectGirl = useMemo(() => {
    const maleCount = +formState[FIELD_NAME.MALE_COUNT];
    let filteredOptions = (projectInfo?.optionsPeople || []).map((option) => ({
      ...option,
    }));

    if (maleCount > 1) {
      let singleOption = filteredOptions?.find((option) => +option.value === 1);
      if (singleOption) {
        singleOption.disabled = true;
      }
    }

    return filteredOptions;
  }, [formState, projectInfo?.optionsPeople]);

  const handleChange = useCallback(
    (name: string, value: string) => {
      if (value) {
        setErrorFields((prev) => prev.filter((field) => field !== name));
        setErrorMessageTime('');
      }
      setFormState((prev) => ({ ...prev, [name]: value }));
    },
    [setFormState]
  );

  const isValidDate = useCallback((currentDate: Moment) => {
    const yesterday = moment().subtract(1, 'day');
    return currentDate.isAfter(yesterday);
  }, []);

  const validateDateTime = useCallback(() => {
    const nowDateTime = new Date().toLocaleString('en-US', {
      timeZone: 'Asia/Tokyo',
    });
    const date = moment(formState[FIELD_NAME.DATE]);
    const time = moment(formState[FIELD_NAME.TIME], 'HH:mm');
    const dateTime = date.set({
      hour: time.hour(),
      minute: time.minute(),
    });

    if (dateTime.isSameOrBefore(nowDateTime) || !time.isValid()) {
      setErrorMessageTime(STR.DATE_WARNING);
      return true;
    } else {
      setErrorMessageTime('');
      return false;
    }
  }, [formState]);

  const handleSubmit = useCallback(async () => {
    let errorList = [];
    if (validateDateTime()) {
      errorList.push(FIELD_NAME.DATE);
    }
    setErrorFields(errorList);
    if (!!errorList.length) return;

    setErrorFields([]);
    setErrorMessageTime('');
    onOrder();
  }, [onOrder, validateDateTime]);

  const handleChoosePeople = useCallback(
    (value: string) => {
      if (+value < listGirl.length) {
        clearListGirl();
      }
      setFormState((prev) => ({ ...prev, [FIELD_NAME.PEOPLE]: value }));
    },
    [listGirl.length, setFormState, clearListGirl]
  );

  useEffect(() => {
    const maleCount = +formState[FIELD_NAME.MALE_COUNT];
    const peopleCount = +formState[FIELD_NAME.PEOPLE];
    if (peopleCount === 1 && maleCount > peopleCount) {
      setFormState((prev) => ({
        ...prev,
        [FIELD_NAME.PEOPLE]: prev[FIELD_NAME.MALE_COUNT],
      }));
    }
  }, [formState, setFormState]);

  return (
    <>
      <div className="flex flex-col gap-5">
        {!isCallNewGirl && (
          <Select
            label={ORDER_STR.NUMBER_CUSTOMER}
            name={FIELD_NAME.MALE_COUNT}
            value={formState[FIELD_NAME.MALE_COUNT]}
            onChange={(value) => {
              handleChange(FIELD_NAME.MALE_COUNT, value);
            }}
            options={projectInfo?.optionsPeople ?? []}
            require
            bold
            textSize="text-sm"
          />
        )}
        <div>
          <Label bold className="text-sm leading-8">
            <div className="flex flex-wrap">
              {STR.OPTION_SET}
              {STR.OPTION_PEOPLE}
            </div>
          </Label>
          <div className="flex justify-between mt-2px">
            <div className={`flex-1 pr-6px`}>
              <Select
                name={FIELD_NAME.SET}
                value={formState[FIELD_NAME.SET]}
                onChange={(value) => {
                  handleChange(FIELD_NAME.SET, value);
                }}
                options={projectInfo?.optionsSet ?? []}
              />
            </div>
            <div className="w-1/4 pr-6px">
              <Select
                name={FIELD_NAME.PEOPLE}
                value={formState[FIELD_NAME.PEOPLE]}
                onChange={(value) => {
                  handleChoosePeople(value);
                }}
                options={optionSelectGirl}
              />
            </div>
          </div>
        </div>
        <div>
          <div className="flex items-center mb-8px gap-4">
            <Label
              className="text-[14px] leading-8"
              require={true}
              error={
                errorFields.includes(FIELD_NAME.TIME) ||
                errorFields.includes(FIELD_NAME.DATE)
              }
              bold
            >
              {STR.START_TIME}
            </Label>
            <span className="text-error font-bold">{errorMessage}</span>
          </div>
          <div className="flex items-center gap-8px">
            <div className="w-[70%]">
              <DatePicker
                isValidDate={isValidDate}
                value={new Date(formState[FIELD_NAME.DATE])}
                onChange={(date) =>
                  handleChange(FIELD_NAME.DATE, date.toString())
                }
              />
            </div>
            <div className="w-[30%] relative">
              <TimePicker
                name={FIELD_NAME.TIME}
                value={formState[FIELD_NAME.TIME]}
                onChange={(value) => {
                  handleChange(FIELD_NAME.TIME, value);
                }}
                error={errorFields.includes(FIELD_NAME.TIME)}
              />
            </div>
          </div>
        </div>
        <SearchFemale
          formState={formState}
          listGirl={listGirl}
          onChooseGirl={onChooseGirl}
          fetchMore={fetchMore}
          hasMore={hasMore}
          searchFemale={searchFemale}
          setSearchFemale={setSearchFemale}
          listView={listView}
          loadingFemale={loadingFemale}
        />
      </div>
      <OrderFooter
        onSubmit={handleSubmit}
        textTitle={textFooterTitle}
        textButton={textFooterButton}
        loading={loadingButton}
        estimatePrice={
          <>
            <Text fontSize={14}>
              {estimatePrice}
              {COMMON_STR.YEN}
            </Text>
            <Text textColor="text-neutral" fontSize={14}>
              + {STR.GAME_FEE}
            </Text>
          </>
        }
      />
    </>
  );
};

export default CallWoman;
