import React, { useContext, useEffect, useState } from 'react';
import { Button, ModalConfirm, Text } from 'components';
import { routes, TEXT_STRING } from '../../../constants';
import { useMutation } from '@tanstack/react-query';
import { useToast } from 'hooks';
import { approveLeverage, cancelLeverage } from 'api';
import { ConversationContext } from 'contexts';
import { useChatStore, useUserStore } from 'store';
import { useNavigate } from 'react-router-dom';

const CALL_STR = TEXT_STRING.CALL_WOMAN;

type Props = {
  loading?: boolean;
};

type TModalContent = {
  isApproval: boolean;
  title: string;
  type?: 'warning' | 'error';
};

const modalContent: Record<'approve' | 'cancel', TModalContent> = {
  approve: {
    title: CALL_STR.CONFIRM_APPROVE_LEVERAGE,
    isApproval: true,
    type: 'warning',
  },
  cancel: {
    title: CALL_STR.CONFIRM_CANCEL,
    isApproval: false,
  },
};

function LeverageActions({ loading = false }: Props) {
  const { pendingLeverageId } = useContext(ConversationContext);

  // Store
  const { isAuthenticated } = useUserStore();
  const {
    setLeveragePendingConfirmationId,
    leveragePendingConfirmationId,
    removeLeveragePending,
  } = useChatStore();

  // Hook
  const { toastError } = useToast();
  const navigate = useNavigate();

  const [showModal, setShowModal] = useState<boolean>(false);
  const [confirmModal, setConfirmModal] = useState<TModalContent | undefined>();

  const { mutate: mutateCancel, isPending: isCanceling } = useMutation({
    mutationFn: () => {
      return cancelLeverage(pendingLeverageId);
    },
    onSettled(data, error) {
      setShowModal(false);
      removeLeveragePending();
      if (error) {
        toastError(error);
      }
    },
  });

  const { mutate: mutateApprove, isPending: isApproving } = useMutation({
    mutationFn: () => {
      return approveLeverage(pendingLeverageId);
    },
    onSettled(data, error) {
      if (error) {
        toastError(error);
      }
      setShowModal(false);
      removeLeveragePending();
    },
  });

  const actions = React.useMemo<
    {
      title: string;
      action: () => void;
      bg: string;
      color?: `text-${string}`;
      border: string;
      disabled: boolean;
      loading: boolean;
    }[]
  >(
    () => [
      {
        title: CALL_STR.APPROVE_LEVERAGE,
        action: () => {
          if (pendingLeverageId) {
            if (isAuthenticated) {
              setShowModal(true);
              setConfirmModal(modalContent.approve);
            } else {
              setLeveragePendingConfirmationId(pendingLeverageId);
              navigate(routes.SIGNUP);
            }
          }
        },
        bg: 'bg-primary-50',
        color: 'text-primary',
        border: 'ring-1 ring-primary',
        disabled: isApproving || loading,
        loading: isApproving,
      },
      {
        title: CALL_STR.CANCEL_LEVERAGE,
        action: () => {
          if (pendingLeverageId) {
            setShowModal(true);
            setConfirmModal(modalContent.cancel);
          }
        },
        bg: 'bg-primary-50',
        color: 'text-primary',
        border: 'ring-1 ring-primary',
        disabled: isApproving || loading,
        loading: false,
      },
    ],
    [
      isApproving,
      loading,
      isAuthenticated,
      pendingLeverageId,
      setLeveragePendingConfirmationId,
      navigate,
    ]
  );

  useEffect(() => {
    if (isAuthenticated && leveragePendingConfirmationId) {
      setConfirmModal(modalContent.approve);
      setShowModal(true);
    }

    return () => {};
  }, [isAuthenticated, leveragePendingConfirmationId]);

  return (
    <>
      <div className="px-4 mb-2">
        <Text className="p-4 rounded-xl bg-primary-50" center>
          {CALL_STR.CONFIRM_LEVERAGE}
        </Text>
      </div>
      <div className="w-full bottom-0 flex gap-8px text-[14px] p-4 pt-0">
        {actions.map((item, index) => {
          return (
            <Button
              key={index}
              onClick={item.action}
              bgColor={item.bg}
              textColor={item.color}
              border={item.border}
              padding="py-7 flex-1"
              disabled={item.disabled || item.loading}
              loading={item.loading}
              loadingBorder="border-primary"
            >
              {item.title}
            </Button>
          );
        })}
      </div>
      <ModalConfirm
        open={showModal}
        onClose={() => {
          if (!isApproving && !isCanceling) {
            setShowModal(false);
            removeLeveragePending();
          }
        }}
        onConfirm={() => {
          if (confirmModal?.isApproval) {
            mutateApprove();
          } else {
            mutateCancel();
          }
        }}
        text={{
          buttonConfirm: '',
          content: '',
          title: confirmModal?.title || '',
        }}
        type={confirmModal?.type}
        loading={isApproving || isCanceling}
      />
    </>
  );
}

export default LeverageActions;
