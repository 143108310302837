import { toast } from 'react-toastify';
import { useCallback, useState } from 'react';
import { TEXT_STRING } from '../constants';

type TOptions = {
  position?:
    | 'top-left'
    | 'top-right'
    | 'top-center'
    | 'bottom-left'
    | 'bottom-right'
    | 'bottom-center';
  autoClose?: number;
  hideProgressBar?: boolean;
  newestOnTop?: boolean;
  closeOnClick?: boolean;
  rtl?: boolean;
  pauseOnFocusLoss?: boolean;
  draggable?: boolean;
  pauseOnHover?: boolean;
  theme?: 'dark' | 'light';
};

function useToast(defaultOptions?: TOptions) {
  const [options] = useState<TOptions | undefined>(defaultOptions);

  const toastSuccess = useCallback(
    (message: string, customOptions?: TOptions) => {
      toast.success(message, customOptions || options);
    },
    [options]
  );

  const toastInfo = useCallback(
    (message: string, customOptions?: TOptions) => {
      toast.info(message, customOptions || options);
    },
    [options]
  );

  const toastError = useCallback(
    (error: Error, customOptions?: TOptions) => {
      toast.error(
        error.message || TEXT_STRING.COMMON.SOMETHING_WENT_WRONG,
        customOptions || options
      );
    },
    [options]
  );

  const toastWarning = useCallback(
    (message: string, customOptions?: TOptions) => {
      toast.warning(message, customOptions || options);
    },
    [options]
  );

  return { toastSuccess, toastInfo, toastWarning, toastError };
}

export default useToast;
