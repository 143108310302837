import { routes, storageKeys } from '../constants';
import axios, { isAxiosError } from 'axios';
import { clearStorage } from 'utils';

axios.defaults.baseURL = process.env.REACT_APP_API_URL || '';
axios.defaults.headers.common['Content-Type'] = 'application/json';

// request interceptor
axios.interceptors.request.use((config) => {
  const accessToken: string | null = localStorage.getItem(
    storageKeys.ACCESS_TOKEN
  );
  if (accessToken != null) {
    const accessHeader = `Bearer ${accessToken}`;
    if (config.headers != null) {
      config.headers.Authorization = accessHeader;
    }
  }
  return config;
});

const handleRefreshToken = async (refreshToken: string) => {
  // gets new token
  if (refreshToken) {
    const token = await axios
      .post(`accounts/refresh-token`, {
        refreshToken,
      })
      .then(function (response) {
        return response.data.accessToken;
      });
    localStorage.setItem(storageKeys.ACCESS_TOKEN, token);
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
  } else throw new Error('');
};

// response interceptor
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    // handle response un-authen error
    if (!error?.response?.status) {
      window.location.href = process.env.REACT_APP_TK_URL || '';
    }

    if (error.response?.status === 401) {
      // gets new token
      const refreshToken = localStorage.getItem(storageKeys.REFRESH_TOKEN);
      if (refreshToken) {
        try {
          await handleRefreshToken(refreshToken);
          window.location.reload();
          return;
        } catch (error) {
          console.error('🚀 ~ error:', error);
        }
      }
      clearStorage();
      window.location.href = routes.LOGIN;
    }

    if (isAxiosError(error)) {
      error.message = error.response?.data?.message || error.message;
      error.status = error.response?.status;
    }
    return Promise.reject(error);
  }
);
