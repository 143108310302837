import Text from 'components/Text';
import { DATE_TIME_FORMAT, routes } from '../../constants';
import { memo, PropsWithChildren, useCallback } from 'react';
import { EUserProvider, TPostDetail } from 'types';
import { formatDate } from 'utils';
import { Avatar, PostGallery } from 'components';
import { useNavigate } from 'react-router-dom';

type Props = PropsWithChildren<{
  data: TPostDetail;
  disableClick?: boolean;
  onClickAvatar?: () => void;
  onLoad?: () => void;
  className?: string;
}>;

function PostItem({
  data,
  disableClick = false,
  onClickAvatar,
  onLoad,
  className = '',
}: Props) {
  const navigate = useNavigate();
  const navigateToDetail = useCallback(() => {
    if (disableClick) return;
    onClickAvatar && onClickAvatar();
    navigate(routes.DETAIL_FEMALE.replace(':id', data.user.id));
  }, [data.user.id, disableClick, navigate, onClickAvatar]);

  return (
    <div className={className}>
      <div className={`flex gap-3`}>
        <div className={`flex-[1]`}>
          <Avatar
            onClick={navigateToDetail}
            circle
            url={data.user.avatar}
            canViewFull={
              data.user.applicationProvider === EUserProvider.MALE_APPLICATION
            }
          />
        </div>

        <div className={`flex-[7]`}>
          <Text className="w-fit">{data.user.nickname}</Text>
          <Text textColor="text-neutral" className="mt-1">
            {formatDate(data.approveDatetime, DATE_TIME_FORMAT)}
          </Text>
          <Text textColor="text-black" className="mt-2">
            {data.content}
          </Text>
          {!!data.media?.length && (
            <PostGallery id={data.id} data={data.media} onLoad={onLoad} />
          )}
        </div>
      </div>
    </div>
  );
}

export default memo(PostItem);
