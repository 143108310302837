import { Header, Text } from 'components';
import { TEXT_STRING } from '../../../constants';

const STR = TEXT_STRING.MY_PAGE;
const FAQ_STR = TEXT_STRING.FAQ;

const FAQs = [
  { question: FAQ_STR.QUESTION_1, answer: FAQ_STR.ANSWER_1 },
  { question: FAQ_STR.QUESTION_2, answer: FAQ_STR.ANSWER_2 },
  { question: FAQ_STR.QUESTION_3, answer: FAQ_STR.ANSWER_3 },
  { question: FAQ_STR.QUESTION_4, answer: FAQ_STR.ANSWER_4 },
  { answer: FAQ_STR.NOTE, isActive: true },
];

function FAQ() {
  return (
    <div className="h-full overflow-y-auto">
      <Header title={STR.FAQ} />
      <div className="p-5 flex flex-col gap-3">
        {FAQs.map((item, index) => {
          return (
            <div
              className={`p-5 border border-solid ${
                item.isActive
                  ? 'border-primary bg-primary-50'
                  : 'border-neutral-300'
              } rounded-2xl`}
              key={index}
            >
              <Text fontSize={14} bold textColor="text-primary">
                {item.question}
              </Text>
              <Text
                fontSize={14}
                textColor={
                  item.isActive ? 'text-primary-600' : 'text-neutral-600'
                }
              >
                {item.answer}
              </Text>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default FAQ;
