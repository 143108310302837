import React from 'react';
import icons from './iconPath';

interface IconProps {
  icon: keyof typeof icons;
  color?: string;
}

const Icon = ({ icon, color }: IconProps): JSX.Element => {
  const IconPath = icons[icon];
  return <>{IconPath && <IconPath color={color} />}</>;
};

export default Icon;
