import React from 'react';

type Props = {
  onToggle: () => void;
  className?: string;
  bgColor?: `bg-${string}`;
  isToggle: boolean;
};

function ToggleButton({
  onToggle,
  className = '',
  bgColor = 'bg-primary',
  isToggle = false,
}: Props) {
  return (
    <button
      className={`${className} relative rounded-full w-16 h-8 flex items-center`}
      onClick={onToggle}
    >
      <div
        className={`w-full h-full transition ${
          isToggle ? bgColor : 'bg-neutral-100'
        } rounded-full shadow-md outline-none`}
      ></div>
      <div
        className={`absolute inline-flex items-center justify-center w-[37.5%] aspect-square transition-all duration-200 ease-in-out transform bg-white rounded-full shadow-sm ${
          isToggle
            ? 'translate-x-full left-[18.75%]'
            : 'translate-x-0 left-[6.25%]'
        }`}
      ></div>
    </button>
  );
}

export default ToggleButton;
