import Loading from 'components/Loading';
import { PropsWithChildren } from 'react';

type Props = PropsWithChildren<{
  className?: string;
  loading?: boolean;
  height?: `h-${string}`;
}>;

const PageWrapper = ({
  children,
  className = 'py-6 px-4',
  loading,
  height = 'h-full',
}: Props): JSX.Element => {
  return (
    <div className={`${height} ${className}`}>
      {loading ? <Loading center /> : children}
    </div>
  );
};

export default PageWrapper;
