import { PropsWithChildren } from 'react';
import { Button, Modal } from 'components';
import { ReactComponent as OrderSuccess } from 'assets/images/orderSuccess.svg';
import { TEXT_STRING } from '../../constants';

type Props = PropsWithChildren<{
  open: boolean;
  onConfirm: () => void;
}>;

function ModalSuccess({ children, open, onConfirm }: Props) {
  return (
    <Modal
      open={open}
      className="p-16px text-center mx-5 w-full bg-white"
      onClose={() => {}}
    >
      <div className="pt-3">
        <OrderSuccess className="m-auto" />
        {children}
        <div className="flex flex-col-2 gap-3 overflow-y-auto mt-6">
          <Button
            className="py-16px font-normal rounded-[8px]"
            onClick={onConfirm}
            block
          >
            {TEXT_STRING.COMMON.COMPLETION}
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default ModalSuccess;
