import React from 'react';
import { useNavigate } from 'react-router-dom';
import IconButton from 'components/IconButton';
import Text from 'components/Text';

type Props = {
  className?: string;
  suffix?: JSX.Element;
  onBack?: () => void;
  title?: JSX.Element | string;
  disabled?: boolean;
  textColor?: `text-${string}`;
};

function Header({
  title,
  className = '',
  suffix,
  onBack,
  disabled = false,
  textColor,
}: Props) {
  const navigate = useNavigate();

  const handleBack = () => {
    if (onBack) {
      onBack();
    } else {
      navigate(-1);
    }
  };

  return (
    <div
      className={`${className} w-full h-[46px] pb-1px sticky top-0 left-0 z-10 bg-white`}
    >
      <div className="w-full h-full px-3 flex justify-between items-center border-b-2 border-neutral-50 gap-4">
        <div className="flex-1 flex justify-start">
          <IconButton
            icon={'chevronRight'}
            onClick={handleBack}
            transparent
            disabled={disabled}
            className="p-4 cursor-pointer rotate-180"
          />
        </div>
        {typeof title === 'string' ? (
          <Text
            fontSize={14}
            bold
            className="line-clamp-1 truncate break-all"
            textColor={textColor}
          >
            {title}
          </Text>
        ) : (
          title
        )}
        <div className="flex-1 flex justify-end">{suffix}</div>
      </div>
    </div>
  );
}

export default Header;
