import { useRef } from 'react';
import { TCard } from 'types';
import 'react-phone-number-input/style.css';
import Draggable from 'react-draggable';
import Button from 'components/Button';
import Text from 'components/Text';
import RadioButton from 'components/RadioButton';

type Props = {
  isEdit?: boolean;
  data: TCard;
  isSelected: boolean;
  onDeleteCard: () => void;
  onSelectCard: () => void;
};

function PaymentCardItem({
  isEdit,
  data,
  isSelected,
  onDeleteCard,
  onSelectCard,
}: Props) {
  const draggableRef = useRef(null);

  return (
    <>
      <div className="flex relative">
        <Draggable
          axis="x"
          defaultPosition={{ x: 0, y: 0 }}
          scale={1}
          onStart={() => {}}
          onDrag={() => {}}
          onStop={(e, data) => {
            if (data.lastX < -23) {
              data.node.style.transform = `translate(-48px, 0px)`;
            } else data.node.style.transform = `translate(0px, 0px)`;
          }}
          bounds={{ left: -48, right: 0 }}
          nodeRef={draggableRef}
          disabled={!isEdit}
        >
          <div
            ref={draggableRef}
            className="flex h-16 w-full z-[1] bg-neutral-25 px-4 items-center"
          >
            <Text className="w-full handle">
              {'************' + data.information?.l4dig}
            </Text>
            <RadioButton
              onChecked={() => {
                onSelectCard();
              }}
              value={1}
              isChecked={isSelected}
            />
          </div>
        </Draggable>
        <div className="bg-error-200 absolute right-0 h-16 w-16">
          <Button
            onClick={() => {
              onDeleteCard();
            }}
            iconLeft="trash"
            bgColor="error-200"
            iconColor="#ef4444"
            className="h-full w-full"
          ></Button>
        </div>
      </div>
    </>
  );
}

export default PaymentCardItem;
