import Text from 'components/Text';
import React, { useMemo } from 'react';
import { EChannelType, TChannel } from 'types';
import { useNavigate } from 'react-router-dom';
import { TEXT_STRING, routes } from '../../../constants';
import { formatDate } from 'utils';
import { useUserStore } from 'store';
import AvatarChatGroup from 'components/AvatarGroup/AvatarChatGroup';
import moment from 'moment';
import Markdown from 'markdown-to-jsx';

type Props = {
  channel: TChannel;
  unreadCount: number;
};

const DATE_FORMAT = 'YYYY/M/D';

const ChannelListItem = ({ channel, unreadCount }: Props) => {
  const navigate = useNavigate();
  const { user, clientId } = useUserStore();

  const [textColor, cardItem, borderAvatar] = useMemo(() => {
    const isChattingWithAdmin = EChannelType.MALE_MANAGEMENT.includes(
      channel.type
    );
    return isChattingWithAdmin
      ? [
          'text-primary',
          'bg-primary-50 border-primary-200',
          'border-2 border-primary',
        ]
      : ['text-neutral-600', 'border-neutral-200', ''];
  }, [channel.type]);

  const gotoConversation = React.useCallback(
    (channelId: string) => {
      navigate(routes.CONVERSATION.replace(':id', channelId));
    },
    [navigate]
  );

  const today = useMemo(() => {
    return moment().format(DATE_FORMAT);
  }, []);

  const channelMembers = useMemo(() => {
    return (
      channel?.members?.filter(
        (member) => ![user?.uuid, clientId].includes(member.uuid)
      ) || []
    );
  }, [channel?.members, user?.uuid, clientId]);

  const lastMessageTime = useMemo(() => {
    const timetoken = +channel.lastMessage?.timetoken / 10000;
    if (!timetoken) return '';

    const msgTime = moment(timetoken).format(DATE_FORMAT);
    const localeFormat = formatDate(timetoken, 'LL');

    const [year, month] = today.split('/');
    const [msgYear, msgMonth, msgDay] = msgTime.split('/');

    if (today === msgTime) {
      // Only display time
      return formatDate(timetoken, 'HH:mm');
    } else if (year === msgYear && month === msgMonth) {
      // Only display date
      return localeFormat.slice(localeFormat.lastIndexOf(msgDay));
    } else if (year === msgYear) {
      // Displays  month and date
      return localeFormat.slice(
        localeFormat.indexOf(msgYear) + msgYear.length + 1
      );
    } else return localeFormat;
  }, [today, channel.lastMessage?.timetoken]);

  return (
    <div
      className={`flex h-full w-full gap-5 ${cardItem} px-4 py-2 cursor-pointer`}
      onClick={() => gotoConversation(channel.id)}
    >
      <AvatarChatGroup
        size="h-[4.5rem]"
        border={borderAvatar}
        avatars={channelMembers.map(({ avatar, inactive }) => ({
          url: avatar,
          disabled: inactive,
        }))}
      />
      <div className="flex-1 self-center">
        <div className="flex">
          <Text
            bold
            className="flex-1 line-clamp-1 break-all"
            textColor={textColor as `text-${string}`}
          >
            {channel.name ||
              channelMembers.map(({ id, name, inactive }, index, arr) => (
                <span className={id && inactive ? 'text-neutral-400' : ''} key={index}>
                  {name}
                  {index + 1 >= arr.length ? '' : ', '}
                </span>
              ))}
          </Text>
          {!!unreadCount && (
            <div className="relative w-8 h-8 bg-error rounded-full ml-auto">
              <Text
                className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
                textColor="text-white"
              >
                {unreadCount}
              </Text>
            </div>
          )}
        </div>

        <div className="flex mt-2">
          <div className="flex-1 line-clamp-1 break-all text-neutral-600">
            <Markdown
              options={{
                createElement(tag, props, ...children) {
                  return (
                    <span className="" key={props.key}>
                      {children}
                    </span>
                  );
                },
                wrapper: 'span',
              }}
            >
              {channel.lastMessage?.message?.message?.text ||
                channel.lastMessage?.message?.text ||
                `${
                  channel.lastMessage?.message?.file
                    ? `${TEXT_STRING.MESSAGE.IMAGE}`
                    : ''
                }`}
            </Markdown>
          </div>
          <Text fontSize={12} className="ml-4">
            {lastMessageTime}
          </Text>
        </div>
      </div>
    </div>
  );
};

export default ChannelListItem;
