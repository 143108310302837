import { TEXT_STRING } from '../../constants';
import { useCallback, useMemo, useState } from 'react';
import Text from 'components/Text';
import Button from 'components/Button';
import { FIELD_NAME } from 'helpers';
import ListArea from './ListArea';
import { TActivityArea, TProjectInfo } from 'types';
import Label from 'components/Label';

const STR = TEXT_STRING.ORDER_DETAIL;

type Props = {
  formState: Record<string, string>;
  setFormState: React.Dispatch<React.SetStateAction<Record<string, string>>>;
  onSubmitOrder: () => void;
  projectInfo?: TProjectInfo;
};

const OrderDetail = ({
  formState,
  setFormState,
  onSubmitOrder,
  projectInfo,
}: Props): JSX.Element => {
  // Callbacks, memo
  const [areaId, meetingAddress, subAreaId] = useMemo(
    () => [
      formState[FIELD_NAME.AREA_ID],
      formState[FIELD_NAME.MEETING_ADDRESS],
      formState[FIELD_NAME.SUB_AREA_ID],
    ],
    [formState]
  );

  const listSubArea = useCallback(
    (areaId: number) => {
      return (
        projectInfo?.optionsLocation
          .find((area) => area.id === areaId)
          ?.subArea?.map((area, index) => {
            return { id: index + 1, name: area } as TActivityArea;
          }) || []
      );
    },
    [projectInfo?.optionsLocation]
  );

  // States
  const [showInput, setShowInput] = useState<boolean>(
    !!meetingAddress && !subAreaId
  );

  return (
    <div className="flex flex-col h-full justify-between py-4 px-5 overflow-y-auto">
      <Text bold fontSize={24}>
        {STR.CHOOSE_AREA}
      </Text>
      <div className="flex flex-col gap-5 flex-1 py-8">
        <div className="flex-1 flex flex-col gap-4">
          <Label require bold className="text-[14px]">
            {STR.AREA}
          </Label>
          <ListArea
            onChangeArea={(value) => {
              setFormState((prev) => ({
                ...prev,
                [FIELD_NAME.MEETING_ADDRESS]: value,
              }));
            }}
            name={'area'}
            areaValue={meetingAddress}
            data={
              projectInfo?.optionsLocation.filter(
                (area) => area.subArea?.length
              ) || []
            }
            selectedAreaId={+areaId}
            onSelectArea={(id, area) => {
              setFormState((prev) => {
                const isAreaIdMatching = +prev[FIELD_NAME.AREA_ID] === id;
                return {
                  ...prev,
                  [FIELD_NAME.AREA_ID]: isAreaIdMatching ? '' : `${id}`,
                  [FIELD_NAME.MEETING_ADDRESS]: '',
                  [FIELD_NAME.LOCATION]: area,
                  [FIELD_NAME.SUB_AREA_ID]: '',
                };
              });
              setShowInput(false);
            }}
            onSelectOtherArea={() => {
              setShowInput((prev) => (prev && !!areaId ? prev : !prev));
              setFormState((prev) => ({
                ...prev,
                [FIELD_NAME.AREA_ID]: '',
                [FIELD_NAME.MEETING_ADDRESS]: '',
                [FIELD_NAME.LOCATION]: '',
                [FIELD_NAME.SUB_AREA_ID]: '',
              }));
            }}
            isSelectOtherArea={!areaId && showInput}
          />
        </div>
        {!!areaId && (
          <div className="flex-1 flex flex-col gap-6">
            <Label require bold className="text-[14px]">
              {STR.AREA_DETAILS}
            </Label>
            <ListArea
              onChangeArea={(value) => {
                setFormState((prev) => ({
                  ...prev,
                  [FIELD_NAME.MEETING_ADDRESS]: value,
                }));
              }}
              name={'area'}
              areaValue={meetingAddress}
              data={listSubArea(+areaId)}
              selectedAreaId={+subAreaId}
              onSelectArea={(id, area) => {
                setFormState((prev) => {
                  const isSameId = +prev[FIELD_NAME.SUB_AREA_ID] === id;
                  return {
                    ...prev,
                    [FIELD_NAME.MEETING_ADDRESS]: isSameId ? '' : area,
                    [FIELD_NAME.SUB_AREA_ID]: isSameId ? '' : `${id}`,
                  };
                });
                setShowInput(false);
              }}
              onSelectOtherArea={() => {
                setShowInput((prev) => !prev);
                setFormState((prev) => ({
                  ...prev,
                  [FIELD_NAME.MEETING_ADDRESS]: '',
                  [FIELD_NAME.SUB_AREA_ID]: '',
                }));
              }}
              isSelectOtherArea={showInput && !!areaId}
            />
          </div>
        )}
      </div>
      <div className="flex flex-col gap-8px w-full">
        <Button
          onClick={onSubmitOrder}
          className="flex flex-col gap-1 py-10px"
          disabled={!meetingAddress.trim()}
        >
          <Text textColor="text-white" bold fontSize={14}>
            {STR.CALL_GIRL}
          </Text>
          <Text textColor="text-white/[.6]" fontSize={14}>
            {STR.CALL_HINT}
          </Text>
        </Button>
        <Text textColor="text-neutral" fontSize={14} center>
          {STR.CALL_WARNING}
        </Text>
      </div>
    </div>
  );
};

export default OrderDetail;
