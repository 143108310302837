import CardGirl from 'components/OrderScreen/CardGirl';
import OrderFooter from 'components/OrderScreen/OrderFooter';
import Text from 'components/Text';
import { AUTO_PRICE, TEXT_STRING } from '../../../../../constants';
import { useCallback, useMemo, useState } from 'react';
import { TProjectFemale } from 'types';
import Loading from 'components/Loading';
import { getFormattedAmount } from 'utils';

type Props = {
  loading?: boolean;
  isSingleMeeting: boolean;
  defaultValues: TProjectFemale[];
  females: TProjectFemale[];
  onSubmit: (females: TProjectFemale[]) => void;
};
const FreeGirl = ({
  loading,
  isSingleMeeting,
  defaultValues,
  females,
  onSubmit,
}: Props) => {
  const [listSelectedGirl, setListSelectedGirl] =
    useState<TProjectFemale[]>(defaultValues);

  const onFreeGirl = useCallback(() => {
    onSubmit(listSelectedGirl?.length ? listSelectedGirl : females);
  }, [listSelectedGirl, females, onSubmit]);

  const estimatePrice = useMemo(
    () =>
      (listSelectedGirl.length ? listSelectedGirl : females)?.reduce(
        (result, current) => {
          const price =
            !isSingleMeeting || females.length > 1
              ? AUTO_PRICE
              : current.rankPrice;
          const nominatedPrice = current.nominatedPrice;
          return result + price + nominatedPrice;
        },
        0
      ) || 0,
    [listSelectedGirl, isSingleMeeting, females]
  );

  return (
    <div>
      {loading ? (
        <div className="py-16">
          <Loading />
        </div>
      ) : !!females?.length ? (
        <>
          <Text bold>{TEXT_STRING.RELEASE_GIRL.CHOOSE_GIRL}</Text>
          <Text className="mb-2" textColor="text-neutral">
            {TEXT_STRING.RELEASE_GIRL.NOTE_CHOOSE_GIRL}
          </Text>

          <div className="flex gap-4 overflow-x-auto py-2">
            {females?.map((female: TProjectFemale) => {
              const isSelected = listSelectedGirl.some(
                ({ id }) => id === female.id
              );
              return (
                <CardGirl
                  key={female.user.id}
                  onChoose={() => {
                    setListSelectedGirl((prev) =>
                      isSelected
                        ? prev.filter((item) => item.id !== female.id)
                        : [...prev, female]
                    );
                  }}
                  onDetail={() => {}}
                  data={female.user}
                  isMeeting={female.isMeeting}
                  isChoose={isSelected}
                  icon="check"
                />
              );
            })}
          </div>
          <OrderFooter
            onSubmit={onFreeGirl}
            textTitle={TEXT_STRING.RELEASE_GIRL.RELEASE_AMOUNT}
            textButton={TEXT_STRING.RELEASE_GIRL.FREE_GIRL}
            loading={loading}
            estimatePrice={
              <>
                <Text fontSize={14}>
                  {getFormattedAmount(estimatePrice)}

                  {TEXT_STRING.COMMON.YEN}
                </Text>
                <Text textColor="text-neutral" fontSize={14}>
                  + {TEXT_STRING.CALL_WOMAN.GAME_FEE}
                </Text>
              </>
            }
          />
        </>
      ) : (
        <Text bold center className="py-16">
          {TEXT_STRING.COMMON.NO_DATA}
        </Text>
      )}
    </div>
  );
};

export default FreeGirl;
