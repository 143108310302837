import { EPaymentMethod, TOrderHistory } from 'types';
import ListItem from '../OrderHistoryList/ListItem';
import DetailItem from './DetailItem';
import PriceItem from './PriceItem';
import { TEXT_STRING } from '../../../constants';
import { useCallback, useMemo } from 'react';

type Props = { data: TOrderHistory };
type TField = {
  label: string;
  key:
    | 'total'
    | 'prepaid'
    | 'discount'
    | 'gamesDiscount'
    | 'totalAfterDiscount'
    | 'totalAfterDiscountCardPayment';
};

const totalFields: TField[] = [
  {
    label: TEXT_STRING.RELEASE_GIRL.TOTAL,
    key: 'total',
  },
  {
    label: TEXT_STRING.RELEASE_GIRL.PRE_PAID,
    key: 'prepaid',
  },
  {
    label: TEXT_STRING.RELEASE_GIRL.DISCOUNT,
    key: 'discount',
  },
  // {
  //   label: TEXT_STRING.RELEASE_GIRL.GAME_DISCOUNT,
  //   key: 'gamesDiscount',
  // },
];

const OrderHistoryDetail = ({ data }: Props) => {
  const total = useMemo<TField>(() => {
    const { discount, gamesDiscount } = data.payment;
    const totalDiscount = discount + gamesDiscount;
    const hasDiscount = totalDiscount && totalDiscount > 0;

    return data.type === EPaymentMethod.CARD
      ? {
          label: hasDiscount
            ? TEXT_STRING.RELEASE_GIRL.TOTAL_CARD
            : TEXT_STRING.RELEASE_GIRL.TOTAL_CARD_NO_DISCOUNT,
          key: 'totalAfterDiscountCardPayment',
        }
      : {
          label: hasDiscount
            ? TEXT_STRING.RELEASE_GIRL.TOTAL_CASH
            : TEXT_STRING.RELEASE_GIRL.TOTAL_CASH_NO_DISCOUNT,
          key: 'totalAfterDiscount',
        };
  }, [data.payment, data.type]);

  const TotalPrice = useCallback(() => {
    return (
      <>
        {totalFields.map(({ label, key }, index) => (
          <PriceItem
            key={index}
            label={label + ':'}
            price={+data.payment[key]}
          />
        ))}
        <PriceItem label={total.label + ':'} price={+data.payment[total.key]} />
      </>
    );
  }, [data.payment, total.key, total.label]);

  return (
    <div>
      <ListItem data={data} />
      {data.females?.map((female) => {
        return (
          <DetailItem key={female.femaleId} data={female}>
            {data.females?.length <= 1 && <TotalPrice />}
          </DetailItem>
        );
      })}
      {/* Total */}
      {data.females?.length > 1 && (
        <div className={`my-5 p-6 bg-neutral-25 items-center`}>
          <TotalPrice />
        </div>
      )}
    </div>
  );
};

export default OrderHistoryDetail;
