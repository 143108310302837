import React, { memo, useContext, useMemo } from 'react';
import { TMediaDetail } from 'types';
import 'react-lazy-load-image-component/src/effects/blur.css';
import Modal from 'components/Modal';
import MediaSlide from 'components/MediaSlide';
import IconButton from 'components/IconButton';
import { PostContext } from 'contexts';
import GalleryGrid from '../GalleryGrid';

type Props = {
  id: string;
  data: TMediaDetail[];
  onLoad?: () => void;
};

function PostGallery({ id, data, onLoad }: Props) {
  const { galleryWidth } = useContext(PostContext);
  const [selectedId, setSelectedId] = React.useState<string | undefined>();

  const [gridHeight, minHeight] = useMemo(() => {
    let maxRatio = 0;
    data.forEach((item) => {
      if (item.originalHeight && item.originalWidth) {
        maxRatio = Math.max(item.originalHeight / item.originalWidth, maxRatio);
      }
    });
    return [
      (maxRatio * (galleryWidth || 0)) / (data.length === 2 ? 2 : 1),
      galleryWidth ? galleryWidth / 1.8 : undefined,
    ];
  }, [data, galleryWidth]);

  return (
    <div
      className="mt-8 relative"
      style={{
        height: !gridHeight ? undefined : gridHeight,
        minHeight: !minHeight ? undefined : minHeight,
      }}
    >
      <GridLoading />
      <GalleryGrid
        data={data}
        onClickMedia={setSelectedId}
        id={id}
        onLoad={onLoad}
      />
      <Modal
        open={selectedId !== undefined}
        onClose={() => {
          setSelectedId(undefined);
        }}
      >
        {selectedId && (
          <div
            className="h-screen w-full flex items-center overflow-hidden"
            onClick={() => {
              setSelectedId(undefined);
            }}
          >
            <IconButton
              icon="close"
              transparent
              className="fixed top-0 left-0 z-50 m-4"
              onClick={(e) => {
                e.stopPropagation();
                setSelectedId(undefined);
              }}
              iconColor="white"
            />
            <MediaSlide
              data={data}
              selectedItem={data.findIndex(({ id }) => id === selectedId)}
            />
          </div>
        )}
      </Modal>
    </div>
  );
}

function GridLoading() {
  return (
    <div
      className={`h-full w-full absolute top-0 left-0 z-[-1] rounded-2xl border border-neutral-200 
        background-gradient bg-gradient-to-br from-neutral-200 via-white to-neutral-200`}
    ></div>
  );
}

export default memo(PostGallery);
