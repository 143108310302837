import Text from 'components/Text';
import { TEXT_STRING } from '../../../constants';
import TextField from 'components/TextField';
import { TTextFieldChangeEvent } from 'types';
import Label from 'components/Label';

type Props = {
  name?: string;
  note?: string;
  onChangeNote?: (e: TTextFieldChangeEvent) => void;
  error?: boolean;
  pending?: boolean;
};

function ProfileBiography({
  note = '',
  onChangeNote,
  name = '',
  error = false,
  pending = false,
}: Props) {
  return (
    <>
      <div className="flex items-center gap-2">
        <Label bold className="m-0" error={error} textSize="text-[14px]">
          {TEXT_STRING.MALE_PAGE.INTRO}
        </Label>
        {pending && <Text bold>({TEXT_STRING.COMMON.PENDING})</Text>}
      </div>
      {onChangeNote ? (
        <TextField
          name={name}
          value={note}
          onChange={onChangeNote}
          type="textarea"
          rows={5}
          placeholder={TEXT_STRING.MALE_PAGE.INTRO}
          error={error}
        />
      ) : (
        <Text fontSize={14}>{note}</Text>
      )}
    </>
  );
}

export default ProfileBiography;
