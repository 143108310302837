import React from 'react';

type Props = React.PropsWithChildren<{
  className?: string;
  textColor?: `text-${string}`;
  fontSize?: number;
  center?: boolean;
  bold?: boolean;
  onClick?: () => void;
  nowrap?: boolean;
}>;

function Text({
  children,
  className = '',
  textColor = 'text-neutral-600',
  fontSize,
  center,
  bold,
  onClick,
  nowrap = false,
}: Props) {
  return (
    <p
      style={{ fontSize: fontSize }}
      className={`${className} 
				${textColor} 
				${center ? 'text-center' : 'text-start'} 
				${bold ? 'font-bold' : 'font-normal'}
        ${nowrap ? 'whitespace-nowrap' : 'whitespace-pre-line'}
			`}
      onClick={onClick}
    >
      {children}
    </p>
  );
}

export default Text;
