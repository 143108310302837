import axios from 'axios';
import { storageKeys } from '../constants';
import { TProjectLeverage } from 'types';

const getPendingProjectLeverage = async (
  femaleId?: number
): Promise<TProjectLeverage[]> => {
  const clientId = localStorage.getItem(storageKeys.CLIENT_ID);
  return await axios
    .get(`projects/leverage/pending`, {
      params: {
        femaleId,
        clientId,
      },
    })
    .then((response) => {
      return response.data;
    });
};

const createLeverage = async (femaleId?: number) => {
  if (!femaleId) return;
  const clientId = localStorage.getItem(storageKeys.CLIENT_ID);
  return await axios
    .post('projects/leverage/create', { femaleId, clientId })
    .then((response) => {
      return response.data;
    });
};

const approveLeverage = async (id?: number) => {
  if (!id) return;
  const clientId = localStorage.getItem(storageKeys.CLIENT_ID);
  return await axios
    .patch(`projects/leverage/approve/${id}`, { clientId })
    .then((response) => {
      return response.data;
    });
};

const cancelLeverage = async (id?: number) => {
  if (!id) return;
  const clientId = localStorage.getItem(storageKeys.CLIENT_ID);
  return await axios
    .patch(`projects/leverage/cancel/${id}`, { clientId })
    .then((response) => {
      return response.data;
    });
};

const getTotalProjectLeverageApproved = async (
  femaleId?: number
): Promise<{ total: number }> => {
  const clientId = localStorage.getItem(storageKeys.CLIENT_ID);
  return await axios
    .get(`projects/leverage/total-approved`, {
      params: {
        femaleId,
        clientId,
      },
    })
    .then((response) => {
      return response.data;
    });
};

export {
  getPendingProjectLeverage,
  createLeverage,
  cancelLeverage,
  approveLeverage,
  getTotalProjectLeverageApproved,
};
