import { Icon, ListItem, Text, ToggleButton } from 'components';
import { DEBOUNCE_CLICK, TEXT_STRING } from '../../../constants';
import { useCallback, useEffect } from 'react';
import { ESettingNoti, TSetting } from 'types';
import { useMutation } from '@tanstack/react-query';
import { settingNotification } from 'api/setting';
import { useSettingStore, useUserStore } from 'store';
import { toast } from 'react-toastify';
import { settingItem } from 'helpers';

const MY_PAGE_STR = TEXT_STRING.MY_PAGE;

let timeout: NodeJS.Timeout;

function SettingNotification() {
  // Hook
  const { user } = useUserStore();
  const { settingNoti, setSettingNoti } = useSettingStore();
  const { mutateAsync } = useMutation({
    mutationFn: (body: TSetting) => {
      return settingNotification(body);
    },
    onError: (err) => {
      toast.error(err.message);
    },
  });

  const handleSettingNotification = useCallback(
    (settingKey: ESettingNoti) => {
      const notification = {
        ...settingNoti,
        [settingKey]: !settingNoti[settingKey],
      };
      setSettingNoti(notification);
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(async () => {
        await mutateAsync(notification);
      }, DEBOUNCE_CLICK);
    },
    [mutateAsync, setSettingNoti, settingNoti]
  );

  useEffect(() => {
    if (user?.notification && !Object.keys(settingNoti).length) {
      setSettingNoti(user.notification);
    }
  }, [setSettingNoti, settingNoti, user]);

  return (
    <>
      <ListItem
        prefix={<Icon icon={'bellRing'} color="black" />}
        text={<Text bold>{MY_PAGE_STR.NOTI_SETTING}</Text>}
        suffix={<></>}
      />
      {Object.keys(user?.notification || {}).map((key) => {
        const settingKey = key as ESettingNoti;
        const setting = settingItem[settingKey];
        return (
          <ListItem
            key={settingKey}
            text={setting?.label}
            prefix={<Icon icon={setting?.icon} />}
            suffix={
              <ToggleButton
                isToggle={settingNoti[settingKey]}
                onToggle={() => handleSettingNotification(settingKey)}
              />
            }
          />
        );
      })}
    </>
  );
}

export default SettingNotification;
