import { CardGirl, OrderFooter } from 'components/OrderScreen';
import { TEXT_STRING } from '../../../../constants';
import { useCallback, useMemo } from 'react';
import { EGameType, TAddGame, TConfirmGame, TProjectFemale } from 'types';
import { QuantitySelector, Table, Text } from 'components';
import { useMutation } from '@tanstack/react-query';
import { addProjectGame } from 'api';
import { getFormattedAmount, validateGamePlayManyTime } from 'utils';
import { useChatStore } from 'store';
import { useToast } from 'hooks';

type Props = {
  females?: TProjectFemale[];
  selectedGame: TConfirmGame[];
  projectId: number;
  listSelectedGirl: TProjectFemale[];
  onSelectGirl: (female: TProjectFemale) => void;
  onUpdateQuantity: (id: number, quantity: number) => void;
  refetchFemales: () => void;
};

enum EGameTable {
  GAME = 'game',
  QUANTITY = 'quantity',
}

const chatStr = TEXT_STRING.CHAT;
const commonStr = TEXT_STRING.COMMON;

function ConfirmGame({
  females,
  selectedGame,
  projectId,
  listSelectedGirl,
  onSelectGirl,
  onUpdateQuantity,
  refetchFemales,
}: Props) {
  const { setActionChatStep } = useChatStore();
  const { toastError, toastSuccess } = useToast();

  const { mutateAsync: addGameMutate, isPending } = useMutation({
    mutationFn: (body: TAddGame) => {
      return addProjectGame(body);
    },
    onError(error) {
      toastError(error);
    },
  });

  const listFemale = useMemo(() => {
    return (
      females?.filter((female) => {
        const isSingleSelectedGameSpecial = !selectedGame.some(
          (game) => game.id !== female.specialGameId
        );
        const isGroupKing = female.specialGameType === EGameType.GROUP_KING;
        return !(isSingleSelectedGameSpecial || isGroupKing);
      }) || []
    );
  }, [females, selectedGame]);

  const listConfirmGirl = useMemo(() => {
    return listSelectedGirl.length ? listSelectedGirl : listFemale;
  }, [listFemale, listSelectedGirl]);

  const totalPriceGame = useMemo(() => {
    return selectedGame.reduce((sum, game) => {
      const isGameSpecial = !validateGamePlayManyTime(game.type);
      const quantityGame = game?.quantity ?? 0;

      const totalGirls = listConfirmGirl?.length || 0;
      let quantityGirl;

      if (isGameSpecial) {
        const totalGirlHasSpecialGame = listConfirmGirl.filter(
          (item) => item.specialGameId === game.id
        ).length;

        quantityGirl = totalGirls - totalGirlHasSpecialGame;
      } else {
        quantityGirl = totalGirls;
      }

      return sum + quantityGame * game.price * quantityGirl;
    }, 0);
  }, [listConfirmGirl, selectedGame]);

  const handleAddGame = useCallback(async () => {
    try {
      if (!projectId || !selectedGame.length) return;
      await addGameMutate({
        projectId: projectId,
        femaleIds: listConfirmGirl?.map((female) => female.user.id) || [],
        games: selectedGame
          .filter((game) => (game?.quantity || 0) > 0)
          .map((game) => {
            return { id: game.id, orderCount: game.quantity || 0 };
          }),
      });
      setActionChatStep();
      refetchFemales();
      toastSuccess(commonStr.SUCCESS);
    } catch (error) {
      console.log(error);
    }
  }, [
    addGameMutate,
    listConfirmGirl,
    projectId,
    refetchFemales,
    selectedGame,
    setActionChatStep,
    toastSuccess,
  ]);

  const listTable = useMemo(
    () =>
      selectedGame?.map((game) => {
        const quantity = game.quantity || 0;
        return {
          [EGameTable.GAME]: (
            <Text className="line-clamp-2 break-all">{game.name}</Text>
          ),
          [EGameTable.QUANTITY]: (
            <QuantitySelector
              className="justify-end gap-6"
              quantity={quantity}
              onIncrease={() => onUpdateQuantity(game.id, 1)}
              onDecrease={() => onUpdateQuantity(game.id, -1)}
              disabledIncrease={
                (!validateGamePlayManyTime(game.type) && quantity >= 1) ||
                isPending
              }
              disabledDecrease={isPending}
            />
          ),
        };
      }),
    [isPending, onUpdateQuantity, selectedGame]
  );

  return (
    <>
      <Text bold>{chatStr.GAMES_TO_ORDER}</Text>
      <div className="py-8">
        <Table
          columns={[
            { key: EGameTable.GAME, title: commonStr.GAME },
            {
              key: EGameTable.QUANTITY,
              title: <span className="pr-12">{commonStr.QUANTITY}</span>,
              align: 'right',
            },
          ]}
          data={listTable}
        />
      </div>
      <Text bold>{chatStr.SELECT_TECO_GIRL}</Text>
      <Text textColor="text-neutral">{chatStr.SELECT_NOTE}</Text>
      <div className="flex gap-2 mt-4">
        {listFemale?.map((female) => {
          return (
            <CardGirl
              key={female.id}
              onChoose={() => onSelectGirl(female)}
              onDetail={() => onSelectGirl(female)}
              data={female.user}
              isChoose={listSelectedGirl.some((item) => item.id === female.id)}
            />
          );
        })}
      </div>
      <OrderFooter
        onSubmit={handleAddGame}
        textButton={chatStr.ADD_GAME}
        className="mt-16px"
        loading={isPending}
        estimatePrice={
          <Text fontSize={14}>
            {getFormattedAmount(totalPriceGame)}
            {commonStr.YEN}
          </Text>
        }
        disabled={
          totalPriceGame < 0 ||
          selectedGame?.every((game) => (game.quantity || 0) <= 0)
        }
      />
    </>
  );
}

export default ConfirmGame;
