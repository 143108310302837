import { Button, Chip, Icon, Modal, MultipleSelect, Text } from 'components';
import { TEXT_STRING } from '../../constants';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { EFilterFemale, ESearchTab, TSelectOption } from 'types';
import { useAppStore } from 'store';
import { initialFilterOptions } from 'helpers';

const str = TEXT_STRING.SEARCH_PAGE;
const commonStr = TEXT_STRING.COMMON;

type ModalSearchProps = {
  currentTab: ESearchTab;
  onSearch: (value: Record<string, number[]>) => void;
  onClose: () => void;
  open?: boolean;
  filtered?: Record<string, number[]>;
};

const textOptions: Record<string, string> = {
  styles: str.STYLES,
  lineage: str.LINEAGE,
  enjoy_method: str.ENJOY_METHOD,
  age_range: TEXT_STRING.CAST_PAGE.AGE_NUMBER,
  game_play: str.GAME_PLAY,
};

function ModalFilter({
  currentTab,
  open = false,
  onClose,
  onSearch,
  filtered,
}: ModalSearchProps) {
  const [listFiltered, setListFiltered] = useState<Record<string, number[]>>(
    filtered || initialFilterOptions
  );

  const { filterOptions } = useAppStore();
  const { tags, ranks, starRanks, activityAreas } = filterOptions || {};

  const options = useMemo<TSelectOption[]>(
    () =>
      activityAreas?.map((item) => ({
        name: item.name,
        value: `${item.id}`,
      })) || [],
    [activityAreas]
  );

  const optionStars = useMemo<TSelectOption[]>(
    () =>
      starRanks?.map((item) => ({
        name: `${item.star}`,
        value: `${item.id}`,
      })) || [],
    [starRanks]
  );

  const handleFilter = useCallback(
    (value: number, key: EFilterFemale) => {
      setListFiltered((prev) => ({
        ...prev,
        [key]: listFiltered[key]?.includes(value)
          ? prev[key].filter((i) => i !== value)
          : [...prev[key], value],
      }));
    },
    [listFiltered]
  );

  useEffect(() => {
    if (filtered) {
      setListFiltered(filtered);
    }

    return () => {};
  }, [filtered]);

  const CustomChip = ({
    type,
    list = [],
  }: {
    type: EFilterFemale;
    list: { id: number; label: string }[];
  }) => {
    return (
      <>
        {list.map((item) => {
          return (
            <Chip
              key={item.id}
              ringColor={
                listFiltered[type].includes(item.id)
                  ? 'ring-primary'
                  : 'ring-neutral-200'
              }
              textColor={
                listFiltered[type].includes(item.id)
                  ? 'text-primary'
                  : 'text-neutral-300'
              }
              className="bg-white cursor-pointer"
              onClick={() => handleFilter(item.id, type)}
            >
              {item.label}
            </Chip>
          );
        })}
      </>
    );
  };

  return (
    <Modal open={open} className="p-16px bg-white" onClose={() => {}}>
      <div className="flex flex-col justify-between gap-16px max-h-[65dvh] h-[70vh]">
        <div className="relative py-2">
          <div onClick={onClose} className="absolute left-0 cursor-pointer">
            <Icon icon={'close'} />
          </div>
          <Text center>{str.REFINE}</Text>
          <div className="absolute right-0 top-0 cursor-pointer">
            <Button
              className="py-2"
              onClick={() => {
                setListFiltered(initialFilterOptions);
              }}
            >
              {TEXT_STRING.COMMON.RESET}
            </Button>
          </div>
        </div>

        <div className="flex flex-col gap-16px overflow-y-auto">
          <div>
            <Text className="mb-4px">
              {currentTab === ESearchTab.SCHEDULES
                ? TEXT_STRING.CAST_PAGE.WORK_AREA
                : TEXT_STRING.CAST_PAGE.AFFILIATION_AREA}
            </Text>
            <MultipleSelect
              name="activity-area"
              options={options}
              values={options.filter((option) =>
                listFiltered?.activityAreas.includes(+option.value)
              )}
              onChange={(values: TSelectOption[]) => {
                setListFiltered((prev) => ({
                  ...prev,
                  activityAreas: values.map((item) => +item.value),
                }));
              }}
            />
          </div>
          <div>
            <Text className="mb-4px">{str.RATING}</Text>
            <MultipleSelect
              name="starIds"
              options={optionStars}
              values={optionStars.filter((option) =>
                listFiltered?.starIds?.includes(+option.value)
              )}
              onChange={(values: TSelectOption[]) => {
                setListFiltered((prev) => ({
                  ...prev,
                  starIds: values.map((item) => +item.value),
                }));
              }}
              isSelectRating
            />
          </div>
          <div>
            <Text className="mb-4px">{commonStr.RANK}</Text>
            <div className="flex flex-wrap gap-8px">
              <CustomChip
                list={
                  ranks?.map((rank) => ({ id: rank.id, label: rank.rank })) ??
                  []
                }
                type={EFilterFemale.RANKS}
              />
            </div>
          </div>
          {Object.keys(tags || {}).map((key) => {
            return (
              <div key={key}>
                <Text className="mb-4px">{textOptions[key] || key}</Text>
                <div className="flex flex-wrap gap-8px">
                  <CustomChip
                    list={tags?.[key] ?? []}
                    type={EFilterFemale.OPTIONS}
                  />
                </div>
              </div>
            );
          })}
        </div>
        <Button
          className="w-full py-16px font-normal rounded-[8px]"
          onClick={() => onSearch(listFiltered)}
        >
          {str.SEARCH}
        </Button>
      </div>
    </Modal>
  );
}

export default ModalFilter;
