import React from 'react';

type Props = {
  className?: string;
  prefixText?: string | number;
  suffixText?: string | number;
  hyphen?: string;
};

function ConcatText({
  className = '',
  prefixText,
  suffixText,
  hyphen = '-',
}: Props) {
  return (
    <p className={`${className} flex gap-1`}>
      <span className="whitespace-nowrap overflow-hidden text-ellipsis">
        {prefixText}
      </span>
      {suffixText && (
        <>
          <span>{hyphen}</span>
          <span>{String(suffixText).padStart(3, '0')}</span>
        </>
      )}
    </p>
  );
}

export default ConcatText;
