import { getSignUrl, uploadFile } from 'api';
import React from 'react';
import { generateFileName } from 'utils';

function useUploadFile() {
  const [loading, setLoading] = React.useState<boolean>(false);

  const uploadToS3 = async ({ files }: { files: File[] }) => {
    try {
      setLoading(true);
      return await Promise.all(
        files.map(async (file) => {
          const res = await getSignUrl({
            filename: generateFileName(file),
            contentType: file.type,
            size: file.size,
          });
          await uploadFile(res.preSignedUrl, file);
          return res;
        })
      );
    } finally {
      setLoading(false);
    }
  };

  return { loading, uploadToS3 };
}

export default useUploadFile;
