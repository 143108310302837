import { useCallback } from 'react';
import { UploadImage } from 'components';
import { MAX_FILE_SIZE, TEXT_STRING } from '../../../constants';
import { toast } from 'react-toastify';

const commonStr = TEXT_STRING.COMMON;

type Props = {
  collections?: File[];
  onSelectCollection: (files: File[]) => void;
  showUpload?: boolean;
};

function RequestImage({
  collections = [],
  onSelectCollection,
  showUpload = false,
}: Props) {
  // Callback
  const validateFile = useCallback((file: File) => {
    if (file.size > MAX_FILE_SIZE) {
      toast.error(commonStr.ERROR_LIMIT_SIZE.replace('$size', '10mb'));
      return false;
    }
    return true;
  }, []);

  const handleAddFile = useCallback(
    (file: File) => {
      if (validateFile(file)) {
        onSelectCollection([file, ...collections]);
      }
    },
    [collections, onSelectCollection, validateFile]
  );

  const handleChangeFile = useCallback(
    (file: File, index: number) => {
      const newFiles = [...collections];
      newFiles[index] = file;
      if (validateFile(file)) {
        onSelectCollection(newFiles);
      }
    },
    [collections, onSelectCollection, validateFile]
  );

  const handleRemoveFile = useCallback(
    (index: number) => {
      const newFiles = [...collections].filter((_, i) => i !== index);
      onSelectCollection(newFiles);
    },
    [collections, onSelectCollection]
  );

  return (
    <>
      {showUpload && <UploadImage onChangeFile={handleAddFile} width="w-10" />}
      {[...collections].map((item, index) => (
        <UploadImage
          key={index}
          image={item}
          onChangeFile={(file) => handleChangeFile(file, index)}
          onRemove={() => handleRemoveFile(index)}
          width="w-12"
        />
      ))}
    </>
  );
}

export default RequestImage;
