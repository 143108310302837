import { Header, Text } from 'components';
import { TEXT_STRING } from '../../../constants';
import Teco from '../../../assets/images/teco.png';
import TEXT_APP_INFO from 'constants/appInfo';

const STR = TEXT_STRING.MY_PAGE;

function HowToUse() {
  return (
    <div className="h-full overflow-y-auto">
      <Header title={STR.HOW_TO_USE} />
      <div className="p-5 pb-10">
        <Text fontSize={14}>{TEXT_APP_INFO.HOW_TO_USE_TEXT}</Text>
        <img src={Teco} alt="" className="mt-10" />
      </div>
    </div>
  );
}

export default HowToUse;
