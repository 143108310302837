import {
  Button,
  Icon,
  ListItem,
  Text,
  TextField,
  ToggleButton,
} from 'components';
import { DEBOUNCE_CLICK, TEXT_STRING, queryKeys } from '../../../constants';
import { useCallback, useEffect, useState } from 'react';
import { validateEmail } from 'utils';
import { useSettingStore, useUserStore } from 'store';
import { updateEmail } from 'api';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ESettingNoti, TSetting } from 'types';
import { toast } from 'react-toastify';
import { settingSendEmail } from 'api/setting';
import { settingItem } from 'helpers';

const COMMON_STR = TEXT_STRING.COMMON;
const MY_PAGE_STR = TEXT_STRING.MY_PAGE;

let timeout: NodeJS.Timeout;

function SettingEmail() {
  // State
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [email, setEmail] = useState<string>('');

  // Hook
  const { user } = useUserStore();
  const { settingEmail, setSettingEmail } = useSettingStore();
  const queryClient = useQueryClient();

  const {
    mutateAsync: updateEmailToReceiptMessage,
    isPending: loadingUpdateEmail,
  } = useMutation({
    mutationFn: (email: string) => {
      return updateEmail({ email });
    },
    onSuccess() {
      toast.success(COMMON_STR.SUCCESS);
      queryClient.refetchQueries({ queryKey: [queryKeys.CURRENT_USER] });
    },
    onError: (err) => {
      toast.error(err.message);
    },
  });

  const { mutateAsync } = useMutation({
    mutationFn: (body: TSetting) => {
      return settingSendEmail(body);
    },
    onError: (err) => {
      toast.error(err.message);
    },
  });

  const handleUpdateEmail = useCallback(async () => {
    try {
      setErrorMessage('');
      if (!validateEmail(email))
        return setErrorMessage(COMMON_STR.EMAIL_NOT_VALID);
      await updateEmailToReceiptMessage(email);
    } catch (error) {
      if (error instanceof Error) {
        setErrorMessage(error.message);
      }
    }
  }, [email, updateEmailToReceiptMessage]);

  const handleSettingEmail = useCallback(
    async (settingKey: ESettingNoti) => {
      const newSettingEmail = {
        ...settingEmail,
        [settingKey]: !settingEmail[settingKey],
      };
      setSettingEmail(newSettingEmail);
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(async () => {
        await mutateAsync(newSettingEmail);
      }, DEBOUNCE_CLICK);
    },
    [mutateAsync, setSettingEmail, settingEmail]
  );

  useEffect(() => {
    if (user) {
      setEmail(user?.email || '');
    }
  }, [user]);

  useEffect(() => {
    if (user?.emailSetting && !Object.keys(settingEmail).length) {
      setSettingEmail(user.emailSetting);
    }
  }, [setSettingEmail, settingEmail, user]);

  return (
    <>
      <Text bold>{MY_PAGE_STR.EMAIL_NOTI_SETTING}</Text>
      <Text>{MY_PAGE_STR.EMAIL_RECEIVE}</Text>
      <div className="flex gap-4 items-center">
        <TextField
          name={'email'}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="py-1"
        />
        <Button
          onClick={handleUpdateEmail}
          disabled={!email || email === user?.email || loadingUpdateEmail}
          loading={loadingUpdateEmail}
          className="w-1/6"
        >
          {COMMON_STR.KEEP}
        </Button>
      </div>
      <Text textColor="text-error" bold>
        {errorMessage}
      </Text>
      {Object.keys(settingItem).map((key) => {
        const settingKey = key as ESettingNoti;
        const setting = settingItem[settingKey];
        return (
          <ListItem
            key={settingKey}
            text={setting.label}
            prefix={<Icon icon={setting.icon} />}
            suffix={
              <ToggleButton
                isToggle={settingEmail[settingKey]}
                onToggle={() => handleSettingEmail(settingKey)}
              />
            }
          />
        );
      })}
    </>
  );
}

export default SettingEmail;
