import React from 'react';
import NoAvatar from '../../assets/images/avatar.svg';
type Props = {
  size?: `w-${string}` | `h-${string}`;
  avatars: Array<{ url?: string; disabled?: boolean }>;
  border?: string;
};

const MAX_IMAGES = 3;

const positions: { [x: number]: string[] } = {
  1: [''],
  2: ['absolute w-[62%]', 'absolute w-[62%] bottom-0 right-0'],
  3: [
    'absolute w-[58%] right-0 top-1/3 -translate-y-1/3',
    'absolute w-[58%]',
    'absolute w-[58%] bottom-0 left-1/3 -translate-x-1/3',
  ],
};

function AvatarChatGroup({ size = `w-full`, avatars, border = '' }: Props) {
  return (
    <div className={`relative aspect-square ${size}`}>
      {!!avatars.length &&
        (positions[avatars.length] || positions[3]).map((className, index) => {
          const viewMore = avatars.length > MAX_IMAGES && index === 0;
          const { url, disabled } = avatars[index] || {};
          return (
            <React.Fragment key={index}>
              <img
                src={viewMore || !url ? NoAvatar : url}
                alt=""
                className={`rounded-full ${className} aspect-square object-cover ${border} ${
                  disabled ? 'opacity-50' : ''
                }`}
                onError={(e) => (e.currentTarget.src = NoAvatar)}
              />
              {viewMore && (
                <div
                  className={`rounded-full bg-black/10 h-[58%] ${className}`}
                >
                  <h1 className="text-sm text-white absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2">
                    +{avatars.length - MAX_IMAGES}
                  </h1>
                </div>
              )}
            </React.Fragment>
          );
        })}
    </div>
  );
}

export default AvatarChatGroup;
