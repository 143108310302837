import { TEXT_STRING } from '../../constants';
import React from 'react';
import { Button, Text } from 'components';

type Props = {
  textTitle?: string;
  textButton?: JSX.Element | string;
  estimatePrice: JSX.Element;
  textWarning?: string;
  onSubmit: () => void;
  className?: string;
  loading?: boolean;
  disabled?: boolean;
};

const CALL_STR = TEXT_STRING.CALL_WOMAN;

function OrderFooter({
  onSubmit,
  textTitle = CALL_STR.ESTIMATED_AMOUNT,
  textButton = CALL_STR.ENTER_DETAIL,
  textWarning = CALL_STR.PAYMENT_WARNING,
  estimatePrice,
  className = '',
  loading = false,
  disabled = false,
}: Props) {
  return (
    <div
      className={`${className} flex flex-col pt-16px gap-8px border-t border-neutral-50`}
    >
      <Text center bold fontSize={14}>
        {textTitle}
      </Text>
      <div className="flex justify-center gap-8px">{estimatePrice}</div>
      <Button
        bold
        onClick={onSubmit}
        className="p-5 mt-8px text-[14px]"
        loading={loading}
        disabled={loading || disabled}
      >
        {textButton}
      </Button>
      <Text textColor="text-neutral" fontSize={14} center>
        {textWarning}
      </Text>
    </div>
  );
}

export default OrderFooter;
