import Button from 'components/Button';
import React, { ChangeEvent, useEffect, useRef } from 'react';

type Props = {
  className?: string;
  onChange: (time: string) => void;
  name?: string;
  error?: boolean;
  value: string;
};

const TimePicker = ({
  className = '',
  onChange,
  name,
  error = false,
  value,
}: Props): JSX.Element => {
  const timeInputRef = useRef<HTMLInputElement>(null);

  const handleTimeChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedTime = event.target.value;
    onChange(selectedTime);
  };

  const openTimeInput = () => {
    if (timeInputRef.current) {
      timeInputRef.current.click();
      timeInputRef.current.focus();
    }
  };

  useEffect(() => {
    const timeInput = timeInputRef.current;
    const clickHandler = function () {
      timeInput?.showPicker();
    };
    if (timeInput) {
      timeInput.addEventListener('click', clickHandler);
      timeInput.addEventListener('focus', clickHandler);
    }
    return () => {
      if (timeInput) {
        timeInput.removeEventListener('click', clickHandler);
        timeInput.removeEventListener('focus', clickHandler);
      }
    };
  }, [timeInputRef]);

  return (
    <>
      <input
        type="time"
        ref={timeInputRef}
        onChange={handleTimeChange}
        name={name}
        value={value}
        className="cursor-pointer absolute rounded-[8px] h-0 w-0 bottom-0 focus:ring-0 ring-0"
      />
      <Button
        onClick={openTimeInput}
        border={
          error
            ? `ring-2 ring-error dark:border-error`
            : `ring-1 ring-neutral-300 focus:ring-2 focus:ring-primary`
        }
        bgColor="bg-white"
        iconLeft="clock"
        textColor="text-black"
        className={`${className} w-full py-3 relative`}
      >
        <div className="flex-1 flex justify-start items-start">
          {value || '-- : --'}
        </div>
      </Button>
    </>
  );
};

export default TimePicker;
