import AvatarChatGroup from 'components/AvatarGroup/AvatarChatGroup';
import Icon from 'components/Icon';
import Text from 'components/Text';
import { TEXT_STRING } from '../../../../constants';
import { TOrderHistory } from 'types';
import { formatAmountNumber, formatDate } from 'utils';

type Props = { data: TOrderHistory; onClick?(item: TOrderHistory): void };

const OrderHistoryListItem = ({ data, onClick }: Props) => {
  return (
    <div
      className={`flex gap-4 p-4 bg-neutral-50 items-center ${
        onClick ? 'cursor-pointer' : ''
      }`}
      onClick={() => {
        onClick && onClick(data);
      }}
    >
      <AvatarChatGroup
        size="h-16"
        avatars={
          data.females?.map((female) => ({
            url: female.avatar,
            disabled: female.inactive,
          })) || []
        }
      />
      <div className="flex-1">
        <Text>{formatDate(data.createdDatetime, 'LL - HH:mm')}</Text>
        <Text className="font-[500]">
          {`${data.location} - ${(data.rank || [])
            .map((item) => item.rank + item.count + TEXT_STRING.COMMON.PERSON)
            .join(' - ')}`}
        </Text>
      </div>
      {onClick && (
        <>
          <Text fontSize={14}>
            {formatAmountNumber(data.amount, data.currency)}
          </Text>
          <Icon icon="chevronRight" />
        </>
      )}
    </div>
  );
};

export default OrderHistoryListItem;
