import Text from 'components/Text';
import { TEXT_STRING } from '../../../../../constants';
import { useCallback } from 'react';
import { TProjectEstimatePrice, TTotalEstimatePrice } from 'types';
import Button from 'components/Button';
import Loading from 'components/Loading';
import { getFormattedAmount, validateGamePlayManyTime } from 'utils';

const STRINGS = TEXT_STRING.RELEASE_GIRL;
const total: {
  label: string;
  subLabel?: string;
  key: keyof TTotalEstimatePrice;
  note?: string;
  bold: boolean;
}[] = [
  { label: STRINGS.TOTAL_ABOVE, key: 'total', bold: false },
  {
    label: STRINGS.PRE_PAID,
    key: 'prepaid',
    bold: false,
    note: STRINGS.HANDLING_FEE,
  },
  { label: STRINGS.PROJECT_DISCOUNT, key: 'discount', bold: false },
  // { label: STRINGS.GAME_DISCOUNT, key: 'gamesDiscount', bold: false },

  {
    label: STRINGS.TOTAL_CASH,
    subLabel: STRINGS.TOTAL_CASH_NO_DISCOUNT,
    key: 'totalAfterDiscount',
    bold: true,
  },
  {
    label: STRINGS.TOTAL_CARD,
    subLabel: STRINGS.TOTAL_CARD_NO_DISCOUNT,
    key: 'totalAfterDiscountCardPayment',
    note: STRINGS.HANDLING_FEE,
    bold: true,
  },
];

type Props = {
  loading?: boolean;
  loadingRelease?: boolean;
  data?: TProjectEstimatePrice;
  onSubmit: () => void;
};
const Confirm = ({ loading, loadingRelease, data, onSubmit }: Props) => {
  const formatPrice = useCallback((value = 0) => {
    return `${getFormattedAmount(value)}${TEXT_STRING.COMMON.YEN}`;
  }, []);

  return (
    <div>
      <Text className="mb-2">{STRINGS.PAYMENT_CONFIRM}</Text>
      {loading ? (
        <div className="py-16">
          <Loading />
        </div>
      ) : (
        <div className="">
          {data?.females.map((female) => {
            return (
              <div key={female.femaleId} className="my-4">
                <Text bold>{female.nickname}</Text>
                <Text className="py-1">
                  {`${TEXT_STRING.RELEASE_GIRL.TOTAL_PRICE}: ${formatPrice(
                    female.total
                  )}`}
                  <b>{`（${female.setCount}${TEXT_STRING.COMMON.SET}）`}</b>
                </Text>
                {female.games.map((game) => {
                  return (
                    <Text className="py-1" key={game.id}>
                      {`${game.name}: ${
                        validateGamePlayManyTime(game.type)
                          ? game.orderCount
                          : ''
                      }${game.unit} ${formatPrice(game.total)}`}
                    </Text>
                  );
                })}
                {!!female.nominatedPrice && (
                  <Text className="py-1">{`${
                    STRINGS.NOMINATION_FEE
                  }: ${formatPrice(female.nominatedPrice)}`}</Text>
                )}
                {!!female.totalDiscount && (
                  <Text className="py-1">
                    {`${STRINGS.GAME_DISCOUNT}: ${formatPrice(
                      female.totalDiscount
                    )}`}
                  </Text>
                )}
              </div>
            );
          })}
          <Text className="mt-4" bold>
            {STRINGS.TOTAL}
          </Text>
          {total.map(({ label, subLabel, key, note, bold }) => {
            if (
              key === 'totalAfterDiscount' ||
              key === `totalAfterDiscountCardPayment`
            ) {
              const discount =
                (data?.payment['discount'] || 0) +
                (data?.payment['gamesDiscount'] || 0);

              return data?.payment[key] ? (
                <Text key={key} className="py-1" bold={bold}>
                  {discount && discount > 0 ? label : subLabel}:{' '}
                  {formatPrice(data?.payment[key])}
                  {note}
                </Text>
              ) : null;
            }

            return data?.payment[key] ? (
              <Text key={key} className="py-1" bold={bold}>
                {label}: {formatPrice(data?.payment[key])}
                {note}
              </Text>
            ) : null;
          })}
        </div>
      )}
      <div className="py-4">
        <Button
          onClick={onSubmit}
          block
          disabled={loading || loadingRelease}
          loading={loadingRelease}
        >
          {TEXT_STRING.COMMON.CONTINUE}
        </Button>
      </div>
    </div>
  );
};

export default Confirm;
