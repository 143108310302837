import AvatarGroup from 'components/AvatarGroup';
import Header from 'components/Header';
import { useNavigate } from 'react-router-dom';
import { EChannelType, TChannelDetail, TMember } from 'types';
import { Popover, Transition } from '@headlessui/react';
import Avatar from 'components/Avatar';
import Icon from 'components/Icon';
import Text from 'components/Text';
import { memo } from 'react';
import ChatOrder from '../ChatOrder';

type Props = {
  channelData?: TChannelDetail;
  users: TMember[];
  onClick: (member: TMember) => void;
};

type THeaderMenuProps = {
  users: TMember[];
  onClick: (member: TMember) => void;
};

const HeaderMenu = ({ users, onClick }: THeaderMenuProps) => {
  return (
    <Popover className="">
      <Popover.Button className="inline-flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900">
        <Icon icon="dotVertical" />
      </Popover.Button>

      <Transition
        enter="transition ease-out duration-200"
        leave="transition ease-in duration-150"
      >
        <Popover.Panel className="absolute z-10 mt-1 flex max-w-[100%] right-0 px-4">
          <div className="max-h-[40dvh] flex-auto overflow-x-hidden overflow-y-auto rounded-xl bg-white text-sm leading-6 shadow-lg ring-1 ring-gray-900/5">
            <div className="py-2">
              {users.map((item, index) => (
                <div
                  key={item.uuid + index}
                  className={`relative flex gap-x-2 rounded-lg px-4 py-2 hover:bg-gray-50 items-center cursor-pointer ${
                    item.inactive ? 'opacity-50' : ''
                  }`}
                  onClick={() => onClick(item)}
                >
                  <div className="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                    <Avatar url={item.avatar} circle />
                  </div>
                  <div className="w-full">
                    <p className="mt-1 text-gray-600 line-clamp-1 ">
                      {item.name}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};

const ChatHeader = ({ channelData, users, onClick }: Props) => {
  const navigate = useNavigate();

  return (
    <Header
      onBack={() => navigate(-1)}
      title={
        <div
          className="max-w-[80%] flex gap-2 justify-center items-center w-full py-4 cursor-pointer"
          onClick={() => {
            if (channelData?.type === EChannelType.PRIVATE) {
              onClick(users?.[0]);
            }
          }}
        >
          <AvatarGroup
            size="h-9"
            avatars={users.map(({ avatar, inactive }) => ({
              url: avatar,
              disabled: inactive,
            }))}
          />
          <Text className="text-ellipsis overflow-hidden max-w-[75%]" nowrap>
            {channelData?.name ||
              users.map(({ id, name, inactive }, index, arr) => (
                <span
                  key={index}
                  className={id && inactive ? 'opacity-50' : ''}
                >
                  {name}
                  {index + 1 >= arr.length ? '' : ', '}
                </span>
              ))}
          </Text>
        </div>
      }
      suffix={
        users.length > 1 ? (
          <HeaderMenu users={users} onClick={onClick} />
        ) : (
          <ChatOrder
            channelId={channelData?.channelId}
            users={users}
            channelType={channelData?.type}
          />
        )
      }
    />
  );
};

export default memo(ChatHeader);
