import axios from 'axios';
import {
  TListFemale,
  TFemaleDetail,
  TFilterFemale,
  TPostResponse,
} from 'types';

const getFemaleList = async (payload: {
  filter: TFilterFemale;
  currentPage: number;
  pageSize: number;
}): Promise<TListFemale> => {
  const { currentPage, pageSize, filter } = payload;
  const res = await axios
    .post('female/filter', {
      currentPage: currentPage,
      pageSize: pageSize,
      rank: filter.ranks,
      starIds: filter.starIds,
      options: filter.options,
      activityAreas: filter.activityAreas,
      startTime: filter.schedules.startTime,
      endTime: filter.schedules.endTime,
      singleCoupon: filter.discount.singleCoupon,
      groupCoupon: filter.discount.groupCoupon,
    })
    .then(function (response) {
      return response.data;
    });

  return res;
};

const getFemaleDetail = async (id: number): Promise<TFemaleDetail> => {
  const res = await axios.get(`female/profile/${id}`).then(function (response) {
    return response.data;
  });

  return res;
};

const getFemalePosts = async (params: {
  userId: number;
  currentPage: number;
  pageSize: number;
}): Promise<TPostResponse> => {
  const res = await axios.get('post', { params }).then(function (response) {
    return response.data;
  });

  return res;
};

export { getFemaleList, getFemaleDetail, getFemalePosts };
