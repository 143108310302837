import { create } from 'zustand';

type State = {
  bannerHeight?: number;
  postRecommendScrollY?: number;
  postFollowedScrollY?: number;
};

type Actions = {
  setBannerHeight: (height: number) => void;
  setPostRecommendScrollY: (value?: number) => void;
  setPostFollowedScrollY: (value?: number) => void;
};

const usePostStore = create<State & Actions>((set) => ({
  setBannerHeight: (height: number) =>
    set(() => ({
      bannerHeight: height,
    })),
  setPostRecommendScrollY: (value?: number) => {
    set(() => ({ postRecommendScrollY: value }));
  },
  setPostFollowedScrollY: (value?: number) => {
    set(() => ({ postFollowedScrollY: value }));
  },
}));

export default usePostStore;
