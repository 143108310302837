import React, { useCallback } from 'react';
import Datetime, { DatetimepickerProps } from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import Icon from 'components/Icon';
import TextField from 'components/TextField';
import 'moment/locale/ja';
import { TEXT_STRING } from '../../constants';
import Chip from 'components/Chip';
import { formatDate } from 'utils';

type Props = DatetimepickerProps & {
  label?: string;
  require?: boolean;
  bold?: boolean;
  textSize?: `text-${string}`;
  error?: boolean;
  disabled?: boolean;
  inputClass?: string;
  format?: string;
};

const DatePicker = ({
  label,
  bold,
  textSize,
  error,
  disabled,
  require,
  renderInput,
  inputClass = '',
  format = 'LL',
  ...otherProps
}: Props) => {
  const customRenderInput = useCallback(
    (props: any, openCalendar: Function) => {
      return (
        <div
          className="relative w-full"
          onClick={() => openCalendar()}
        >
          <TextField
            {...props}
            className={inputClass}
            error={error}
            name={''}
            value={formatDate(props.value, format)}
            onChange={() => {}}
            inputClass="py-10px"
            readOnly
            prefix={
              <div className="pl-10px">
                <Icon icon={'calendar'} />
              </div>
            }
          />
        </div>
      );
    },
    [error, format, inputClass]
  );

  return (
    <>
      {label && (
        <label
          className={`mb-2 block leading-6 ${
            bold ? 'font-bold' : 'font-medium'
          } ${textSize} ${error ? 'text-error' : 'text-neutral-600'}
					${disabled ? 'opacity-50' : ''}`}
        >
          {label}
          {require && (
            <Chip
              textColor="text-white"
              className="ml-4"
              padding="py-0.25 px-2"
              textSize="text-[10px]"
              filled
            >
              {TEXT_STRING.COMMON.REQUIRE}
            </Chip>
          )}
        </label>
      )}
      <Datetime
        renderInput={renderInput || customRenderInput}
        timeFormat={false}
        {...otherProps}
      />
    </>
  );
};

export default DatePicker;
