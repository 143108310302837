import { memo, useEffect, useRef, useState } from 'react';
import { TPostDetail } from 'types';
import { PostItem } from 'components';
import { PostContext } from 'contexts';

type Props = {
  data: TPostDetail[];
};

function PostList({ data }: Props) {
  const ref = useRef<HTMLDivElement>(null);

  const [galleryWidth, setGalleryWidth] = useState<number | undefined>(
    undefined
  );

  useEffect(() => {
    if (ref.current?.offsetWidth && galleryWidth !== ref.current?.offsetWidth) {
      setGalleryWidth(ref.current.offsetWidth);
    }
  }, [galleryWidth, setGalleryWidth]);

  return (
    <PostContext.Provider value={{ galleryWidth, setGalleryWidth }}>
      <div className={`flex gap-3 h-0`}>
        <div className={`flex-[1]`}></div>
        <div className={`flex-[7]`} ref={ref}></div>
      </div>
      <div>
        {data?.map((post, index) => {
          return (
            <div key={post.id}>
              <PostItem data={post} disableClick />
              {index < data.length - 1 && <hr className="solid my-8" />}
            </div>
          );
        })}
      </div>
    </PostContext.Provider>
  );
}

export default memo(PostList);
