import { DAY_IN_WEEK } from '../../constants';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { TSchedule } from 'types';
import { formatDate, getCurrentWeeks } from 'utils';

type Props = {
  schedules: TSchedule[];
};

const Spacing = () => {
  return <>&nbsp;</>;
};

function ListSchedule({ schedules }: Props) {
  const [groupSchedule, setGroupSchedule] = useState<TSchedule[]>([]);

  useEffect(() => {
    setGroupSchedule(() => {
      if (!schedules?.length) {
        return getCurrentWeeks({});
      }
      const lastScheduleStartDate = moment(
        schedules[schedules.length - 1].startDate
      );

      const scheduleWithoutShift = getCurrentWeeks({
        currentDate: lastScheduleStartDate.clone().add({ days: 1, hours: 11 }),
        range: DAY_IN_WEEK - schedules.length,
      });
      return [...schedules, ...scheduleWithoutShift];
    });
  }, [schedules]);

  return (
    <div className="grid grid-cols-7 gap-x-2 mt-6">
      {groupSchedule?.map((schedule, index) => {
        const {
          startDate,
          startTime,
          endTime,
          disabled,
          startDateFormat,
          activityArea,
        } = schedule;

        return (
          <div
            key={index}
            className={`border border-solid border-neutral-300 rounded-xl font-medium text-neutral text-center`}
          >
            <div className="py-2 flex flex-col items-center gap-1">
              <span>
                {startDateFormat || formatDate(startDate, 'MM/DD\n (dd)')}
              </span>
            </div>
            <div
              className={`border-y border-solid border-neutral-300 py-2 
                ${
                  schedules.some((schedule) => !schedule.disabled) &&
                  'whitespace-pre-line'
                }
              `}
            >
              {disabled || !startTime ? (
                <>
                  <Spacing />
                  <br />-<br />
                  <Spacing />
                </>
              ) : (
                `${startTime}\n-\n${endTime || '\n'}`
              )}
            </div>
            <div className={`py-2`}>
              {disabled || !activityArea?.name ? (
                <Spacing />
              ) : (
                `${activityArea.name}`
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default ListSchedule;
