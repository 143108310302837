import { Button, CodeInput, Text } from 'components';
import React from 'react';

type Props = {
  phone: string;
  loading?: boolean;
  verifyOTP(otp: string): void;
  resendOTP(): void;
};

const CODE_LENGTH = 6;
let timeout: NodeJS.Timeout;

function Verify({ phone, loading, verifyOTP, resendOTP }: Props) {
  const [code, setCode] = React.useState([...Array(CODE_LENGTH)].map(() => ''));
  const [disableResend, setDisableResend] = React.useState<boolean>(true);

  React.useEffect(() => {
    if (disableResend) {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        setDisableResend(false);
      }, 10000);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [disableResend, setDisableResend]);

  const onVerify = React.useCallback(() => {
    const otp = code.join('');
    if (otp.length === CODE_LENGTH) {
      verifyOTP(otp);
    }
  }, [verifyOTP, code]);

  const onResend = React.useCallback(() => {
    resendOTP();
    setDisableResend(true);
  }, [resendOTP, setDisableResend]);

  return (
    <>
      <div className="bg-white">
        <div className="text-center">
          <Text center className="">
            コードを入力してください
          </Text>
          <Text center className="mt-8px">
            {`${phone} に送信されたコードを入力してください。`}
          </Text>
        </div>
        <CodeInput code={code} setCode={setCode} length={CODE_LENGTH} />
      </div>
      <div className="mt-16px">
        <Button onClick={onVerify} block disabled={loading}>
          完了
        </Button>
        <Button
          onClick={onResend}
          block
          textColor="text-primary"
          bgColor="bg-white"
          className="py-0 mt-4"
          disabled={disableResend || loading}
        >
          コードを再送してください
        </Button>
      </div>
    </>
  );
}

export default Verify;
