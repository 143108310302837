import ConcatText from 'components/ConcatText';
import Icon from 'components/Icon';
import Rating from 'components/Rating';
import Text from 'components/Text';
import { RANK_STYLES, TEXT_STRING } from '../../constants';
import { TFemale } from 'types';
import { useMemo } from 'react';
import NoAvatar from '../../assets/images/avatar.svg';

type Props = {
  isChoose?: boolean;
  onChoose: () => void;
  onDetail: () => void;
  data: TFemale;
  disabledChoose?: boolean;
  isMeeting?: boolean;
  showShiftTime?: boolean;
  icon?: string;
};

const CardGirl = ({
  isChoose = false,
  onChoose,
  onDetail,
  data,
  disabledChoose,
  showShiftTime = false,
  icon = 'check',
}: Props): JSX.Element => {
  const styles = useMemo(
    () => (data.rank ? RANK_STYLES[data.rank?.id] : RANK_STYLES[1]),
    [data.rank]
  );

  return (
    <div
      className={`rounded-[8px] w-40 border ${
        isChoose ? 'border-primary' : 'border-neutral-200'
      } flex flex-col cursor-pointer flex-shrink-0 justify-center items-center`}
      onClick={onDetail}
    >
      <div className="w-full relative">
        <img
          alt=""
          src={data.avatar}
          className="aspect-square object-cover object-top w-full rounded-t-[8px]"
          draggable="false"
          onError={(e) => (e.currentTarget.src = NoAvatar)}
        />
        <div
          className={`absolute flex justify-center items-center w-18px h-18px rounded-[4px] border ${
            isChoose ? 'bg-primary-50 border-primary' : 'border-neutral'
          } top-6px right-6px ${disabledChoose && !isChoose ? 'hidden' : ''}`}
          onClick={(e) => {
            e.stopPropagation();
            onChoose();
          }}
        >
          {isChoose && <Icon icon={icon} />}
        </div>
        <div
          className="p-1 relative bottom-0 w-full bg-cover"
          style={{
            backgroundImage: `url(${styles.bg})`,
          }}
        >
          <Text textColor={styles.color} center bold fontSize={14}>
            {data.rank?.rank}
          </Text>
        </div>
      </div>
      <div
        className={`w-full h-full text-[12px] flex flex-col gap-2 items-center px-8px py-4px rounded-b-[8px] ${
          isChoose && 'bg-primary-50'
        }`}
      >
        <Rating value={data.point} />
        <div className="flex items-center justify-center gap-2 w-full">
          <ConcatText
            prefixText={data.nickname}
            suffixText={data.userId || data.id}
            hyphen=""
            className={`font-bold ${
              data.isPeriodTime ? 'max-w-[80%]' : 'max-w-full'
            }`}
          />
          {data.isPeriodTime && (
            <img src="/images/menstruation.png" alt="" className="w-5 h-5" />
          )}
        </div>
        <Text className="line-clamp-1">{`${data.height || ''}cm(${
          data.cupSize
        }${TEXT_STRING.CAST_PAGE.CUP})`}</Text>
        {showShiftTime && data.shifts?.length && (
          <Text className="line-clamp-1">{`${data.shifts[0].startTime} - ${data.shifts[0].endTime}`}</Text>
        )}
      </div>
    </div>
  );
};

export default CardGirl;
