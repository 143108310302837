import { useCallback, useState } from 'react';
import { TCard } from 'types';
import 'react-phone-number-input/style.css';
import PaymentCardItem from './PaymentCardItem';
import Modal from 'components/Modal';
import Text from 'components/Text';
import { TEXT_STRING } from '../../constants';
import Button from 'components/Button';
import { useMutation } from '@tanstack/react-query';
import { deleteCard } from 'api';
import InfiniteScroll from 'react-infinite-scroll-component';
import Loading from 'components/Loading';

type Props = {
  isEdit?: boolean;
  data: TCard[];
  selectedCard?: TCard;
  hasMore: boolean;
  onSelectCard: (card: TCard) => void;
  fetchMore: () => void;
  refetchAPI: () => void;
};

function PaymentCardList({
  isEdit,
  data,
  selectedCard,
  hasMore,
  onSelectCard,
  fetchMore,
  refetchAPI,
}: Props) {
  const [cardDeleted, setCardDeleted] = useState<TCard | undefined>();
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const { mutateAsync, isPending } = useMutation({
    mutationFn: (id: number) => {
      return deleteCard(id);
    },
  });

  const onDeleteCard = useCallback((card: TCard) => {
    setCardDeleted(card);
  }, []);

  const onCancel = useCallback(() => {
    setCardDeleted(undefined);
  }, []);

  const onConfirm = useCallback(async () => {
    if (isEdit && cardDeleted) {
      try {
        await mutateAsync(cardDeleted.id);
        setCardDeleted(undefined);
        refetchAPI();
      } catch (error) {
        if (error instanceof Error) {
          setErrorMessage(error.message);
        }
      }
    }
  }, [isEdit, mutateAsync, cardDeleted, refetchAPI]);

  return (
    <div className="py-4">
      <InfiniteScroll
        next={fetchMore}
        inverse={true}
        hasMore={hasMore}
        loader={
          <div className="py-[36px] flex justify-center items-center">
            <Loading />
          </div>
        }
        dataLength={data?.length}
        scrollThreshold={0.5}
        className="gap-4 flex flex-col-reverse overflow-hidden"
        scrollableTarget="scrollablePayment"
      >
        <div className="">
          {data.map((card) => (
            <PaymentCardItem
              key={card.id}
              data={card}
              isSelected={selectedCard?.id === card.id}
              onDeleteCard={() => onDeleteCard(card)}
              onSelectCard={() => {
                if (selectedCard?.id !== card.id) {
                  onSelectCard(card);
                }
              }}
              isEdit={isEdit}
            />
          ))}
        </div>
      </InfiniteScroll>

      <Modal
        open={!!cardDeleted}
        onClose={onCancel}
        className="bg-white w-full mx-20px pt-24px p-16px"
      >
        <Text bold center fontSize={16} className="break-keep">
          {TEXT_STRING.COMMON.CONFIRM_DELETE}
        </Text>
        <div className="mt-24px">
          <Button
            onClick={onConfirm}
            bgColor="bg-error-400"
            textSize="text-[14px]"
            bold
            block
            loading={isPending}
            disabled={isPending}
          >
            {TEXT_STRING.COMMON.YES}
          </Button>
          <Button
            onClick={onCancel}
            bgColor="bg-white"
            textColor="text-error-400"
            textSize="text-[14px]"
            block
            bold
            disabled={isPending}
          >
            {TEXT_STRING.COMMON.NO}
          </Button>
          {errorMessage && (
            <Text className="py-4" textColor="text-error">
              {errorMessage}
            </Text>
          )}
        </div>
      </Modal>
    </div>
  );
}

export default PaymentCardList;
