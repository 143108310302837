import { QueryObserverResult, RefetchOptions } from '@tanstack/react-query';
import { createContext } from 'react';
import { TProjectLeverage } from 'types';

type TConversationContext = {
  // Leverage created by existing user
  userLeverageId?: number;
  // Leverage created by other user and requires current user approval or cancellation
  pendingLeverageId?: number;
  // Refetch leverage data
  refetchLeverage?: (
    options?: RefetchOptions
  ) => Promise<QueryObserverResult<TProjectLeverage[], Error>>;
};

const ConversationContext = createContext<TConversationContext>({});

export default ConversationContext;
