import React, { Suspense } from 'react';
import { IMAGE_ACCEPTED } from '../../constants';

type Props = {
  file: File;
  className?: string;
  onRemove?: () => void;
};

const IconButton = React.lazy(() =>
  new Promise((resolve) => {
    setTimeout(resolve, 100);
  }).then(() => import('components/IconButton'))
);

function FileView({ file, className = '', onRemove }: Props) {
  return (
    <div className="h-full relative flex-shrink-0">
      {onRemove && (
        <Suspense fallback={<></>}>
          <IconButton
            onClick={onRemove}
            icon="close"
            className="absolute top-0 right-0 z-[1]"
            transparent
          />
        </Suspense>
      )}
      {IMAGE_ACCEPTED.includes(file.type) ? (
        <img
          alt=""
          src={URL.createObjectURL(file)}
          className={`${className}`}
        />
      ) : (
        <video className={`${className}`} autoPlay muted playsInline loop>
          <source src={URL.createObjectURL(file)} />
        </video>
      )}
    </div>
  );
}

export default FileView;
