import { create } from 'zustand';

type State = {
  loadingFollow: number[];
};

type Actions = {
  addToFollowingList: (value: number) => void;
  removeToFollowingList: (value: number) => void;
};

const useFollowStore = create<State & Actions>((set) => ({
  loadingFollow: [],
  addToFollowingList: (value: number) =>
    set((state) => ({ loadingFollow: [...state.loadingFollow, value] })),
  removeToFollowingList: (value: number) =>
    set((state) => ({
      loadingFollow: state.loadingFollow.filter((userId) => userId !== value),
    })),
}));

export default useFollowStore;
