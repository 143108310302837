import { useQueryClient } from '@tanstack/react-query';
import { queryKeys } from '../../../constants';
import { EditProfileContext } from 'contexts';
import { useCallback, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { TDraftUser, TDraftUserInput } from 'types';

function EditMalePage() {
  const queryClient = useQueryClient();

  const [draftUser, setDraftUser] = useState<TDraftUser>();

  const handleChangeDraft = useCallback((draft: TDraftUserInput) => {
    setDraftUser((prev) => ({
      ...(prev as TDraftUser),
      [draft.key]: draft.value,
    }));
  }, []);

  const refetchCurrentUser = useCallback(async () => {
    await queryClient.refetchQueries({ queryKey: [queryKeys.CURRENT_USER] });
  }, [queryClient]);

  useEffect(() => {
    refetchCurrentUser();
  }, [refetchCurrentUser]);

  return (
    <>
      <EditProfileContext.Provider
        value={{
          draftUser,
          setDraftUser: handleChangeDraft,
          removeDraftUser() {
            setDraftUser(undefined);
          },
          refetchCurrentUser,
        }}
      >
        <Outlet />
      </EditProfileContext.Provider>
    </>
  );
}

export default EditMalePage;
