import {
  ProfileBiography,
  Header,
  RequestImage,
  ToggleButton,
  UpdateAvatar,
  UploadImage,
  TextField,
  Button,
  Label,
  Text,
} from 'components';
import { LIMIT_VALUE, TEXT_STRING, routes } from '../../../../constants';
import {
  TTextFieldChangeEvent,
  EStatusProfile,
  TBodyProfile,
  TDraftUser,
} from 'types';
import { useMutation } from '@tanstack/react-query';
import { useCallback, useContext, useMemo, useState } from 'react';
import { useToast, useUploadFile } from 'hooks';
import { useNavigate } from 'react-router-dom';
import { profileField } from 'helpers';
import { useUserStore } from 'store';
import { AxiosError } from 'axios';
import { editProfile } from 'api';
import { convertFormatAmount, getFormattedAmount } from 'utils';
import { EditProfileContext } from 'contexts';

const maleStr = TEXT_STRING.MALE_PAGE;
const commonStr = TEXT_STRING.COMMON;

const listSetting = [maleStr.UTILIZATION_AMOUNT, maleStr.TOTAL_USES];

function EditMale() {
  // Hook
  const { draftUser, removeDraftUser, setDraftUser, refetchCurrentUser } =
    useContext(EditProfileContext);
  const { user } = useUserStore();
  const { toastError, toastSuccess } = useToast();
  const { uploadToS3 } = useUploadFile();
  const navigate = useNavigate();

  // State
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string[]>([]);

  // Mutation
  const { mutateAsync: mutateEditProfile } = useMutation({
    mutationFn: (body: TBodyProfile) => {
      return editProfile(body);
    },
    onSuccess() {
      toastSuccess(commonStr.SUCCESS);
    },
  });

  // Memo, callbacks
  const [
    draftBiography,
    showInfo,
    cancelCollection,
    deleteCollection,
    newCollection,
    profileCollection,
  ] = useMemo(() => {
    const draftBiography =
      draftUser?.biography ?? user?.profileCollection?.biography?.content ?? '';
    const showInfo = draftUser?.showInfo ?? user?.total.showInvoiceAndOrder;
    return [
      draftBiography,
      showInfo,
      draftUser?.cancelCollection ?? [],
      draftUser?.deleteCollection ?? [],
      draftUser?.newCollection ?? [],
      user?.profileCollection.collection ?? [],
    ];
  }, [draftUser, user]);

  const handleChangeInfo = useCallback(
    (e: TTextFieldChangeEvent) => {
      const { name, value } = e.target;
      if (+value || +value === 0) {
        setDraftUser({ key: name as keyof TDraftUser, value: +value });
      }
    },
    [setDraftUser]
  );

  const handleFocusInfo = useCallback(
    (e: TTextFieldChangeEvent) => {
      const { name, value } = e.target;
      if (value)
        setDraftUser({
          key: name as keyof TDraftUser,
          value: convertFormatAmount(value),
        });
    },
    [setDraftUser]
  );

  const handleBlurInfo = useCallback(
    (e: TTextFieldChangeEvent) => {
      const { name, value } = e.target;
      if (value)
        setDraftUser({
          key: name as keyof TDraftUser,
          value: getFormattedAmount(+value),
        });
    },
    [setDraftUser]
  );
  const validateNumber = useCallback((value = 0, min: number, max: number) => {
    return !(+value >= min && +value <= max);
  }, []);

  const handleEditInfo = useCallback(async () => {
    try {
      let errorList = [];

      if (!draftBiography) errorList.push('biography');
      profileField.forEach((item) => {
        if (
          validateNumber(
            convertFormatAmount(draftUser?.[item.name] ?? user?.[item.name]),
            item.min,
            item.max
          )
        )
          errorList.push(item.name);
      });
      setError(errorList);
      if (errorList.length) return;
      setIsLoading(true);
      const [uploadedCollections, avatarUpload] = await Promise.all([
        newCollection?.length ? uploadToS3({ files: newCollection }) : [],
        !!draftUser?.avatar ? uploadToS3({ files: [draftUser?.avatar] }) : [],
      ]);
      await mutateEditProfile({
        biography: draftUser?.biography,
        customer: {
          age: convertFormatAmount(draftUser?.age ?? user?.age),
          income: convertFormatAmount(draftUser?.income ?? user?.income),
          showInvoiceAndOrder: !!showInfo,
          avatar: avatarUpload[0]?.uploadedUrl,
        },
        collection: uploadedCollections.map((signed) => {
          return { url: signed.uploadedUrl };
        }),
        cancelCollection,
        deleteCollection,
      });
      await refetchCurrentUser();
      removeDraftUser();
    } catch (error) {
      toastError(error as AxiosError);
    } finally {
      setIsLoading(false);
    }
  }, [
    refetchCurrentUser,
    mutateEditProfile,
    cancelCollection,
    deleteCollection,
    removeDraftUser,
    validateNumber,
    newCollection,
    draftBiography,
    uploadToS3,
    toastError,
    draftUser,
    showInfo,
    user,
  ]);

  return (
    <>
      <div className="h-full overflow-y-auto">
        <Header
          title={maleStr.EDIT_PROFILE}
          suffix={
            <Button
              onClick={() => navigate(routes.PREVIEW_MALE)}
              rounded="rounded-md"
              textSize="text-[10px]"
              padding="py-2 px-4"
              className="whitespace-nowrap"
            >
              {maleStr.PREVIEW}
            </Button>
          }
        />
        <div className="flex flex-col gap-4 p-4">
          {/* Avatar */}
          <>
            <Text bold fontSize={14}>
              {maleStr.AVATAR}
            </Text>
            <UpdateAvatar
              onUpdateAvatar={(file) => {
                setDraftUser({ key: 'avatar', value: file });
              }}
              avatar={
                draftUser?.avatar ??
                user?.profileCollection.avatar.url ??
                user?.avatar
              }
              status={
                user?.profileCollection.avatar.status === EStatusProfile.PENDING
                  ? commonStr.PENDING
                  : ''
              }
            />
          </>
          {/* Collections */}
          <div>
            <Text bold fontSize={14}>
              {maleStr.PROFILE_IMAGE}
            </Text>
            <div className="grid grid-cols-4 gap-4">
              <RequestImage
                collections={newCollection}
                onSelectCollection={(files) => {
                  setDraftUser({ key: 'newCollection', value: files });
                }}
                showUpload={
                  newCollection?.length +
                    profileCollection?.length -
                    cancelCollection?.length -
                    deleteCollection?.length <
                  LIMIT_VALUE.MAX_REQUEST_IMAGE
                }
              />
              {user?.profileCollection.collection?.map((collection) => {
                const isPending = collection.status === EStatusProfile.PENDING;
                const showCollections =
                  (!cancelCollection?.includes(collection.id) && isPending) ||
                  (!deleteCollection?.includes(collection.id) && !isPending);

                return (
                  showCollections && (
                    <UploadImage
                      key={collection.id}
                      image={collection.url}
                      onRemove={() =>
                        setDraftUser({
                          key: isPending
                            ? 'cancelCollection'
                            : 'deleteCollection',
                          value: isPending
                            ? [...cancelCollection, collection.id]
                            : [...deleteCollection, collection.id],
                        })
                      }
                      width="w-12"
                      status={isPending ? commonStr.PENDING : ''}
                    />
                  )
                );
              })}
            </div>
          </div>
          {/* Info */}
          <>
            <ProfileBiography
              note={draftBiography}
              onChangeNote={(e) => {
                setDraftUser({ key: 'biography', value: e.target.value });
              }}
              error={error.includes('biography')}
              pending={
                user?.profileCollection?.biography?.status ===
                EStatusProfile.PENDING
              }
            />
            {profileField.map((field, index) => (
              <div className="grid grid-cols-3 items-center" key={index}>
                <Label
                  bold
                  className="m-0"
                  error={error.includes(field.name)}
                  textSize="text-[14px]"
                >
                  {field.label}
                </Label>
                <TextField
                  error={error.includes(field.name)}
                  name={field.name}
                  value={
                    draftUser?.[field.name] ??
                    getFormattedAmount(user?.[field.name])
                  }
                  onChange={handleChangeInfo}
                  className="mr-4"
                  inputClass="text-right"
                  onBlur={handleBlurInfo}
                  onFocus={handleFocusInfo}
                  type="tel"
                />
                <Text>{field.unit}</Text>
              </div>
            ))}
          </>
          {/* Setting */}
          <>
            <div className="flex items-center justify-between">
              <Text bold fontSize={14}>
                {maleStr.USING_TECO}
              </Text>
              <ToggleButton
                onToggle={() =>
                  setDraftUser({
                    key: 'showInfo',
                    value: !showInfo,
                  })
                }
                isToggle={!!showInfo}
              />
            </div>
            {listSetting.map((setting, index) => (
              <Text key={index} className="pl-4" fontSize={14}>
                {setting}
              </Text>
            ))}
          </>
        </div>
        <div className="p-4 sticky bottom-0 left-0">
          <Button
            onClick={handleEditInfo}
            block
            className="py-6 "
            loading={isLoading}
            disabled={isLoading || !draftUser}
            textSize="text-[14px]"
          >
            {commonStr.KEEP}
          </Button>
        </div>
      </div>
    </>
  );
}

export default EditMale;
