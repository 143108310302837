import React, { PropsWithChildren } from 'react';
import Icon from 'components/Icon';

type Props = PropsWithChildren<{
  onChangeFiles: (files: File[]) => void;
  multiple?: boolean;
  accept?: string;
  maxLength?: number;
  disabled?: boolean;
  buttonClass?: string;
}>;

const UploadFile = ({
  children,
  onChangeFiles,
  multiple = false,
  accept,
  maxLength,
  disabled = false,
  buttonClass = '',
}: Props) => {
  const fileInputRef = React.useRef<HTMLInputElement>(null);

  const onChange = () => {
    if (fileInputRef.current) {
      const files = fileInputRef.current?.files;
      if (files && files.length) {
        onChangeFiles(Array.from(files));
      }
      fileInputRef.current.value = '';
    }
  };

  return (
    <>
      <input
        type="file"
        ref={fileInputRef}
        className="hidden"
        onChange={onChange}
        accept={accept}
        multiple={multiple}
        maxLength={maxLength}
      />
      <button
        onClick={() => {
          if (!disabled) {
            fileInputRef?.current?.click();
          }
        }}
        className={`${buttonClass} ${disabled ? 'opacity-50' : ''}`}
        disabled={disabled}
      >
        {children || <Icon icon={'image'} />}
      </button>
    </>
  );
};

export default UploadFile;
