import Loading from 'components/Loading';
import Text from 'components/Text';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useUserStore } from 'store';
import MessageItem from '../MessageItem';
import { TEXT_STRING } from '../../../constants';
import moment from 'moment';
import {
  EChannelType,
  EUserProvider,
  TChannelDetail,
  TMember,
  TMessageDetail,
} from 'types';

type Props = {
  isLoading?: boolean;
  messages: TMessageDetail[];
  fetchMore: () => void;
  hasMore: boolean;
  members: { [x: string]: TMember };
  channelData?: TChannelDetail;
  onClickAvatar: (data: TMember) => void;
};

const MessageList = ({
  isLoading,
  messages,
  fetchMore,
  hasMore,
  members,
  channelData,
  onClickAvatar,
}: Props) => {
  const { user, clientId } = useUserStore();

  return (
    <div
      id="scrollableMessage"
      className="h-full gap-4 overflow-y-auto p-4 flex flex-col-reverse"
    >
      {isLoading ? (
        <div className="m-auto">
          <Loading />
        </div>
      ) : !messages.length ? (
        <Text className="m-auto tracking-widest" center bold>
          {channelData?.type === EChannelType.MALE_MANAGEMENT
            ? TEXT_STRING.MESSAGE.QUESTIONS_TO_MANAGEMENT
            : channelData?.provider === EUserProvider.MALE_APPLICATION
            ? TEXT_STRING.MESSAGE.START_MESSAGE
            : TEXT_STRING.COMMON.SOMEONE_WANT_CONTACT}
        </Text>
      ) : (
        <>
          <InfiniteScroll
            next={fetchMore}
            inverse={true}
            hasMore={hasMore}
            loader={
              <div className="py-[36px] flex justify-center items-center">
                <Loading />
              </div>
            }
            dataLength={messages.length}
            scrollThreshold={0.5}
            className="gap-4 flex flex-col-reverse overflow-hidden"
            scrollableTarget="scrollableMessage"
          >
            {messages.map((item, index, arr) => {
              const isMyMessage = [user?.uuid, clientId].includes(item.uuid);
              const msgTime = +item.timetoken / 10000;
              const beforeMsgTime = +arr[index + 1]?.timetoken / 10000;
              const showAvatar =
                item.uuid !== arr[index + 1]?.uuid ||
                moment(msgTime).diff(beforeMsgTime, 'hours') > 1;

              return (
                <div key={item.timetoken} className="overflow-hidden">
                  {!moment(msgTime).isSame(beforeMsgTime, 'day') ||
                  isNaN(beforeMsgTime) ? (
                    <Text center className="mb-4">
                      {moment(msgTime).format('ll (dd) HH:mm')}
                    </Text>
                  ) : moment(msgTime).diff(beforeMsgTime, 'hours') > 1 ? (
                    <Text center className="mb-4">
                      {moment(msgTime).format('HH:mm')}
                    </Text>
                  ) : null}
                  <MessageItem
                    message={item}
                    isMyMessage={isMyMessage}
                    showAvatar={showAvatar}
                    user={members[item.uuid]}
                    onClickAvatar={() => onClickAvatar(members[item.uuid])}
                  />
                </div>
              );
            })}
          </InfiniteScroll>
        </>
      )}
    </div>
  );
};

export default MessageList;
