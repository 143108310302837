import axios from 'axios';
import { TSetting } from 'types';

const settingNotification = async (body: TSetting) => {
  return await axios
    .patch('notification/setting/male', body)
    .then((response) => {
      return response.data;
    });
};

const settingSendEmail = async (body: TSetting) => {
  return await axios.patch('email/setting/male', body).then((response) => {
    return response.data;
  });
};

export { settingNotification, settingSendEmail };
