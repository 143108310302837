import { Button, Rating, Text } from 'components';
import { AUTO_PRICE, queryKeys, TEXT_STRING } from '../../constants';
import React, { ReactElement, useCallback } from 'react';
import { getFormattedAmount } from 'utils';
import BgPrice from '../../assets/images/bgPrice.svg';
import { useQuery } from '@tanstack/react-query';
import { getStarRank } from 'api';

const COMMON_STR = TEXT_STRING.COMMON;
const MENU_STR = TEXT_STRING.PRICE_LIST;
const tableCss =
  'w-full border-separate rounded-xl border-t border-r border-solid border-neutral border-spacing-0';

type Props = {
  onNext: () => void;
  isOnlyShowTravel?: boolean;
};

function Item({
  content,
  className = '',
}: {
  content: string | ReactElement;
  className?: string;
}) {
  return (
    <td
      align="center"
      className={`${className} w-1/3 border border-r-0 border-t-0 border-solid border-neutral py-4 bg-contain`}
      style={{ backgroundImage: `url(${BgPrice})` }}
    >
      {typeof content === 'string' ? (
        <Text center bold fontSize={14}>
          {content}
        </Text>
      ) : (
        content
      )}
    </td>
  );
}

function PriceList({ onNext, isOnlyShowTravel = false }: Props) {
  const { data } = useQuery({
    queryKey: [queryKeys.STAR_RANK],
    queryFn: getStarRank,
  });

  const formatPrice = useCallback((price = 0) => {
    return `${getFormattedAmount(price)}${COMMON_STR.YEN}`;
  }, []);

  const TravelTable = useCallback(
    () => (
      <>
        <Text bold fontSize={20}>
          {MENU_STR.TRAVEL_PACK}
        </Text>
        <Text>{MENU_STR.TRAVEL_INFO}</Text>
        <table className={tableCss}>
          <thead>
            <tr className="bg-neutral-300">
              <Item
                content={TEXT_STRING.CAST_PAGE.POINT}
                className="rounded-tl-xl"
              />
              <Item content={COMMON_STR.ONE_NIGHT} className="rounded-tr-xl" />
            </tr>
          </thead>
          <tbody>
            {data?.map((item, index) => {
              const isLastItem = data?.length - 1 === index;
              return (
                <tr key={item.id}>
                  <Item
                    content={
                      <Rating
                        value={index + 1}
                        showEmptyStar={false}
                        className="justify-center"
                      />
                    }
                    className={isLastItem ? 'rounded-bl-xl' : ''}
                  />
                  <Item
                    content={formatPrice(item.tourPrice)}
                    className={isLastItem ? 'rounded-br-xl' : ''}
                  />
                </tr>
              );
            })}
          </tbody>
        </table>
        <Text center>{MENU_STR.TRAVEL_NOTE}</Text>
      </>
    ),
    [data, formatPrice]
  );

  return (
    <div className="h-full flex flex-col justify-between overflow-y-auto px-5 pt-5">
      <div className="w-full flex flex-col justify-center items-center gap-5 pb-5">
        {!isOnlyShowTravel && (
          <>
            {/* LEVERAGE */}
            <>
              <Text bold fontSize={20}>
                {MENU_STR.LEVERAGE}
              </Text>
              <Text center>{MENU_STR.LEVERAGE_DESCRIPTION}</Text>
              <table className={tableCss}>
                <thead>
                  <tr>
                    <Item
                      content={MENU_STR.ONE_GIRL}
                      className="rounded-l-xl"
                    />
                    <Item content={MENU_STR.FREE_TIME} />
                    <Item
                      content={`${formatPrice(50000)}`}
                      className="rounded-r-xl"
                    />
                  </tr>
                </thead>
              </table>
              <Text center>{MENU_STR.LEVERAGE_NOTE}</Text>
            </>
            {/* Group Dispatch */}
            <>
              <Text bold fontSize={20}>
                {MENU_STR.GROUP_DISPATCH}
              </Text>
              <Text>{MENU_STR.DISPATCH_MORE_GIRL}</Text>
              <table className={tableCss}>
                <thead>
                  <tr>
                    <Item
                      content={MENU_STR.ONE_GIRL}
                      className="rounded-l-xl"
                    />
                    <Item content={MENU_STR.ONE_SET} />
                    <Item
                      content={`${formatPrice(
                        data?.[0]?.groupPrice || AUTO_PRICE
                      )}`}
                      className="rounded-r-xl"
                    />
                  </tr>
                </thead>
              </table>
              <Text center>{MENU_STR.PRICE_NOTE}</Text>
            </>
            {/* Individual levers */}
            <>
              <Text bold fontSize={20}>
                {MENU_STR.INDIVIDUAL_LEVER}
              </Text>
              <Text>{MENU_STR.PLAY_SINGLE}</Text>
              <table className={tableCss}>
                <thead>
                  <tr className="bg-neutral-300">
                    <Item
                      content={TEXT_STRING.CAST_PAGE.POINT}
                      className="rounded-tl-xl"
                    />
                    <Item content={MENU_STR.ONE_SET} />
                    <Item
                      content={MENU_STR.NOMINATE_FEE}
                      className="rounded-tr-xl"
                    />
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <Item content={MENU_STR.LEAVE_IT} />
                    <Item content={MENU_STR.DISPATCHED_EVALUATION_FEE} />
                    <Item content={formatPrice()} />
                  </tr>
                  {data?.map((item, index) => {
                    const isLastItem = data?.length - 1 === index;
                    return (
                      <tr key={item.id}>
                        <Item
                          content={
                            <Rating
                              value={index + 1}
                              showEmptyStar={false}
                              className="justify-center"
                            />
                          }
                          className={isLastItem ? 'rounded-bl-xl' : ''}
                        />
                        <Item content={formatPrice(item.price)} />
                        <Item
                          content={formatPrice(item.nominatedPrice)}
                          className={isLastItem ? 'rounded-br-xl' : ''}
                        />
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <Text center>{MENU_STR.PRICE_TUTORIAL}</Text>
            </>
          </>
        )}
        {/* Travel pack */}
        {<TravelTable />}
      </div>
      <div className="w-full sticky left-0 bottom-0 bg-white py-5 border-t border-solid border-neutral-50">
        <Button onClick={onNext} className="w-full p-5" bold>
          {COMMON_STR.NEXT}
        </Button>
      </div>
    </div>
  );
}

export default PriceList;
