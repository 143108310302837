import { ESystemMobile } from 'types';

const userAgent = navigator.userAgent;

const detectMobile = () => {
  return /iPhone|iPad|iPod|Android|webOS|BlackBerry|Windows Phone/i.test(
    userAgent
  );
};

const detectSystemMobile = () => {
  const isIOS = /iPad|iPhone|iPod|Mac/.test(userAgent);
  const isAndroid = /Android/.test(userAgent);

  if (isIOS) {
    return ESystemMobile.IOS;
  } else if (isAndroid) {
    return ESystemMobile.ANDROID;
  } else {
    return ESystemMobile.OTHER;
  }
};

const detectPWA = () => {
  if (window.matchMedia('(display-mode: standalone)').matches) {
    return true;
  } else {
    return false;
  }
};

export { detectMobile, detectSystemMobile, detectPWA };
