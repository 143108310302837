import axios from 'axios';
import {
  TAddGame,
  TUpdateGame,
  TCreateProject,
  TGames,
  TListFemale,
  TProject,
  TProjectEstimatePrice,
  TProjectFemale,
  TProjectGame,
  TProjectPaymentBody,
  TReleaseFemaleProject,
} from 'types';

const getProjectGame = async (projectId: number): Promise<TGames[]> => {
  const res = await axios
    .get('projects/games', {
      params: {
        projectId,
      },
    })
    .then(function (response) {
      return response.data;
    });

  return res;
};

const getRecommendFemale = async (payload: {
  currentPage: number;
  pageSize: number;
  search: string;
  femaleId?: number;
  projectId?: number;
}): Promise<TListFemale> => {
  const { currentPage, pageSize, projectId, femaleId, search } = payload;
  const res = await axios
    .get('projects/recommend', {
      params: {
        currentPage,
        pageSize,
        search,
        femaleId,
        projectId,
      },
    })
    .then(function (response) {
      return response.data;
    });

  return res;
};

const createProject = async (body: TCreateProject): Promise<TProject> => {
  return await axios.post('projects', body).then((response) => {
    return response.data;
  });
};

const createProjectOrder = async (body: TCreateProject): Promise<TProject> => {
  return await axios.post('projects/order', body).then((response) => {
    return response.data;
  });
};

// Games
const addProjectGame = async (body: TAddGame) => {
  return await axios.post('projects/order/games', body).then((response) => {
    return response.data;
  });
};

const updateProjectGame = async (body: TUpdateGame) => {
  return await axios.put('projects/order/games', body).then((response) => {
    return response.data;
  });
};

const getProjectOrderGame = async (
  projectId: number
): Promise<TProjectGame[]> => {
  const res = await axios
    .get('projects/order/games', { params: { projectId } })
    .then(function (response) {
      return response.data;
    });

  return res;
};

const removeProjectGame = async (body: {
  ids: number[];
  projectId: number;
}) => {
  return await axios
    .post('projects/order/games/remove', body)
    .then((response) => {
      return response.data;
    });
};

const getProjectFemales = async (id: number): Promise<TProjectFemale[]> => {
  return await axios.get(`projects/${id}/females`).then((response) => {
    return response.data;
  });
};

const getProjectPrice = async (body: {
  projectId: number;
  femaleIds: number[];
}): Promise<TProjectEstimatePrice> => {
  return await axios.post(`projects/predict`, body).then((response) => {
    return response.data;
  });
};

const projectPayment = async (body: TProjectPaymentBody) => {
  return await axios.post(`payment`, body).then((response) => {
    return response.data;
  });
};

const projectRelease = async (body: TReleaseFemaleProject) => {
  return await axios.post(`projects/female/release`, body).then((response) => {
    return response.data;
  });
};

export {
  getProjectGame,
  getRecommendFemale,
  createProject,
  createProjectOrder,
  addProjectGame,
  updateProjectGame,
  getProjectOrderGame,
  removeProjectGame,
  getProjectFemales,
  getProjectPrice,
  projectPayment,
  projectRelease,
};
