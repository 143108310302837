import { createContext } from 'react';
import { TDraftUser, TDraftUserInput } from 'types';

type TEditProfileContext = {
  draftUser?: TDraftUser;
  setDraftUser: (draft: TDraftUserInput) => void;
  removeDraftUser: () => void;
  refetchCurrentUser: () => Promise<void>;
};

const EditProfileContext = createContext<TEditProfileContext>({
  draftUser: undefined,
  setDraftUser: (draft) => {},
  removeDraftUser: () => {},
  refetchCurrentUser: async () => {},
});

export default EditProfileContext;
