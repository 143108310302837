import Avatar from 'components/Avatar';
import Text from 'components/Text';
import { TEXT_STRING } from '../../../../constants';
import { TFemalePrice } from 'types';
import PriceItem from '../PriceItem';
import { PropsWithChildren } from 'react';
import { validateGamePlayManyTime } from 'utils';

type Props = PropsWithChildren<{ data: TFemalePrice }>;

const orderFields: { label: string; key: keyof TFemalePrice }[] = [
  {
    label: TEXT_STRING.RELEASE_GIRL.NOMINATION_FEE,
    key: 'nominatedPrice',
  },
  {
    label: TEXT_STRING.RELEASE_GIRL.GAME_DISCOUNT,
    key: 'totalDiscount',
  },
];

const OrderHistoryDetailItem = ({ data, children }: Props) => {
  return (
    <div className={`my-5 p-6 bg-neutral-25 items-center`}>
      {/* Avatar, nickname */}
      <div
        className={`flex gap-4 items-center ${
          data.inactive ? 'opacity-50' : ''
        }`}
      >
        <Avatar url={data.avatar} size="w-11" circle />
        <Text>{data.nickname}</Text>
      </div>
      <div className="py-2">
        {/* Set */}
        <PriceItem
          label={`${TEXT_STRING.RELEASE_GIRL.NUM_OF_SET}: ${data.setCount}${TEXT_STRING.COMMON.SET}`}
          price={data.total}
        />
        {/* Games */}
        {data.games.map((item) => (
          <PriceItem
            key={item.id}
            label={`${item.name}: ${
              validateGamePlayManyTime(item.type) ? item.orderCount : ''
            }${item.unit}`}
            price={item.total}
          />
        ))}
        {orderFields.map((item, index) => (
          <PriceItem
            key={index}
            label={`${item.label}:`}
            price={+data[item.key]}
          />
        ))}

        {/* Total price */}
        {children || (
          <PriceItem
            label={`${TEXT_STRING.RELEASE_GIRL.SUB_TOTAL}:`}
            price={
              data.total +
              data.totalGames +
              data.nominatedPrice -
              data.totalDiscount
            }
          />
        )}
      </div>
    </div>
  );
};

export default OrderHistoryDetailItem;
