import Chip from 'components/Chip';
import { TEXT_STRING } from '../../constants';
import { PropsWithChildren } from 'react';

type Props = PropsWithChildren<{
  color?: `text-${string}`;
  bold?: boolean;
  className?: string;
  error?: boolean;
  require?: boolean;
  textSize?: string;
}>;

const Label = ({
  color = 'text-neutral-600',
  bold,
  className = 'mb-2 leading-8',
  error,
  require,
  children,
  textSize,
}: Props) => {
  return (
    <label
      className={`flex items-center gap-4 leading-6 ${textSize} ${
        bold ? 'font-bold' : 'font-medium'
      } ${error ? 'text-error' : color} ${className}`}
    >
      {children}
      {require && (
        <Chip
          textColor="text-white"
          padding="py-0.25 px-2"
          textSize="text-[10px]"
          filled
        >
          {TEXT_STRING.COMMON.REQUIRE}
        </Chip>
      )}
    </label>
  );
};

export default Label;
