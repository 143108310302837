import { CSSProperties, PropsWithChildren } from 'react';

type ChipProps = PropsWithChildren<{
  className?: string;
  style?: CSSProperties;
  onClick?: () => void;
  filled?: boolean;
  textColor?: `text-${string}`;
  bgColor?: string;
  ringColor?: string;
  textSize?: string;
  padding?: string;
  rounded?: `rounded-${string}`;
}>;

const Chip = ({
  children,
  className = '',
  style,
  onClick,
  filled = false,
  textColor = 'text-white',
  bgColor = 'bg-primary',
  ringColor = '',
  textSize = '',
  padding = 'px-12px py-8px',
  rounded = 'rounded-[8px]',
}: ChipProps): JSX.Element => {
  return (
    <span
      onClick={onClick}
      style={style}
      className={`${className} inline-flex items-center ${rounded} ${padding} ring-inset 
            ${textColor} ${bgColor} ${textSize} ${ringColor}
            ${filled ? '' : 'ring-1'} 
      `}
    >
      {children}
    </span>
  );
};

export default Chip;
