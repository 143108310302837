import React from 'react';

type Props = React.PropsWithChildren<{
  count?: number;
  dot?: boolean;
  maxCount?: number;
  bgColor?: `bg-${string}`;
  className?: string;
  position?: string;
}>;

function Badge({
  children,
  count,
  maxCount = 9,
  dot,
  bgColor = 'bg-error',
  className,
  position = '-top-1 -right-1/3',
}: Props) {
  return (
    <div
      className={`relative inline-flex items-center text-sm font-medium text-center text-white rounded-lg ${className}`}
    >
      {children}
      {(count || dot) && (
        <div
          className={`absolute inline-flex items-center justify-center h-5 min-w-[1.25rem] px-1 text-[8px] rounded-full ${position} ${bgColor}`}
        >
          {count ? (count > maxCount ? `${maxCount}+` : count) : undefined}
        </div>
      )}
    </div>
  );
}

export default Badge;
