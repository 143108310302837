import Icon from 'components/Icon';
interface FloatButtonProps {
  content: string;
  isLoading?: boolean;
  icon?: string;
  imgSrc?: string;
  handleClick: () => void;
  className?: string;
  imgClassName?: string;
  contentClassName?: string;
  iconColor?: string;
  iconClassName?:string;
}

function FloatButton({
  content,
  isLoading,
  icon,
  imgSrc,
  handleClick,
  className,
  imgClassName,
  contentClassName,
  iconColor = 'black',
  iconClassName,
}: FloatButtonProps) {
  return (
    <button
      className={`${className} shadow-xl flex flex-col items-center rounded-full bg-gradient-to-tr from-primary-400 to-primary-500 
                z-10 fixed right-12 bottom-36 h-[4.5rem] aspect-square border-white border-2 scale-[1.35] gap-1 py-1 
                ${isLoading ? 'opacity-60' : ''} `}
      onClick={handleClick}
      disabled={isLoading}
    >
      {icon ? (
        <div className={`${iconClassName} flex w-6 h-6`}>
          <Icon icon={icon} color={iconColor} />
        </div>
      ) : null}
      {imgSrc ? <img src={imgSrc} alt="" className={imgClassName} /> : null}
      <span className={`${contentClassName} text-[9px] font-bold`}>
        {content}
      </span>
    </button>
  );
}

export default FloatButton;
