import { CSSProperties, memo } from 'react';
import { TMediaDetail } from 'types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import VideoPlayer from 'components/VideoPlayer';
import { IMAGE_ACCEPTED } from '../../../constants';

type Props = TMediaDetail & {
  className?: string;
  style?: CSSProperties;
  onClick?: () => void;
  onLoad?: () => void;
};

const MediaView = ({
  type,
  url,
  className = '',
  style,
  onClick,
  onLoad,
}: Props) => {
  return (
    <>
      {IMAGE_ACCEPTED.includes(type) ? (
        <LazyLoadImage
          alt=""
          src={url}
          className={`${className} cursor-pointer h-full w-full object-cover`}
          onClick={onClick}
          height={'100%'}
          width={'100%'}
          onLoad={onLoad}
          style={style}
          // effect="blur"
        />
      ) : (
        <VideoPlayer
          url={url}
          className={className}
          isAutoPlayOnView
          isFullscreenOnclick
          controls={false}
          onLoad={onLoad}
        />
      )}
    </>
  );
};

export default memo(MediaView);
