import Text from 'components/Text';
import { TEXT_STRING, queryKeys } from '../../constants';
import { formatDate } from 'utils';
import ReviewForm from 'components/ReviewForm';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { getPendingReviewList, sendReview } from 'api';
import { useCallback, useEffect, useState } from 'react';
import Button from 'components/Button';
import { TReview, TSendReview } from 'types';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { Loading } from 'components';
import { useUserStore } from 'store';

const STR = TEXT_STRING.REVIEW_GIRL;
const userKey = { queryKey: [queryKeys.CURRENT_USER] };

function ReviewGirl() {
  // Hooks
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { user } = useUserStore();

  // States
  const [listReview, setListReview] = useState<TReview[]>([]);

  // Queries, mutations
  const { data, isLoading } = useQuery({
    queryKey: [queryKeys.LIST_REVIEW],
    queryFn: getPendingReviewList,
    enabled: !!user?.hasPendingReview,
  });

  const { mutateAsync, isPending } = useMutation({
    mutationFn: (body: TSendReview) => {
      return sendReview(body);
    },
    onSuccess() {
      toast.success(STR.REVIEW_SUCCESS);
      queryClient.refetchQueries(userKey);
      navigate(-1);
    },
    onError(err) {
      toast.error(err.message);
    },
  });

  // Memo, callbacks
  const handleReview = useCallback(
    (id: number, data: { star?: number; review?: string }) => {
      setListReview((prev) => {
        const newListReview = prev.map((review) => {
          if (review.id === id) {
            return {
              ...review,
              point: data.star || review.point,
              review: data.review || '',
            };
          }
          return review;
        });
        return newListReview;
      });
    },
    []
  );

  const handleSendReview = useCallback(() => {
    mutateAsync({ reviews: listReview });
  }, [listReview, mutateAsync]);

  // Effects
  useEffect(() => {
    if (data) {
      setListReview(
        data.map((review) => {
          return { id: review.id, point: 0, review: '' };
        })
      );
    }
  }, [data]);

  useEffect(() => {
    if (!user?.hasPendingReview) {
      navigate(-1);
    }
  }, [navigate, user?.hasPendingReview]);

  if (isLoading || !user?.hasPendingReview) {
    return <Loading fullScreen size="md" />;
  }

  return (
    <div className="px-4 py-6 flex flex-col gap-10 fixed z-20 bg-white w-full h-full overflow-y-auto top-0 left-0">
      <Text bold fontSize={24} className="leading-10">
        {STR.QUESTIONNAIRE}
      </Text>
      <Text>{formatDate(data?.[0].createdDatetime, 'LL(dd) HH:mm~')}</Text>
      <div className="p-2 flex flex-col gap-10 w-full flex-1 overflow-y-auto">
        {data?.map((item) => {
          const review = listReview.find((review) => review.id === item.id);
          return (
            <ReviewForm
              key={item.id}
              female={item}
              star={review?.point}
              review={review?.review}
              onRating={(star) =>
                handleReview(item.id, {
                  review: review?.review,
                  star: star + 1,
                })
              }
              onComment={(review) => handleReview(item.id, { review })}
            />
          );
        })}
      </div>
      <Button
        onClick={handleSendReview}
        className="py-5"
        loading={isPending}
        disabled={isPending || listReview?.some((review) => !review.point)}
      >
        {STR.SEND}
      </Button>
    </div>
  );
}

export default ReviewGirl;
