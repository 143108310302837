import Icon from 'components/Icon';
import { IMAGE_ACCEPTED } from '../../constants';
import React, { CSSProperties, ChangeEvent, ReactElement, useRef } from 'react';
import Text from 'components/Text';

type UploadImageProps = {
  className?: string;
  style?: CSSProperties;
  onChangeFile?: (file: File) => void;
  onRemove?: () => void;
  image?: File | string;
  width?: `w-${string}`;
  readonly?: boolean;
  renders?: ReactElement;
  status?: string;
};

const UploadImage = ({
  className = '',
  style,
  onChangeFile,
  onRemove,
  image,
  width = 'w-20',
  readonly,
  renders,
  status = '',
}: UploadImageProps): JSX.Element => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileSelect = (event: ChangeEvent<HTMLInputElement>) => {
    const fileInput = event.target;
    const selectedFile = fileInput.files?.[0];

    if (fileInput) {
      fileInput.value = '';
    }

    if (selectedFile && onChangeFile) {
      onChangeFile(selectedFile);
    }
  };

  const openFileInput = () => {
    if (fileInputRef.current && !readonly && onChangeFile) {
      fileInputRef.current.click();
    }
  };

  return (
    <>
      <input
        type="file"
        ref={fileInputRef}
        id="hiddenFileInput"
        className="hidden"
        onChange={handleFileSelect}
        accept={IMAGE_ACCEPTED.join(', ')}
      />
      {
        <div onClick={openFileInput} className={`${className}`}>
          {renders || (
            <div
              style={style}
              className={` flex justify-center w-full items-center ${
                image ? '' : 'border-2'
              } border-dashed border-primary rounded-3xl ${width} cursor-pointer aspect-square`}
            >
              {image ? (
                <div className="relative aspect-square w-full">
                  {status && (
                    <div className="absolute bg-black/50 w-full h-full flex justify-center items-center">
                      <Text textColor="text-white">{status}</Text>
                    </div>
                  )}
                  {onRemove && (
                    <div
                      className={
                        'absolute flex justify-center items-center w-18px h-18px rounded-[4px] border bg-primary-50 border-primary top-6px right-6px'
                      }
                      onClick={(e) => {
                        e.stopPropagation();
                        onRemove();
                      }}
                    >
                      <Icon icon={'minus'} />
                    </div>
                  )}
                  <img
                    src={
                      typeof image === 'string'
                        ? image
                        : URL.createObjectURL(image)
                    }
                    alt=""
                    className="w-full h-full object-cover object-top"
                  />
                </div>
              ) : (
                <div className="scale-150">
                  <Icon icon={'plus'} color="#F69C0F" />
                </div>
              )}
            </div>
          )}
        </div>
      }
    </>
  );
};

export default UploadImage;
