import { Header, Rating, Text } from 'components';
import { TEXT_STRING, LIMIT_VALUE, ONE_MAN_JPY } from '../../../constants';
import { useUserStore } from 'store';

const rankStr = TEXT_STRING.PROFILE_RANK;
const commonStr = TEXT_STRING.COMMON;

const replaceAmount = (amount: number) =>
  rankStr.OVER_ONE_MAN.replace('$amount', `${amount}`);

const displayUtilizationAmount = (price = 0) => {
  if (price === 0) return 0 + commonStr.YEN;
  const convertedAmount = price / ONE_MAN_JPY;
  let result = '';

  if (convertedAmount < LIMIT_VALUE.MIN_PRICE_ONE_STAR)
    return rankStr.LESS_ONE_MAN.replace(
      '$amount',
      `${LIMIT_VALUE.MIN_PRICE_ONE_STAR}`
    );

  const amounts = [
    LIMIT_VALUE.MIN_PRICE_ONE_STAR,
    LIMIT_VALUE.MIN_PRICE_TWO_STAR,
    LIMIT_VALUE.MIN_PRICE_THREE_STAR,
    LIMIT_VALUE.MIN_PRICE_FOUR_STAR,
    LIMIT_VALUE.MIN_PRICE_FIVE_STAR,
  ];

  amounts.forEach((amount) => {
    if (convertedAmount >= amount) {
      result = replaceAmount(amount);
    }
  });

  return result;
};

function StarDetail() {
  const { user } = useUserStore();

  return (
    <div className="h-full">
      <Header title={rankStr.GUEST_RANK} />
      <div className="px-8 py-[10%] pb-[56px] flex flex-col gap-8 h-full overflow-y-auto">
        <div className="border border-solid border-primary rounded-2xl bg-primary-50 py-[10%]">
          <Rating
            value={user?.total?.ranking}
            isBlingStar
            className="justify-center gap-4"
          />
        </div>
        <div className="flex flex-col items-center">
          <Text bold fontSize={20}>
            {rankStr.USAGE_30_DAY}
          </Text>
          <Text bold fontSize={20}>
            {rankStr.USAGE_AMOUNT.replace(
              '$amount',
              displayUtilizationAmount(user?.total?.invoiceIn30Days)
            )}
          </Text>
          <Text bold fontSize={20}>
            {rankStr.USAGE_COUNT.replace(
              '$count',
              `${user?.total?.orderIn30Days}`
            )}
          </Text>
        </div>
        <div className="flex flex-col gap-4">
          <Text bold fontSize={20}>
            {rankStr.GUEST_RANK_CONDITIONS}
          </Text>
          <Text fontSize={13}>{rankStr.RANK_STAR_CONDITIONS}</Text>
          <Text fontSize={13}>{rankStr.RANK_NOTE}</Text>
        </div>
      </div>
    </div>
  );
}

export default StarDetail;
