import { routes } from '../constants';
import { initialValueCallWoman } from 'helpers';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useOrderStore } from 'store';
import { EOrderStep } from 'types';

function useOrder() {
  const { setOrderFormState, setSelectedGirlId, setOrderStep } =
    useOrderStore();
  const navigate = useNavigate();

  const gotoCall = useCallback(
    async (id?: number) => {
      setOrderFormState(initialValueCallWoman);
      setSelectedGirlId(id);
      setOrderStep(EOrderStep.CALL_WOMAN);
      navigate(routes.CALL);
    },
    [navigate, setOrderFormState, setOrderStep, setSelectedGirlId]
  );

  return { gotoCall };
}

export default useOrder;
