import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';
import {
  createProject,
  getProjectInformation,
  getRecommendFemale,
} from '../../api';
import {
  AUTO_PRICE,
  PAGE_SIZE,
  TEXT_STRING,
  queryKeys,
  routes,
  storageKeys,
} from '../../constants';
import { useOrderStore, useSettingStore } from '../../store';
import SummaryOrder from '../../components/OrderScreen/SummaryOrder';
import CallWoman from '../../components/OrderScreen/CallWoman';
import OrderDetail from '../../components/OrderScreen/OrderDetail';
import { EOrderStep, EProjectType, TCreateProject, TFemale } from '../../types';
import { useAuth } from '../../hooks';
import { Header, PriceList, Text } from '../../components';
import moment from 'moment';
import { FIELD_NAME, initialValueCallWoman } from '../../helpers';
import { useNavigate } from 'react-router-dom';
import ModalSuccess from '../../components/ModalSuccess';
import { toast } from 'react-toastify';
import { detectPWA, getFormattedAmount } from 'utils';

const titleModal = {
  [EOrderStep.ORDER_METHOD]: TEXT_STRING.COMMON.MENU,
  [EOrderStep.CALL_WOMAN]: TEXT_STRING.SUMMARY_ORDER.ORDER,
  [EOrderStep.ORDER_DETAIL]: TEXT_STRING.ORDER_DETAIL.RECRUITMENT_DETAIL,
  [EOrderStep.SUMMARY_ORDER]: TEXT_STRING.SUMMARY_ORDER.CONFIRM_ORDER,
};

function Call() {
  // State
  const [loading, setLoading] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');

  // Hooks
  const {
    setOrderStep,
    orderStep,
    setSelectedGirlId,
    selectedGirlId,
    orderFormState: formState,
    setOrderFormState: setFormState,
    listSelectedGirl,
    setListSelectedGirl,
  } = useOrderStore();
  const navigate = useNavigate();
  const { checkNeedToLogin } = useAuth();
  const { setOpenTutorial } = useSettingStore();

  // Queries, mutations
  const { data } = useQuery({
    queryKey: [queryKeys.PROJECT_INFO],
    queryFn: getProjectInformation,
  });

  const { mutateAsync: createOrderMutation, data: project } = useMutation({
    mutationFn: (body: TCreateProject) => {
      return createProject(body);
    },
    onError: (err) => {
      toast.error(err.message);
    },
  });

  const {
    data: listRecommendFemale,
    fetchNextPage,
    hasNextPage,
    isPending,
  } = useInfiniteQuery({
    queryKey: [queryKeys.RECOMMEND_FEMALE, selectedGirlId, searchValue],
    queryFn: async ({ pageParam }) =>
      getRecommendFemale({
        currentPage: pageParam,
        pageSize: PAGE_SIZE,
        femaleId: selectedGirlId,
        search: searchValue,
      }),
    initialPageParam: 1,
    getNextPageParam: (lastPage, _, currentPage) => {
      return lastPage?.totalPage > currentPage ? currentPage + 1 : undefined;
    },
    enabled: !!searchValue || !!selectedGirlId,
  });

  // Memo, callbacks
  const handleChooseGirl = useCallback(
    (value: TFemale) => {
      setListSelectedGirl((prev) => {
        if (prev.some((girl) => girl.userId === value.userId)) {
          return prev.filter((item) => item.id !== value.id);
        } else {
          return [value, ...prev];
        }
      });
    },
    [setListSelectedGirl]
  );

  const listView = useMemo(
    () => listRecommendFemale?.pages.map(({ data }) => data).flat() || [],
    [listRecommendFemale?.pages]
  );

  const totalPriceGirl = useMemo(() => {
    const setCount = +formState[FIELD_NAME.SET];
    const peopleCount = +formState[FIELD_NAME.PEOPLE];
    const girlCount = peopleCount - listSelectedGirl.length;

    if (peopleCount === 1 && !listSelectedGirl.length) {
      return TEXT_STRING.COMMON.PRICE_SINGLE_ORDER;
    }

    const total =
      listSelectedGirl.reduce((result, current) => {
        const price =
          peopleCount > 1
            ? current.starRank?.groupPrice || AUTO_PRICE
            : current.starRank?.price;
        const nominatedPrice = current.starRank?.nominatedPrice;
        return result + price * setCount + nominatedPrice;
      }, 0) +
      girlCount * AUTO_PRICE * setCount;

    return getFormattedAmount(total);
  }, [formState, listSelectedGirl]);

  const clearState = useCallback(() => {
    setSelectedGirlId();
    setFormState(initialValueCallWoman);
    setListSelectedGirl([]);
  }, [setFormState, setListSelectedGirl, setSelectedGirlId]);

  const handleOrderGirl = useCallback(async () => {
    const combineDateTime = () => {
      const date = moment(formState[FIELD_NAME.DATE]);
      const time = moment(formState[FIELD_NAME.TIME], 'HH:mm');
      const dateTime = date.set({
        hour: time.hour(),
        minute: time.minute(),
      });

      return dateTime.toISOString();
    };

    const data = {
      meetingAddress: `${formState[FIELD_NAME.MEETING_ADDRESS]}`.trim(),
      note: `${formState[FIELD_NAME.NOTE]}`.trim(),
      setCount: +formState[FIELD_NAME.SET],
      peopleCount: +formState[FIELD_NAME.PEOPLE],
      startDate: combineDateTime(),
      maleCount: +formState[FIELD_NAME.MALE_COUNT],
      ProjectCandidate: listSelectedGirl.map((girl) => girl.userId),
      estimatePrice: totalPriceGirl,
      type: EProjectType.BASIC,
    };
    const areaId = +formState[FIELD_NAME.AREA_ID];
    try {
      setLoading(true);
      await createOrderMutation(!!areaId ? { ...data, areaId } : data);
      setOrderStep(EOrderStep.ORDER_METHOD);
      setOpenModal(true);
      clearState();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [
    clearState,
    createOrderMutation,
    formState,
    listSelectedGirl,
    setOrderStep,
    totalPriceGirl,
  ]);

  // Effect
  useEffect(() => {
    if (
      selectedGirlId &&
      listView[0]?.userId === selectedGirlId &&
      !searchValue
    ) {
      setListSelectedGirl((prev) => {
        const isNominatedGirl = prev.some(
          (girl) => girl.userId === listView[0]?.userId
        );
        if (isNominatedGirl) return prev;
        return [listView[0]];
      });
    }
  }, [
    data?.optionsPeople?.length,
    formState,
    listView,
    searchValue,
    selectedGirlId,
    setFormState,
    setListSelectedGirl,
  ]);

  return (
    <>
      {orderStep === EOrderStep.ORDER_METHOD && (
        <div className="h-full flex flex-col">
          <Header title={titleModal[orderStep]} />
          <PriceList onNext={() => setOrderStep(EOrderStep.CALL_WOMAN)} />
        </div>
      )}
      {orderStep === EOrderStep.CALL_WOMAN && (
        <div className="h-full flex flex-col">
          <Header
            title={titleModal[orderStep]}
            onBack={() => setOrderStep(orderStep - 1)}
          />
          <div className="h-full p-5 flex flex-col justify-between gap-4 overflow-y-auto ">
            <CallWoman
              projectInfo={data}
              formState={formState}
              setFormState={setFormState}
              listGirl={listSelectedGirl}
              onChooseGirl={(value) => handleChooseGirl(value)}
              setListGirl={setListSelectedGirl}
              clearListGirl={() => setListSelectedGirl([])}
              estimatePrice={totalPriceGirl}
              onOrder={() => setOrderStep(EOrderStep.ORDER_DETAIL)}
              fetchMore={fetchNextPage}
              hasMore={hasNextPage}
              listView={listView}
              searchFemale={searchValue}
              setSearchFemale={setSearchValue}
              loadingFemale={isPending}
            />
          </div>
        </div>
      )}
      {orderStep === EOrderStep.ORDER_DETAIL && (
        <div className="h-full flex flex-col">
          <Header
            title={titleModal[orderStep]}
            onBack={() => setOrderStep(orderStep - 1)}
          />
          <OrderDetail
            projectInfo={data}
            formState={formState}
            setFormState={setFormState}
            onSubmitOrder={() => {
              if (!checkNeedToLogin(routes.SIGNUP))
                setOrderStep(EOrderStep.SUMMARY_ORDER);
            }}
          />
        </div>
      )}
      {orderStep === EOrderStep.SUMMARY_ORDER && (
        <div className="h-full flex flex-col">
          <Header
            title={titleModal[orderStep]}
            onBack={() => setOrderStep(orderStep - 1)}
          />
          <SummaryOrder
            summaryOrder={{
              ...formState,
            }}
            listGirl={listSelectedGirl}
            estimatePrice={totalPriceGirl}
            loading={loading}
            onOrderGirl={handleOrderGirl}
          />
        </div>
      )}
      <ModalSuccess
        open={openModal}
        onConfirm={() => {
          setOpenModal(false);
          navigate(
            routes.CONVERSATION.replace(':id', project?.channelId || '')
          );
          setOpenTutorial(
            !localStorage.getItem(storageKeys.IS_CONFIRM_TUTORIAL) &&
              !detectPWA()
          );
        }}
      >
        <Text className="mt-6" center textColor="text-neutral-900">
          {TEXT_STRING.SUMMARY_ORDER.ORDER_SUCCESS_MESSAGE} <br />
          {TEXT_STRING.SUMMARY_ORDER.ORDER_SUCCESS_HINT}
        </Text>
      </ModalSuccess>
    </>
  );
}

export default Call;
