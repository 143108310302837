import { TEXT_STRING } from '../../../../constants';
import { useCallback, useState } from 'react';
import { EGameType, TConfirmGame, TGames, TProjectFemale } from 'types';
import ConfirmGame from './ConfirmGame';
import SelectGame from './SelectGame';
import ListGameSelected from './ListGameSelected';
import IconButton from 'components/IconButton';
import Text from 'components/Text';
import ModalWarningGame from './ModalWarningGame';

const CHAT_STR = TEXT_STRING.CHAT;

enum EAddGameStep {
  SELECT_GAME = 1,
  CONFIRM_GAME = 2,
  LIST_SELECTED_GAME = 3,
}

const title = {
  [EAddGameStep.SELECT_GAME]: CHAT_STR.ADD_GAME_MESSAGE,
  [EAddGameStep.CONFIRM_GAME]: CHAT_STR.GAME_ORDER,
  [EAddGameStep.LIST_SELECTED_GAME]: CHAT_STR.LIST_TECO_GIRL,
};

type Props = {
  projectId: number;
  listGirlInMeeting: TProjectFemale[];
  refetchFemales: () => void;
  isSingleMeeting?: boolean;
};

function AddGame({
  projectId,
  listGirlInMeeting,
  refetchFemales,
  isSingleMeeting = false,
}: Props) {
  // State
  const [selectedGame, setSelectedGame] = useState<TConfirmGame[]>([]);
  const [disabledAllGame, setDisabledAllGame] = useState<boolean>(false);
  const [listSelectedGirl, setListSelectedGirl] = useState<TProjectFemale[]>(
    []
  );
  const [gameStep, setGameStep] = useState<EAddGameStep>(
    EAddGameStep.SELECT_GAME
  );
  const [open, setOpen] = useState<boolean>(false);

  // Memo, callbacks
  const handleSelectedGame = useCallback(
    (game: TGames) => {
      if (game.type === EGameType.COMMON_SPECIAL && !isSingleMeeting) {
        return setOpen(true);
      }
      setSelectedGame((prev) => {
        const isSelectedGame = prev.some((item) => item.id === game.id);
        if (game.type === EGameType.GROUP_KING) {
          setDisabledAllGame(!isSelectedGame);
          return isSelectedGame ? [] : [{ ...game, quantity: 1 }];
        } else {
          return isSelectedGame
            ? prev.filter((g) => g.id !== game.id)
            : [...prev, { ...game, quantity: 1 }];
        }
      });
    },
    [isSingleMeeting]
  );

  const handleSelectedGirl = useCallback((female: TProjectFemale) => {
    setListSelectedGirl((prev) =>
      prev?.some((item) => item.id === female.id)
        ? prev.filter((item) => item.id !== female.id)
        : [...prev, female]
    );
  }, []);

  const handleUpdateQuantity = useCallback((id: number, quantity: number) => {
    setSelectedGame((prev) => {
      const updatedItems = prev.map((item) => {
        return item.id === id
          ? { ...item, quantity: (item?.quantity || 0) + quantity }
          : item;
      });

      return updatedItems;
    });
  }, []);

  return (
    <div>
      <div className="absolute top-5 left-0 pl-16px w-full flex justify-between items-center">
        <IconButton
          icon="chevronLeft"
          transparent
          onClick={() => setGameStep(EAddGameStep.SELECT_GAME)}
          className={gameStep > 1 ? 'visible' : 'invisible'}
        />
        <Text bold center className="mt-1">
          {title[gameStep]}
        </Text>
        <div className="w-8"></div>
      </div>
      {gameStep === EAddGameStep.SELECT_GAME && (
        <SelectGame
          projectId={projectId}
          listGirlInMeeting={listGirlInMeeting}
          onContinue={() => {
            setGameStep(EAddGameStep.CONFIRM_GAME);
            setListSelectedGirl([]);
          }}
          onSelectGame={handleSelectedGame}
          onGoToListGame={() => setGameStep(EAddGameStep.LIST_SELECTED_GAME)}
          selectedGame={selectedGame}
          disabledAllGame={disabledAllGame}
        />
      )}
      {gameStep === EAddGameStep.CONFIRM_GAME && (
        <ConfirmGame
          projectId={projectId}
          females={listGirlInMeeting}
          selectedGame={selectedGame}
          listSelectedGirl={listSelectedGirl}
          onSelectGirl={handleSelectedGirl}
          onUpdateQuantity={handleUpdateQuantity}
          refetchFemales={refetchFemales}
        />
      )}
      {gameStep === EAddGameStep.LIST_SELECTED_GAME && (
        <ListGameSelected
          projectId={projectId}
          refetchFemales={refetchFemales}
        />
      )}
      <ModalWarningGame onConfirm={() => setOpen(false)} open={open} />
    </div>
  );
}

export default AddGame;
