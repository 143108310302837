import React, { ReactElement } from 'react';
import { TPhoneInput } from 'types';
import 'react-phone-number-input/style.css';
import Input from 'react-phone-number-input/input';
import { Country, Value as E164Number } from 'react-phone-number-input';
import Label from 'components/Label';

type Props = {
  className?: string;
  inputClass?: string;
  name: string;
  value: TPhoneInput;
  type?: string;
  autoComplete?: string;
  label?: string;
  placeholder?: string;
  bold?: boolean;
  prefix?: ReactElement;
  suffix?: ReactElement;
  disabled?: boolean;
  maxLength?: number;
  textSize?: string;
  onChange: (value: TPhoneInput) => void;
  onBlur?: () => {};
  onFocus?: () => {};
  error?: boolean;
  require?: boolean;
};

const countryCode = (process.env.REACT_APP_COUNTRY_CODE || 'JP') as Country;

function PhoneInput({
  className = '',
  inputClass = '',
  name,
  value,
  label,
  placeholder,
  bold,
  disabled = false,
  textSize = '',
  onChange,
  onBlur,
  onFocus,
  error,
  require,
}: Props) {
  const onChangeNumber = React.useCallback(
    (number: E164Number) => {
      onChange({ ...value, number });
    },
    [value, onChange]
  );

  return (
    <div className="w-full">
      {label && (
        <Label bold={bold} require={require} error={error}>
          {label}
        </Label>
      )}
      <div
        className={`${className} flex items-center rounded-lg ring-1 ${
          error
            ? `ring-error`
            : `ring-neutral-300 focus-within:ring-2 focus-within:ring-primary`
        }`}
      >
        <Input
          country={countryCode}
          value={value.number}
          onChange={onChangeNumber}
          onBlur={onBlur}
          onFocus={onFocus}
          countries={[countryCode]}
          international
          placeholder={placeholder}
          disabled={disabled}
          className={`block flex-1 border-0 bg-transparent placeholder:text-neutral-300 focus:ring-0 py-10px px-8px ${inputClass}`}
        />
      </div>
    </div>
  );
}

export default PhoneInput;
