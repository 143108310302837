import Avatar from 'components/Avatar';
import Text from 'components/Text';
import { TEXT_STRING } from '../../../constants';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useNavigate } from 'react-router-dom';
import { TMember, TMessageDetail } from 'types';
import moment from 'moment';
import { useMemo } from 'react';
import Markdown from 'markdown-to-jsx';

type Props = {
  message: TMessageDetail;
  isMyMessage?: boolean;
  showAvatar?: boolean;
  user?: TMember;
  onClickAvatar?: () => void;
};

const SYSTEM_CHAT_UUID = process.env.REACT_APP_SYSTEM_CHAT_UUID;
const paymentStr = TEXT_STRING.PAYMENT;
const commonStr = TEXT_STRING.COMMON;

const MessageItem = ({
  message,
  isMyMessage,
  showAvatar,
  user,
  onClickAvatar,
}: Props) => {
  const navigate = useNavigate();

  const text = useMemo(
    () => message.message?.message?.text || message.message?.text || '',
    [message]
  );

  const messageData = useMemo(
    () => (message.message?.message || message.message)?.data,
    [message]
  );

  const isAutoMessage = useMemo(
    () => SYSTEM_CHAT_UUID === message.uuid,
    [message.uuid]
  );

  const messageTime = useMemo(
    () => moment(+message.timetoken / 10000).format('HH:mm'),
    [message.timetoken]
  );

  const paymentId = useMemo(
    () => messageData?.paymentId || messageData?.prepaidId,
    [messageData?.paymentId, messageData?.prepaidId]
  );

  const isRegister = useMemo(
    () => messageData?.isRegister,
    [messageData?.isRegister]
  );

  return (
    <div
      className={`flex ${
        isMyMessage ? 'items-end justify-end' : 'items-start'
      } break-all`}
    >
      {!isMyMessage && (
        <div className={`w-12 ${user?.inactive && user?.id ? 'opacity-50' : ''}`}>
          {showAvatar && (
            <Avatar
              circle
              url={isAutoMessage ? '/images/logo192.png' : user?.avatar}
              onClick={!user?.inactive ? onClickAvatar : undefined}
            />
          )}
        </div>
      )}

      {isMyMessage && (
        <Text fontSize={10} className="mr-3 mt-auto">
          {messageTime}
        </Text>
      )}

      <div
        className={`${
          isMyMessage
            ? 'bg-primary-200'
            : isAutoMessage
            ? 'bg-neutral-50 ml-3'
            : 'bg-transparent ml-3'
        } rounded-xl border border-neutral-200 max-w-[70%]`}
      >
        {text && (
          <div className="px-3 py-2 text-neutral-600 whitespace-pre-line">
            <Markdown
              options={{
                wrapper: 'p',
                forceInline: true,
                overrides: {
                  a: {
                    component: (props) => (
                      <a
                        href={props.href}
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        {props.children}
                      </a>
                    ),
                  },
                },
              }}
            >
              {text}
            </Markdown>
            {paymentId && (
              <Text
                onClick={() => paymentId && navigate(`/payment/${paymentId}`)}
                className={'cursor-pointer text-sky-400 underline text-[20px] my-2'}
              >
                {messageData?.prepaidId
                  ? paymentStr.TO_PAYMENT
                  : paymentStr.CHOOSE_METHOD}
              </Text>
            )}

            {isRegister && (
              <Text
                onClick={() => isRegister && navigate(`/signup`)}
                className={'cursor-pointer text-sky-400 underline text-[20px] my-2'}
              >
                {commonStr.NAVIGATE_TO_REGISTER_SCREEN}
              </Text>
            )}
          </div>
        )}

        {message.url && (
          <LazyLoadImage
            className={`rounded-b-xl${text ? '' : ' rounded-t-xl'}`}
            src={message.url}
            alt=""
          />
        )}
      </div>

      {!isMyMessage && (
        <Text fontSize={10} className="ml-3 mt-auto">
          {messageTime}
        </Text>
      )}
    </div>
  );
};

export default MessageItem;
