import { IMAGE_ACCEPTED, VIDEO_ACCEPTED } from '../constants';

const getFileSize = (file: File) => {
  return new Promise<{
    originalHeight?: number;
    originalWidth?: number;
  }>((resolve) => {
    if (IMAGE_ACCEPTED.includes(file.type) || file.type.startsWith('image/')) {
      const image = new Image();
      image.src = URL.createObjectURL(file);
      image.onload = () => {
        resolve({
          originalHeight: image.naturalHeight,
          originalWidth: image.naturalWidth,
        });
      };
      image.onerror = () => resolve({});
    } else if (
      VIDEO_ACCEPTED.includes(file.type) ||
      file.type.startsWith('video/')
    ) {
      const video = document.createElement('video');
      video.src = URL.createObjectURL(file);
      video.onloadedmetadata = () => {
        resolve({
          originalHeight: video.videoHeight,
          originalWidth: video.videoWidth,
        });
      };
      video.onerror = () => resolve({});
    } else {
      resolve({});
    }
  });
};

export default getFileSize;
