import { useCallback, useEffect, useRef, useState } from 'react';
import { Waypoint } from 'react-waypoint';
import ReactPlayer from 'react-player/lazy';
import screenfull from 'screenfull';

type Props = {
  className?: string;
  url: string;
  isFullscreenOnclick?: boolean;
  isAutoPlayOnView?: boolean;
  controls?: boolean;
  autoPlay?: boolean;
  loop?: boolean;
  onLoad?: () => void;
};

function VideoPlayer({
  className,
  url,
  isFullscreenOnclick = false,
  isAutoPlayOnView = false,
  controls = true,
  autoPlay = true,
  loop = true,
  onLoad,
}: Props) {
  const playerRef = useRef<any>(null);
  const videoRef = useRef<any>(null);

  const [isPlaying, setIsPlaying] = useState<boolean>(false);

  const onPlayFullscreen = () => {
    try {
      const player = playerRef.current?.player?.player;
      if (!player) return;

      setIsPlaying(true);
      player.unmute();

      if (videoRef.current.webkitEnterFullscreen) {
        videoRef.current.webkitEnterFullscreen();
      } else if (videoRef.current.requestFullscreen) {
        videoRef.current.requestFullscreen();
      }
    } catch (error) {}
  };

  const handleFullscreenEvent = useCallback(() => {
    const player = playerRef.current.player?.player;
    if (player) {
      if (screenfull.isFullscreen) {
        player.unmute();
      } else player.mute();
    }
  }, []);

  const onEnter = useCallback(() => {
    if (isAutoPlayOnView) {
      setIsPlaying(true);
    }
  }, [isAutoPlayOnView, setIsPlaying]);

  const onLeave = useCallback(() => {
    setIsPlaying(false);
    playerRef.current.player?.player.mute();
  }, [setIsPlaying]);

  const handleOnReady = useCallback(() => {
    if (videoRef.current) return;
    onLoad && onLoad();
    videoRef.current = playerRef.current.player?.player?.player;
    videoRef.current?.addEventListener(
      'fullscreenchange',
      handleFullscreenEvent
    );
  }, [handleFullscreenEvent, onLoad]);

  useEffect(() => {
    return () => {
      videoRef.current?.removeEventListener(
        'fullscreenchange',
        handleFullscreenEvent
      );
    };
  }, [handleFullscreenEvent]);

  return (
    <div className="relative">
      {isFullscreenOnclick && (
        <div
          className="absolute w-full h-full bg-transparent z-[1]"
          onClick={onPlayFullscreen}
        ></div>
      )}
      <Waypoint onEnter={onEnter} onLeave={onLeave}>
        <div>
          <ReactPlayer
            ref={playerRef}
            playing={isPlaying}
            loop={loop}
            controls={controls}
            muted
            autoPlay={autoPlay && !isAutoPlayOnView}
            playsinline
            config={{
              file: { attributes: { controlsList: 'nodownload', className } },
            }}
            url={url}
            width={'100%'}
            height={'auto'}
            onReady={handleOnReady}
            onPause={() => {
              setIsPlaying(false);
            }}
            onPlay={() => {
              setIsPlaying(true);
            }}
          />
        </div>
      </Waypoint>
    </div>
  );
}

export default VideoPlayer;
