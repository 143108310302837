import Text from 'components/Text';
import { formatAmountNumber } from 'utils';

type Props = { label: string; price: number };

const PriceItem = ({ label, price }: Props) => {
  return !!price ? (
    <div className="flex justify-between border-b pt-4 pb-1">
      <Text>{label}</Text>
      <Text bold>{formatAmountNumber(price)}</Text>
    </div>
  ) : null;
};

export default PriceItem;
