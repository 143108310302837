import React, { ReactElement } from 'react';
import Text from 'components/Text';
import { TEXT_STRING } from '../../constants';

type TableColumn = {
  key: string;
  title: string | ReactElement;
  align?: TAlignTable;
};

type TAlignTable = 'center' | 'left' | 'right' | 'justify' | 'char' | undefined;

type TableData = Record<string, string | number | JSX.Element>;

type Props = {
  columns: TableColumn[];
  data: TableData[];
  loading?: boolean;
  cols?: string[];
  alignBody?: TAlignTable;
  size?: number;
};

function Table({
  columns,
  data,
  loading = false,
  cols,
  alignBody = 'left',
  size = 15,
}: Props) {
  return (
    <div className="h-full relative flex flex-col justify-between min-w-0 break-words bg-white w-full">
      {loading ? (
        <table className="items-center bg-transparent w-full border-collapse">
          <thead>
            <tr className="bg-gray-50 text-gray-600 border-b border-solid border-gray-200">
              {Array.from(Array(columns.length).keys()).map((index) => (
                <th
                  key={index}
                  className="animate-pulse px-6 align-middle border border-solid border-blueGray-100 py-3 text-sm uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                >
                  <div className="h-8 bg-gray-300 rounded"></div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {Array.from(Array(size).keys()).map((rowIndex) => (
              <tr
                key={rowIndex}
                className="border-b border-solid border-gray-200"
              >
                {Array.from(Array(columns.length).keys()).map((colIndex) => (
                  <td
                    key={colIndex}
                    className={`border-t-0 px-6 align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4`}
                  >
                    <div className="h-8 bg-gray-300 rounded animate-pulse"></div>
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      ) : !data.length ? (
        <Text center bold className="py-8">
          {TEXT_STRING.COMMON.NO_DATA}
        </Text>
      ) : (
        <table className="items-center bg-transparent w-full border-collapse">
          {cols && (
            <colgroup>
              {cols.map((col, index) => (
                <col key={index} className={col} />
              ))}
            </colgroup>
          )}
          <thead>
            <tr className="text-neutral-600 border-b border-solid border-gray-200">
              {columns?.map((column) => (
                <th
                  key={column.key}
                  className="p-5 whitespace-nowrap"
                  align={column.align || 'left'}
                >
                  {column.title}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data?.map((row, rowIndex) => (
              <tr
                key={rowIndex}
                className="border-b border-solid border-gray-200"
              >
                {columns?.map((column) => (
                  <td
                    key={column.key}
                    className={`whitespace-nowrap p-5`}
                    align={alignBody}
                  >
                    {row[column.key]}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
}

export default Table;
