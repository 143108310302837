import { Listbox, Transition } from '@headlessui/react';
import Icon from 'components/Icon';
import Label from 'components/Label';
import { TEXT_STRING } from '../../constants';
import { Fragment } from 'react';
import { TSelectOption } from 'types';

type Props = {
  className?: string;
  name: string;
  value: string | number;
  label?: string;
  disabled?: boolean;
  onChange: (value: string) => void;
  onBlur?: () => {};
  onFocus?: () => {};
  options: TSelectOption[];
  rounded?: `rounded-${string}`;
  require?: boolean;
  error?: boolean;
  placeholder?: string;
  bold?: boolean;
  textSize?: string;
};

type ClassNames =
  | string
  | {
      [key: string]: boolean | undefined | null;
    }
  | undefined
  | null
  | false;

function classNames(...classes: ClassNames[]) {
  return classes.filter(Boolean).join(' ');
}

function Select({
  className = '',
  name,
  value,
  label,
  disabled = false,
  onChange,
  onBlur,
  onFocus,
  options,
  rounded = 'rounded-lg',
  require = false,
  error = false,
  bold = false,
  placeholder = TEXT_STRING.COMMON.SELECT,
  textSize,
}: Props) {
  return (
    <Listbox value={value} onChange={onChange}>
      {({ open }) => (
        <div>
          {label && (
            <Label
              bold={bold}
              textSize={textSize}
              require={require}
              error={error}
            >
              {label}
            </Label>
          )}
          <div className="relative">
            <Listbox.Button
              name={name}
              onBlur={onBlur}
              onFocus={onFocus}
              className={`${className} ${rounded} ${
                disabled ? 'bg-neutral-50 pointer-events-none' : 'bg-white'
              } py-10px relative w-full cursor-default pl-3 pr-10 text-left ring-1 ring-neutral-300 focus:outline-none focus:ring-2 focus:ring-primary sm:leading-6`}
            >
              <span className="block truncate w-full">
                {options.find((option) => option.value === value)?.name || (
                  <span className="text-neutral-300">{placeholder}</span>
                )}
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-2 ml-3 mb-1 flex items-center pr-2 -rotate-90 scale-75">
                <Icon icon={'chevronLeft'} />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-xs shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                {options.map((option, index) => (
                  <Listbox.Option
                    key={index}
                    disabled={option.disabled}
                    className={({ active, disabled }) =>
                      classNames(
                        disabled
                          ? 'bg-neutral-100'
                          : active
                          ? 'bg-primary-400 text-white'
                          : 'text-gray-900',
                        'relative cursor-default select-none py-2 pl-3'
                      )
                    }
                    value={option.value}
                  >
                    {({ selected, active }) => (
                      <>
                        <div className="flex items-center">
                          <span
                            className={classNames(
                              selected ? 'font-semibold' : 'font-normal',
                              'block truncate'
                            )}
                          >
                            {option.name}
                          </span>
                        </div>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? 'text-white' : 'text-primary-400',
                              'absolute inset-y-0 right-0 flex items-center'
                            )}
                          ></span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </div>
      )}
    </Listbox>
  );
}

export default Select;
