import { DAY_IN_WEEK } from '../constants';
import moment from 'moment';
import { TSchedule } from 'types';

type Props = {
  currentDate?: moment.Moment;
  format?: string;
  range?: number;
};

const getCurrentWeeks = ({
  currentDate = moment(),
  format = 'MM/DD\n (dd)',
  range = DAY_IN_WEEK,
}: Props) => {
  const weekStart = currentDate.clone().subtract(11, 'hours');

  const days: TSchedule[] = [];

  for (let i = 0; i < range; i++) {
    let weekDay = moment(weekStart).startOf('days').add(i, 'days');
    days.push({
      startDate: weekDay.toISOString(),
      startDateFormat: weekDay.format(format),
      startTime: '',
      endTime: '',
      disabled: false,
      id: 0,
    });
  }

  return days;
};

export default getCurrentWeeks;
