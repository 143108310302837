import React from 'react';
import { PAGE_SIZE, TEXT_STRING, queryKeys, routes } from '../../constants';
import { useNavigate } from 'react-router-dom';
import { useInfiniteQuery, useMutation } from '@tanstack/react-query';
import {
  createManagementClientChannel,
  getChannelList,
  getClientChannelList,
} from 'api';
import { Header, Icon, PageWrapper, Text, TextField } from 'components';
import { TTextFieldChangeEvent } from 'types';
import ChannelList from 'components/ChannelList';
import { useUserStore } from 'store';
import { toast } from 'react-toastify';

const MSG_STRs = TEXT_STRING.MESSAGE;
let timeout: NodeJS.Timeout;

function Message() {
  const navigate = useNavigate();
  const { isAuthenticated, clientId, setMgmChannelId } = useUserStore();

  // State
  const [keyword, setKeyword] = React.useState<string>('');
  const [searchText, setSearchText] = React.useState<string>('');

  // Fetch api
  const { data, isLoading, fetchNextPage, hasNextPage, refetch } =
    useInfiniteQuery({
      queryKey: [queryKeys.CHANNEL_LIST, searchText],
      queryFn: async ({ pageParam }) =>
        getChannelList(pageParam, PAGE_SIZE, searchText),
      initialPageParam: 1,
      getNextPageParam: (lastPage, allPages, lastPageParam, allPageParams) => {
        return lastPage?.totalPage > lastPageParam
          ? lastPageParam + 1
          : undefined;
      },
      staleTime: 1000,
      enabled: !!isAuthenticated,
    });

  const { data: clientData } = useInfiniteQuery({
    queryKey: [queryKeys.CLIENT_CHANNEL_LIST, searchText],
    queryFn: async ({ pageParam }) =>
      getClientChannelList(pageParam, PAGE_SIZE, clientId || '', searchText),
    initialPageParam: 1,
    getNextPageParam: (lastPage, allPages, lastPageParam, allPageParams) => {
      return lastPage?.totalPage > lastPageParam
        ? lastPageParam + 1
        : undefined;
    },
    staleTime: 1000,
    enabled: !isAuthenticated && !!clientId,
  });

  // Mutation
  const { mutate: createMutate, isPending } = useMutation({
    mutationFn: (isAuto: boolean) => {
      return createManagementClientChannel(clientId || '', isAuto);
    },
    onSuccess(res) {
      if (res) {
        setMgmChannelId(res.id);
        navigate(routes.CONVERSATION.replace(':id', res.id));
      }
    },
    onError(err) {
      toast.error(err.message);
    },
    retry: 0,
  });

  const onChangeSearch = React.useCallback((e: TTextFieldChangeEvent) => {
    setKeyword(e.target.value);
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      setSearchText(e.target.value);
    }, 500);
  }, []);

  const channels = React.useMemo(
    () =>
      (isAuthenticated
        ? data?.pages.map(({ data }) => data).flat()
        : clientData?.pages.map(({ data }) => data).flat()) ?? [],
    [data, clientData, isAuthenticated]
  );

  return (
    <PageWrapper className="px-4 overflow-hidden text-sm flex flex-col">
      <Header
        title={<Text>{MSG_STRs.MESSAGE_LIST}</Text>}
        // suffix={
        //   <Badge count={100} maxCount={99}>
        //     <Icon icon="bell" />
        //   </Badge>
        // }
        onBack={() => {
          navigate('/');
        }}
      />
      <TextField
        name="search"
        onChange={onChangeSearch}
        value={keyword}
        placeholder={MSG_STRs.ENTER_NAME}
        prefix={<Icon icon="searchInput" />}
        className="my-4 mr-4 px-2"
      />
      <ChannelList
        channels={[...channels]}
        fetchMore={fetchNextPage}
        hasMore={hasNextPage}
        isLoading={isLoading || isPending}
        refetch={!!isAuthenticated ? refetch : () => {}}
        createChannelWithAdmin={createMutate}
      />
    </PageWrapper>
  );
}

export default Message;
