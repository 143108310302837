import { useMessageStore } from 'store';
import { getUnreadMessagesCount } from 'api';
import React, { useCallback } from 'react';
import { REFETCH_UNREAD_COUNT_TIMEOUT, queryKeys } from '../constants';
import { useQuery } from '@tanstack/react-query';
import { TUnreadCount } from 'types';

function useMessage() {
  const { unreadCount, setUnreadCount, updateUnreadCount, resetUnreadCount } =
    useMessageStore();
  const timeoutRef = React.useRef<NodeJS.Timeout>();

  const { data, refetch } = useQuery<{ channels: TUnreadCount }>({
    refetchOnMount: true,
    queryKey: [queryKeys.UNREAD_COUNT],
    queryFn: getUnreadMessagesCount,
  });

  React.useEffect(() => {
    if (data?.channels) {
      setUnreadCount(data?.channels);
    }
  }, [data, setUnreadCount]);

  const refetchAPI = useCallback(() => {
    if (timeoutRef.current) return;
    timeoutRef.current = setTimeout(() => {
      refetch();
      clearTimeout(timeoutRef.current);
      timeoutRef.current = undefined;
    }, REFETCH_UNREAD_COUNT_TIMEOUT);
  }, [refetch]);

  return {
    unreadCount,
    setUnreadCount,
    updateUnreadCount,
    resetUnreadCount,
    refetch: refetchAPI,
  };
}

export default useMessage;
