import Icon from 'components/Icon';
import React from 'react';

type Props = {
  onClick(event: React.MouseEvent<HTMLElement>): void;
  disabled?: boolean;
  circle?: boolean;
  outline?: boolean;
  icon: string;
  iconColor?: string;
  transparent?: boolean;
  color?: string;
  size?: string;
  className?: string;
};

const IconButton = ({
  onClick,
  disabled = false,
  circle = true,
  outline,
  icon,
  color = 'primary',
  transparent,
  size,
  iconColor,
  className: classProp,
}: Props): JSX.Element => {
  const className = React.useMemo(() => {
    let temp = `inline-flex items-center`;
    if (!transparent) {
      temp += outline
        ? ` border border-${color} hover:bg-${color}`
        : ` bg-${color}`;
    }
    if (size) {
      temp += ` ${size}`;
    }
    if (disabled) {
      temp += ` opacity-50`;
    }
    temp += ` rounded-${circle ? 'full' : 'md'}`;
    temp += ` ${classProp}`;
    return temp;
  }, [outline, circle, color, size, transparent, disabled, classProp]);

  return (
    <button
      type="button"
      className={className}
      disabled={disabled}
      onClick={onClick}
    >
      <Icon icon={icon} color={iconColor} />
    </button>
  );
};

export default IconButton;
