import React from 'react';
import Icon from 'components/Icon';
import ReactGoogleAutocomplete from 'react-google-autocomplete';
import { TPlaceResult } from 'types';
import IconButton from 'components/IconButton';

type Props = {
  onPlaceSelected: (value: TPlaceResult) => void;
  defaultValue?: string;
  className?: string;
  onRemove?: () => void;
};

const PlacesInput = ({
  onPlaceSelected,
  defaultValue,
  className,
  onRemove,
}: Props) => {
  const autoRef = React.useRef<HTMLInputElement>(null);
  return (
    <div
      className={`flex gap-4 items-center ${className}`}
      onClick={(e) => e.stopPropagation()}
    >
      <Icon icon="location"></Icon>

      <div className="relative w-full">
        <ReactGoogleAutocomplete
          ref={autoRef}
          apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
          onPlaceSelected={(place) => {
            console.log(place);
            onPlaceSelected(place);
          }}
          defaultValue={defaultValue}
          className="w-full rounded-[8px] ring-1 ring-neutral-300 focus-within:ring-2 focus-within:ring-primary block flex-1 border-0 bg-white placeholder:text-neutral-300 focus:ring-0 py-10px px-8px"
        />
        {onRemove && defaultValue && (
          <IconButton
            onClick={() => {
              onRemove();
              if (autoRef.current) autoRef.current.value = '';
            }}
            icon="close"
            className="absolute right-0 top-1/2 -translate-y-1/2 p-2"
            transparent
          />
        )}
      </div>
    </div>
  );
};

export default PlacesInput;
