import { LIMIT_VALUE, TEXT_STRING } from '../../constants';
import { Fragment, useCallback, useState } from 'react';
import Select from 'components/Select';
import DatePicker from 'components/DatePicker';
import { EFieldFormTravel, TFemale, TField, TProjectInfo } from 'types';
import moment, { Moment } from 'moment';
import Label from 'components/Label';
import SearchFemale from './SearchFemale';
import OrderFooter from './OrderFooter';
import Text from 'components/Text';
import { formatDate, getFormattedAmount } from 'utils';
import CardGirl from './CardGirl';

const STR = TEXT_STRING.CALL_WOMAN;
const COMMON_STR = TEXT_STRING.COMMON;
const ORDER_STR = TEXT_STRING.ORDER_DETAIL;

enum ETypeForm {
  SELECT_PEOPLE = 'selectPeople',
  DATE = 'date',
  SEARCH_FEMALE = 'searchFemale',
}

const fields: TField[] = [
  {
    name: EFieldFormTravel.MALE_COUNT,
    label: ORDER_STR.NUMBER_CUSTOMER,
    type: ETypeForm.SELECT_PEOPLE,
    require: true,
  },
  {
    name: EFieldFormTravel.START_DATE,
    label: COMMON_STR.START_DATE,
    type: ETypeForm.DATE,
    require: true,
  },
  {
    name: EFieldFormTravel.END_DATE,
    label: COMMON_STR.END_DATE,
    type: ETypeForm.DATE,
    require: true,
  },
  {
    name: EFieldFormTravel.FEMALE_COUNT,
    label: TEXT_STRING.CALL_WOMAN.OPTION_PEOPLE,
    type: ETypeForm.SELECT_PEOPLE,
    require: true,
  },
  {
    name: EFieldFormTravel.NOMINATED_GIRL,
    label: STR.NOMINATED_GIRL,
    type: ETypeForm.SEARCH_FEMALE,
    require: true,
  },
];

type Props = {
  projectInfo: TProjectInfo | undefined;
  formState: Record<string, string>;
  setFormState: React.Dispatch<React.SetStateAction<Record<string, string>>>;
  listGirl: TFemale[];
  onChooseGirl: (value: TFemale) => void;
  clearListGirl: () => void;
  onOrder: () => void;
  loadingFemale?: boolean;
  fetchMore: () => void;
  hasMore: boolean;
  listView?: TFemale[];
  searchFemale: string;
  setSearchFemale: React.Dispatch<React.SetStateAction<string>>;
  estimatePrice?: string;
  readonly?: boolean;
  loading?: boolean;
};

const FormOrderTravel = ({
  projectInfo,
  formState,
  setFormState,
  listGirl,
  onChooseGirl,
  clearListGirl,
  onOrder,
  loadingFemale = false,
  hasMore,
  fetchMore,
  listView = [],
  searchFemale,
  setSearchFemale,
  estimatePrice = '',
  readonly = false,
  loading = false,
}: Props): JSX.Element => {
  // State
  const [errorFields, setErrorFields] = useState<string[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>('');

  // Memo & Callbacks
  const handleChange = useCallback(
    (name: string, value: string) => {
      if (value) {
        setErrorFields((prev) => prev.filter((field) => field !== name));
        setErrorMessage('');
      }
      if (+value < listGirl.length && name === EFieldFormTravel.FEMALE_COUNT) {
        clearListGirl();
      }
      setFormState((prev) => ({ ...prev, [name]: value }));
    },
    [clearListGirl, listGirl.length, setFormState]
  );

  const handleChangeDate = useCallback(
    (name: string, value: string) => {
      setFormState((prev) => {
        const date = moment(value);
        const endDate = moment(prev[EFieldFormTravel.END_DATE]);
        let newState = { ...prev, [name]: value };
        if (
          date.isSameOrAfter(endDate) &&
          name === EFieldFormTravel.START_DATE
        ) {
          newState[EFieldFormTravel.END_DATE] = date.add(1, 'day').toString();
        }
        return newState;
      });
    },
    [setFormState]
  );

  const isValidDate = useCallback((currentDate: Moment) => {
    const yesterday = moment().subtract(1, 'day');
    return currentDate.isAfter(yesterday);
  }, []);

  const isValidEndDate = useCallback((currentDate: Moment, date: string) => {
    const startDate = moment(date);
    return currentDate.isAfter(startDate);
  }, []);

  const handleSubmit = useCallback(async () => {
    if (!!errorFields.length) return;
    setErrorFields([]);
    setErrorMessage('');
    onOrder();
  }, [onOrder, errorFields]);

  const renderValue = useCallback(
    (field: TField) => {
      switch (field.type) {
        case ETypeForm.SELECT_PEOPLE:
          return (
            <Text fontSize={14}>
              {formState[field.name] + COMMON_STR.PERSON}
            </Text>
          );
        case ETypeForm.DATE:
          return (
            <Text fontSize={14}>{formatDate(formState[field.name], 'LL')}</Text>
          );
        case ETypeForm.SEARCH_FEMALE:
          return (
            <div className="flex gap-8px px-1 overflow-x-auto">
              {listGirl?.map((girl) => {
                return (
                  <CardGirl
                    key={girl.id}
                    onChoose={() => {}}
                    onDetail={() => {}}
                    data={girl}
                    disabledChoose
                  />
                );
              })}
            </div>
          );
        default:
          return <Text fontSize={14}>{formState[field.name]}</Text>;
      }
    },
    [formState, listGirl]
  );

  const renderFields = useCallback(
    (fields: TField[]) => {
      return fields.map((field, index) => {
        const hasError = errorFields.includes(field.name);
        return readonly ? (
          <div className="flex flex-col gap-2" key={index}>
            <Text bold fontSize={14}>
              {field.label}
            </Text>
            {renderValue(field)}
          </div>
        ) : (
          <Fragment key={`${field.name}-${index}`}>
            {(() => {
              switch (field.type) {
                case ETypeForm.SELECT_PEOPLE:
                  return (
                    <Select
                      label={field.label}
                      name={field.name}
                      value={formState[field.name]}
                      onChange={(value) => {
                        handleChange(field.name, value);
                      }}
                      options={projectInfo?.optionsPeople ?? []}
                      require={field.require}
                      textSize="text-sm"
                      bold
                    />
                  );
                case ETypeForm.DATE:
                  return (
                    <div>
                      <div className="flex items-center mb-8px gap-4">
                        <Label
                          error={hasError}
                          className="text-[14px] leading-8"
                          require
                          bold
                        >
                          {field.label}
                        </Label>
                        <span className="text-error font-bold">
                          {hasError && errorMessage}
                        </span>
                      </div>
                      <DatePicker
                        isValidDate={(cur) =>
                          field.name === EFieldFormTravel.END_DATE
                            ? isValidEndDate(
                                cur,
                                formState[EFieldFormTravel.START_DATE]
                              )
                            : isValidDate(cur)
                        }
                        value={new Date(formState[field.name])}
                        onChange={(date) =>
                          handleChangeDate(field.name, date.toString())
                        }
                        error={hasError}
                      />
                    </div>
                  );
                case ETypeForm.SEARCH_FEMALE:
                  return (
                    <SearchFemale
                      formState={formState}
                      listGirl={listGirl}
                      onChooseGirl={onChooseGirl}
                      fetchMore={fetchMore}
                      hasMore={hasMore}
                      searchFemale={searchFemale}
                      setSearchFemale={setSearchFemale}
                      listView={listView}
                      loadingFemale={loadingFemale}
                    />
                  );
                default:
                  return <></>;
              }
            })()}
          </Fragment>
        );
      });
    },
    [
      errorFields,
      errorMessage,
      fetchMore,
      formState,
      handleChange,
      handleChangeDate,
      hasMore,
      isValidDate,
      isValidEndDate,
      listGirl,
      listView,
      loadingFemale,
      onChooseGirl,
      projectInfo?.optionsPeople,
      readonly,
      renderValue,
      searchFemale,
      setSearchFemale,
    ]
  );

  return (
    <>
      <div className="flex flex-col p-5 overflow-y-auto justify-between h-full">
        <div className="flex flex-col gap-5">{renderFields(fields)}</div>
        <OrderFooter
          disabled={!!errorFields.length}
          onSubmit={handleSubmit}
          textTitle={STR.ESTIMATED_AMOUNT}
          textButton={
            readonly ? TEXT_STRING.SUMMARY_ORDER.ORDER : STR.ENTER_DETAIL
          }
          loading={loading}
          className="mt-5"
          estimatePrice={
            <Text fontSize={14}>
              {errorFields.includes(EFieldFormTravel.END_DATE)
                ? COMMON_STR.PRICE_RANGE.replace(
                    '$min',
                    getFormattedAmount(LIMIT_VALUE.MIN_TOUR_PRICE)
                  ).replace(
                    '$max',
                    getFormattedAmount(LIMIT_VALUE.MAX_TOUR_PRICE)
                  )
                : estimatePrice}
            </Text>
          }
        />
      </div>
    </>
  );
};

export default FormOrderTravel;
