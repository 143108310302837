import { PAGE_SIZE, TEXT_STRING, queryKeys } from '../../../constants';
import { useInfiniteQuery } from '@tanstack/react-query';
import {
  Loading,
  Header,
  OrderHistoryDetail,
  OrderHistoryList,
  Text,
} from 'components';
import { getOrderHistory } from 'api';
import { useCallback, useMemo, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate } from 'react-router-dom';
import { TOrderHistory } from 'types';

function OrderHistory() {
  const navigate = useNavigate();

  const [selectedOrder, setSelectedOrder] = useState<
    TOrderHistory | undefined
  >();

  const { data, isLoading, fetchNextPage, hasNextPage } = useInfiniteQuery({
    queryKey: [queryKeys.ORDER_HISTORY],
    queryFn: async ({ pageParam }) => getOrderHistory(pageParam, PAGE_SIZE),
    initialPageParam: 1,
    getNextPageParam: (lastPage, _, currentPage) => {
      return lastPage?.totalPage > currentPage ? currentPage + 1 : undefined;
    },
  });

  const onSelectOrder = useCallback(
    (card: TOrderHistory) => {
      setSelectedOrder(card);
    },
    [setSelectedOrder]
  );

  const orders = useMemo(
    () => data?.pages.map(({ data }) => data).flat() ?? [],
    [data]
  );

  return (
    <>
      {isLoading ? (
        <div className="h-full w-full flex justify-center items-center">
          <Loading />
        </div>
      ) : (
        <div className="flex flex-col h-full">
          <div className="overflow-y-auto" id="scrollFollowing">
            <Header
              onBack={() => {
                if (!selectedOrder) {
                  navigate(-1);
                } else {
                  setSelectedOrder(undefined);
                }
              }}
              title={TEXT_STRING.MY_PAGE.ORDER_HISTORY}
            />
            {!selectedOrder ? (
              <>
                {!orders?.length ? (
                  <Text bold center className="py-16">
                    {TEXT_STRING.COMMON.NO_DATA}
                  </Text>
                ) : (
                  <InfiniteScroll
                    next={() => fetchNextPage()}
                    hasMore={hasNextPage}
                    loader={
                      <div className="py-[36px] flex justify-center items-center">
                        <Loading />
                      </div>
                    }
                    dataLength={orders.length}
                    scrollThreshold={0.5}
                    scrollableTarget="scrollFollowing"
                  >
                    <OrderHistoryList
                      data={orders}
                      onSelectOrder={onSelectOrder}
                    />
                  </InfiniteScroll>
                )}
              </>
            ) : (
              <OrderHistoryDetail data={selectedOrder} />
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default OrderHistory;
