const routes = {
  SIGNUP: '/signup',
  LOGIN: '/login',
  SEARCH: '/search',
  HOME: '/home',
  CREATE_POST: '/home/post',
  CALL: '/call',
  ORDER_TRAVEL: '/call/travel',
  MESSAGE: '/message',
  CONVERSATION: '/message/:id',
  PAYMENT: '/payment/:id',
  MY_PAGE: '/my-page',
  HOW_TO_USE: '/my-page/how-to-use',
  FAQ: '/my-page/faq',
  APP_INFO: '/my-page/app-info/:id',
  DETAIL_FEMALE: '/search/detail-female/:id',
  STAR_DETAIL: '/my-page/detail-rank',
  PREVIEW_MALE: '/my-page/edit-profile/preview',
  EDIT_MALE: '/my-page/edit-profile',
  FOLLOWING: '/my-page/following',
  FEMALE_MET: '/my-page/female-met',
  PAYMENT_METHOD: '/my-page/payment',
  GAME_PAGE: '/game-page',
  ORDER_HISTORY: '/my-page/order-history',
  REVIEW_GIRL: '/review-girl',
};

export { routes };
