import React from 'react';

type Props = {
  length: number;
  code: string[];
  setCode(code: string[]): void;
  onComplete?(): void;
};

const GRID_COLS: { [x: number]: string } = {
  2: 'grid-cols-2',
  4: 'grid-cols-4',
  6: 'grid-cols-6',
};

function CodeInput({ code, setCode, length, onComplete }: Props) {
  const inputs = React.useRef<HTMLInputElement[]>([]);

  const processInput = (
    e: React.ChangeEvent<HTMLInputElement>,
    slot: number
  ) => {
    const num = e.target.value;
    if (/[^0-9]/.test(num)) return;
    const newCode = [...code];
    newCode[slot] = num;
    setCode(newCode);
    if (slot !== length - 1 && !!num) {
      inputs.current[slot + 1].focus();
    }
    if (newCode.every((num) => num !== '')) {
      onComplete && onComplete();
    }
  };

  const onKeyUp = (e: React.KeyboardEvent<HTMLInputElement>, slot: number) => {
    if (e.keyCode === 8 && !code[slot] && slot !== 0) {
      inputs.current[slot - 1].focus();
    }
  };

  return (
    <div className={`grid ${GRID_COLS[length]} gap-8px mt-16px`}>
      {code.map((num, idx) => (
        <input
          key={idx}
          type="text"
          maxLength={1}
          inputMode="numeric"
          required
          value={num}
          autoFocus={!code[0].length && idx === 0}
          onChange={(e) => processInput(e, idx)}
          onKeyUp={(e) => onKeyUp(e, idx)}
          ref={(ref: HTMLInputElement) => inputs.current.push(ref)}
          className="h-0 py-[50%] text-2xl text-center rounded-xl border border-neutral-200 focus:border-primary focus:ring-primary"
        />
      ))}
    </div>
  );
}

export default CodeInput;
