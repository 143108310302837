import { storageKeys } from '../constants';
import { TUser } from 'types';
import { create } from 'zustand';

type State = {
  isAuthenticated: boolean;
  clientId: string | null;
  user?: TUser;
  mgmChannelId?: string;
};

type Actions = {
  setIsAuthenticated: (value: boolean) => void;
  setClientId: (value: string) => void;
  setUser: (user: TUser) => void;
  removeUser: () => void;
  setMgmChannelId: (id: string) => void;
};

const useUserStore = create<State & Actions>((set) => ({
  isAuthenticated: !!localStorage.getItem(storageKeys.ACCESS_TOKEN),
  clientId: localStorage.getItem(storageKeys.CLIENT_ID),
  user: undefined,
  setIsAuthenticated: (value: boolean) =>
    set(() => ({ isAuthenticated: value })),
  setClientId: (value: string) => set(() => ({ clientId: value })),
  setUser: (user: TUser) => set(() => ({ user: user })),
  removeUser: () => set(() => ({ user: undefined })),
  setMgmChannelId: (channelId) => set(() => ({ mgmChannelId: channelId })),
}));

export default useUserStore;
