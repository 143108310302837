import { Button, QuantitySelector, Table, Text } from 'components';
import { TEXT_STRING, queryKeys } from '../../../../constants';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { getProjectOrderGame, updateProjectGame } from 'api';
import { validateGamePlayManyTime } from 'utils';
import { TProjectGame, TUpdateGame } from 'types';
import { useToast } from 'hooks';

const COMMON_STR = TEXT_STRING.COMMON;

type Props = {
  projectId?: number;
  refetchFemales: () => void;
};

enum EListGameTable {
  GIRL = 'girl',
  GAME = 'game',
}

function ListGameSelected({ projectId = 0, refetchFemales }: Props) {
  // State
  const [listGameSelected, setListGameSelected] = useState<TProjectGame[]>([]);

  // Hook
  const { toastError, toastSuccess } = useToast();

  // Queries, mutations
  const { data, isLoading, refetch, isRefetching } = useQuery({
    queryKey: [queryKeys.SELECTED_GAMES],
    queryFn: () => getProjectOrderGame(projectId),
  });

  const { mutateAsync: updateGameMutate, isPending } = useMutation({
    mutationFn: (body: TUpdateGame) => {
      return updateProjectGame(body);
    },
    onError(error) {
      toastError(error);
    },
  });

  // Memo, callbacks
  const handleUpdateGame = useCallback(async () => {
    try {
      await updateGameMutate({
        projectId,
        games: listGameSelected.flatMap((item) =>
          item.games.map((game) => {
            return {
              id: +game.projectGameId,
              orderCount: +game.orderCount,
            };
          })
        ),
      });
      refetch();
      refetchFemales();
      toastSuccess(COMMON_STR.SUCCESS);
    } catch (error) {
      console.log(error);
    }
  }, [
    listGameSelected,
    projectId,
    refetch,
    refetchFemales,
    toastSuccess,
    updateGameMutate,
  ]);

  const handleUpdateQuantity = useCallback(
    (key: number, id: number, quantity: number) => {
      setListGameSelected((prev) =>
        prev.map((listItem, index) =>
          index === key
            ? {
                ...listItem,
                games: listItem.games.map((game) =>
                  game.gameId === id
                    ? { ...game, orderCount: (game.orderCount || 0) + quantity }
                    : game
                ),
              }
            : listItem
        )
      );
    },
    []
  );

  const listTable = useMemo(
    () =>
      listGameSelected?.map((item, index) => {
        return {
          [EListGameTable.GIRL]: (
            <Text className="line-clamp-2 break-all">{item.nickname}</Text>
          ),
          [EListGameTable.GAME]: (
            <div className="flex flex-col gap-4">
              {item.games.map((game) => {
                return (
                  <div className="flex justify-between" key={game.gameId}>
                    <Text className="line-clamp-2 break-all w-full">
                      {game.name}
                    </Text>
                    <QuantitySelector
                      className="justify-end gap-2"
                      quantity={game.orderCount}
                      onDecrease={() =>
                        handleUpdateQuantity(index, game.gameId, -1)
                      }
                      onIncrease={() =>
                        handleUpdateQuantity(index, game.gameId, 1)
                      }
                      disabledIncrease={
                        (!validateGamePlayManyTime(game.gameType) &&
                          game.orderCount >= 1) ||
                        isPending
                      }
                      disabledDecrease={isPending}
                    />
                  </div>
                );
              })}
            </div>
          ),
        };
      }),
    [handleUpdateQuantity, isPending, listGameSelected]
  );

  const isDisabled = useMemo(() => {
    const listCurrent = listGameSelected.flatMap((item) =>
      item.games.map((game) => game.orderCount)
    );
    const listData = data?.flatMap((item) =>
      item.games.map((game) => game.orderCount)
    );
    return JSON.stringify(listCurrent) === JSON.stringify(listData);
  }, [data, listGameSelected]);

  // Effect
  useEffect(() => {
    setListGameSelected(data || []);
  }, [data]);

  return (
    <div className="min-h-[30vh] w-full flex flex-col justify-between">
      <Table
        columns={[
          { key: EListGameTable.GIRL, title: COMMON_STR.TECO_GIRL },
          {
            key: EListGameTable.GAME,
            title: (
              <div className="flex justify-between">
                <Text bold>{COMMON_STR.GAME}</Text>
                <Text bold>{COMMON_STR.QUANTITY}</Text>
              </div>
            ),
          },
        ]}
        data={listTable || []}
        loading={isLoading}
        size={4}
      />
      {!!listTable?.length && (
        <Button
          block
          className="mb-4 mt-8 py-5"
          onClick={handleUpdateGame}
          bold
          disabled={isDisabled || isPending || isLoading || isRefetching}
          loading={isPending || isLoading || isRefetching}
        >
          {COMMON_STR.KEEP}
        </Button>
      )}
    </div>
  );
}

export default ListGameSelected;
