import React from 'react';

type Props = React.PropsWithChildren<{
  size?: 'sm' | 'md' | 'lg';
  fullScreen?: boolean;
  center?: boolean;
}>;

const sizeMap = {
  sm: 'h-8 w-8 border-2',
  md: 'h-16 w-16 border-4',
  lg: 'h-24 w-24 border-4',
};

const Loading = ({
  size = 'sm',
  fullScreen = false,
  center,
}: Props): JSX.Element => {
  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
      className={
        fullScreen
          ? 'w-full h-screen z-50 fixed top-0 left-0 flex justify-center items-center bg-black bg-opacity-50'
          : center
          ? 'h-full w-full flex justify-center items-center'
          : 'text-center'
      }
    >
      <div
        className={`inline-block animate-spin rounded-full align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite] border-solid border-primary border-r-transparent ${sizeMap[size]}`}
        role="status"
      >
        <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
          Loading...
        </span>
      </div>
    </div>
  );
};

export default Loading;
