import { EGameType } from 'types';

const validateGamePlayManyTime = (type: string) => {
  return (
    !EGameType.GROUP_SPECIAL.includes(type) &&
    !EGameType.GROUP_KING.includes(type)
  );
};

export default validateGamePlayManyTime;
