import Text from 'components/Text';
import { Fragment, ReactElement } from 'react';

type TItem = {
  label: string | number | ReactElement;
  value?: string | number | ReactElement;
};

type Props = {
  title?: string;
  data: TItem[];
  className?: string;
};

function ProfileInfo({ title = '', data, className = '' }: Props) {
  return (
    <>
      {title && (
        <Text bold fontSize={14}>
          {title}
        </Text>
      )}
      <div className="flex flex-wrap">
        {data.map((item, index) => {
          return (
            <div key={index} className={`${className} w-full flex border-b`}>
              <div className="basis-2/5 p-4 flex items-center">
                <Text fontSize={14} textColor="text-neutral-700">
                  {item.label}
                </Text>
              </div>
              <div className="basis-3/5 p-4">
                {typeof item?.value === 'string' ? (
                  <Text fontSize={14}>{item?.value || '-'}</Text>
                ) : (
                  item?.value || '-'
                )}
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}

export default ProfileInfo;
