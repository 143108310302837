import { DATE_FORMAT } from '../constants';
import moment from 'moment';
import 'moment/locale/ja';

const formatDate = (
  date?: Date | string | number,
  format = DATE_FORMAT,
  locale = 'ja'
) => {
  if (!date || moment(date).format() === 'Invalid date') return '-';

  moment.locale(locale);
  return moment(date).local().format(format);
};
export default formatDate;
