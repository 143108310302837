import Icon from 'components/Icon';
import { MAX_STAR } from '../../constants';
import Star from '../../assets/images/star.svg';
import OutlineStar from '../../assets/images/outlineStar.svg';

type Props = {
  value?: number;
  isEdit?: boolean;
  onClick?(index: number): void;
  showEmptyStar?: boolean;
  className?: string;
  maxStar?: number;
  isBlingStar?: boolean;
  size?: `w-${string}`;
};

function Rating({
  value = 0,
  isEdit,
  onClick,
  showEmptyStar = true,
  className = '',
  maxStar = MAX_STAR,
  isBlingStar = false,
  size,
}: Props) {
  return (
    <div className={`flex gap-1 ${className}`}>
      {Array.from(Array(showEmptyStar ? maxStar : value).keys()).map(
        (index) => {
          const selectedStar = index < value;
          return (
            <div
              key={index}
              className={`${isEdit ? 'cursor-pointer' : ''}`}
              onClick={() => {
                onClick && onClick(index);
              }}
            >
              {isBlingStar ? (
                <img
                  src={selectedStar ? Star : OutlineStar}
                  alt=""
                  className={`${size}`}
                />
              ) : (
                <Icon icon="star" color={`${selectedStar ? '#F69C0F' : ''}`} />
              )}
            </div>
          );
        }
      )}
    </div>
  );
}

export default Rating;
