import Text from 'components/Text';
import {
  DEBOUNCE_TEXT,
  MIN_NUMBER_SEARCH,
  TEXT_STRING,
  routes,
} from '../../constants';
import CardGirl from './CardGirl';
import { useNavigate } from 'react-router-dom';
import { TFemale } from 'types';
import { FIELD_NAME } from 'helpers';
import InputSelect from 'components/InputSelect';
import { useCallback, useState } from 'react';

const STR = TEXT_STRING.CALL_WOMAN;

type Props = {
  formState: Record<string, string>;
  listGirl: TFemale[];
  onChooseGirl: (value: TFemale) => void;
  loadingFemale?: boolean;
  fetchMore: () => void;
  hasMore: boolean;
  listView?: TFemale[];
  isCallNewGirl?: boolean;
  searchFemale: string;
  setSearchFemale: React.Dispatch<React.SetStateAction<string>>;
};

let timeout: NodeJS.Timeout;

const SearchFemale = ({
  formState,
  listGirl,
  loadingFemale = false,
  hasMore,
  fetchMore,
  listView = [],
  setSearchFemale,
  onChooseGirl,
}: Props): JSX.Element => {
  // State
  const [searchText, setSearchText] = useState<string>('');

  // Hook
  const navigate = useNavigate();

  const handleSearch = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearchText(e.target.value);
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(async () => {
        if (e.target.value.trim().length >= MIN_NUMBER_SEARCH) {
          setSearchFemale(e.target.value.trim());
        }
      }, DEBOUNCE_TEXT);
    },
    [setSearchFemale]
  );

  return (
    <>
      <div className="w-full flex flex-col gap-8px">
        <Text bold fontSize={14}>
          {STR.INPUT_ID}
        </Text>
        <Text textColor="text-neutral" fontSize={14}>
          {STR.NOMINATE_HINT}
        </Text>
        <InputSelect
          fetchMore={fetchMore}
          hasMore={hasMore}
          searchValue={searchText}
          onSelectValue={onChooseGirl}
          data={listView}
          selectedData={listGirl}
          onSearchValue={handleSearch}
          placeholder={STR.INPUT_PLACEHOLDER}
          loading={loadingFemale}
          disabledOptions={listGirl.length >= +formState[FIELD_NAME.PEOPLE]}
        />
        <div className="flex overflow-x-auto pb-16px pt-8px">
          <div className="flex gap-8px">
            {listGirl?.map((female, index) => {
              return (
                <CardGirl
                  onDetail={() => {
                    navigate(
                      `${routes.DETAIL_FEMALE.replace(
                        ':id',
                        `${female?.userId}`
                      )}`
                    );
                  }}
                  disabledChoose={
                    listGirl?.length === +formState[FIELD_NAME.PEOPLE]
                  }
                  isMeeting={female.isMeeting}
                  data={female}
                  key={index}
                  isChoose={listGirl?.some(
                    (girl) => girl?.userId === female?.userId
                  )}
                  onChoose={() => {
                    onChooseGirl(female);
                  }}
                  icon="minus"
                />
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default SearchFemale;
