import { Loading, PostItem, Text, VirtualizedList } from 'components';
import React, { Fragment, ReactElement, useEffect } from 'react';
import { TPostDetail } from 'types';
import { CellMeasurerCache, ScrollParams } from 'react-virtualized';
import { TEXT_STRING } from '../../constants';

type Props = {
  loading?: boolean;
  hasNextPage: boolean;
  data: TPostDetail[];
  fetchNextPage: () => void;
  postScrollY?: number;
  setPostScrollY: (value?: number) => void;
  cachePost?: CellMeasurerCache;
  onScrollY?: (params?: ScrollParams) => void;
  Banner: (props: { measure?: () => void }) => ReactElement;
  onClickAvatar?: () => void;
};

function PostView({
  loading = false,
  hasNextPage,
  data,
  fetchNextPage,
  postScrollY,
  setPostScrollY,
  cachePost,
  onScrollY,
  Banner,
  onClickAvatar,
}: Props) {
  useEffect(() => {
    setPostScrollY(undefined);
  }, [setPostScrollY]);

  if (loading) {
    return <Loading fullScreen size="md" />;
  }

  return (
    <>
      {!data.length ? (
        <>
          <Banner />
          <Text center bold className={`py-20`}>
            {TEXT_STRING.COMMON.NO_DATA}
          </Text>
        </>
      ) : (
        <div className="flex-1">
          <VirtualizedList
            rowCount={data.length + +hasNextPage}
            hasMore={hasNextPage}
            fetchMore={fetchNextPage}
            dataLength={data.length}
            scrollTop={postScrollY}
            onScroll={onScrollY}
            cache={cachePost}
            overscanRowCount={5}
            RowItem={({ index, measure, registerChild, style }) => {
              const item = data[index];
              return (
                <div ref={registerChild} style={style}>
                  {index === 0 && <Banner measure={measure} />}
                  {item ? (
                    <>
                      <PostItem
                        className="p-4"
                        data={item}
                        onClickAvatar={onClickAvatar}
                        onLoad={measure}
                      />
                      <hr className="solid my-8" />
                    </>
                  ) : (
                    <ItemLoading index={index} />
                  )}
                </div>
              );
            }}
          />
        </div>
      )}
    </>
  );
}

const ItemLoading = ({ index }: { index: number }) => {
  const animation =
    index % 2 === 0 ? `animate-pulse` : `animate-pulse [animation-delay:-0.1s]`;
  return (
    <div className={`flex w-full px-4 py-2`}>
      <div
        className={`h-16 w-16 rounded-full bg-gray-300 mr-3 ${animation} self-start align-top`}
      />
      <div className="flex flex-col flex-1">
        <div
          className={`rounded-xl h-6 w-1/2 border border-neutral-200 bg-gray-300 text-gray-300 ${animation}`}
        ></div>
        <div
          className={`mt-2 rounded-xl h-4 w-1/2 border border-neutral-200 bg-gray-300 text-gray-300 ${animation}`}
        ></div>
        <div
          className={`mt-8 rounded-xl h-64 border border-neutral-200 bg-gray-300 text-gray-300 ${animation}`}
        ></div>
      </div>
    </div>
  );
};

export default React.memo(PostView);
