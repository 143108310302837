import { CSSProperties, ReactElement } from 'react';
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  InfiniteLoader,
  List,
  ListRowProps,
  ScrollParams,
} from 'react-virtualized';

type Props = {
  fetchMore: () => void;
  hasMore: boolean;
  dataLength: number;
  listWidth?: number;
  listHeight?: number;
  rowHeight?: number;
  overscanRowCount?: number;
  rowCount: number;
  RowItem: (props: {
    index: number;
    measure: () => void;
    registerChild?: any;
    style: CSSProperties;
  }) => ReactElement;
  scrollTop?: number;
  onScroll?: (params: ScrollParams) => void;
  cache?: CellMeasurerCache;
};

const cacheDefault = new CellMeasurerCache({
  defaultHeight: 50,
  fixedWidth: true,
});

const VirtualizedList = ({
  rowCount,
  rowHeight,
  listWidth,
  listHeight,
  RowItem,
  dataLength,
  fetchMore,
  hasMore,
  overscanRowCount = 5,
  scrollTop,
  onScroll,
  cache = cacheDefault,
}: Props) => {
  const rowRenderer = ({ index, key, parent, style }: ListRowProps) => {
    return (
      <CellMeasurer
        cache={cache}
        columnIndex={0}
        key={key}
        parent={parent}
        rowIndex={index}
      >
        {({ measure, registerChild }: any) => {
          return (
            <RowItem
              index={index}
              measure={measure}
              registerChild={registerChild}
              style={style}
            />
          );
        }}
      </CellMeasurer>
    );
  };

  return (
    <InfiniteLoader
      isRowLoaded={({ index }) => !hasMore || index < dataLength}
      loadMoreRows={async () => fetchMore()}
      rowCount={rowCount}
    >
      {({ onRowsRendered, registerChild }) => (
        <>
          <AutoSizer>
            {({ height, width }) => {
              return (
                <List
                  ref={registerChild}
                  width={listWidth || width}
                  height={listHeight || height}
                  onRowsRendered={onRowsRendered}
                  rowCount={rowCount}
                  rowHeight={rowHeight || cache.rowHeight}
                  rowRenderer={rowRenderer}
                  overscanRowCount={overscanRowCount}
                  scrollTop={scrollTop}
                  onScroll={onScroll}
                />
              );
            }}
          </AutoSizer>
        </>
      )}
    </InfiniteLoader>
  );
};

export default VirtualizedList;
