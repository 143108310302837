import React from 'react';
import { Button, Modal, Text } from 'components';
import { TEXT_STRING } from '../../../../constants';

type Props = {
  open?: boolean;
  onConfirm: () => void;
};

function ModalWarningGame({ open = false, onConfirm }: Props) {
  return (
    <>
      <Modal
        open={open}
        className="p-16px text-center mx-5 bg-white w-full"
        onClose={() => {}}
      >
        <div className="pt-3 flex flex-col gap-5">
          <div>
            <Text className="mt-6 ml-1" bold center>
              {TEXT_STRING.GAME_PAGE.GAME_SINGLE_NOTE}
            </Text>
          </div>
          <div className="flex flex-col gap-5 mt-2">
            <Button
              className="py-5 font-normal rounded-[8px]"
              onClick={onConfirm}
              block
              bold
            >
              {TEXT_STRING.CAST_PAGE.OK}
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default ModalWarningGame;
