import moment from 'moment';

const getNumberOfNights = (startDate: string, endDate: string) => {
  const startTour = moment(startDate);
  const endTour = moment(endDate);

  const duration = endTour
    .startOf('day')
    .diff(startTour.startOf('day'), 'days');
  const daysDifference = duration + 1;

  const nightCount = Math.max(daysDifference - 1, 0);

  return nightCount;
};

export default getNumberOfNights;
