import { TSetting } from 'types';
import { create } from 'zustand';

type State = {
  openTutorial: boolean;
  settingNoti: TSetting;
  settingEmail: TSetting;
};

type Actions = {
  setOpenTutorial: (value: boolean) => void;
  setSettingNoti: (value: TSetting) => void;
  setSettingEmail: (value: TSetting) => void;
};

const useSettingStore = create<State & Actions>((set) => ({
  openTutorial: false,
  settingNoti: {},
  settingEmail: {},
  setOpenTutorial: (value: boolean) => set(() => ({ openTutorial: value })),
  setSettingNoti: (value: TSetting) => set(() => ({ settingNoti: value })),
  setSettingEmail: (value: TSetting) => set(() => ({ settingEmail: value })),
}));

export default useSettingStore;
