import axios from 'axios';
import { storageKeys } from '../constants';

const checkIdentify = async (
  phone: string
): Promise<{
  isValidUser: boolean;
  isDisabledUser: boolean;
  message: string;
  phone: string;
}> => {
  return await axios
    .get(`accounts/check-user-identity/${encodeURIComponent(phone)}`)
    .then(function (response) {
      return response.data;
    });
};

const signup = async (
  body: {
    name: string;
    email: string;
    phone: string;
    clientId: string;
  },
  token: string
): Promise<void> => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const res = await axios
    .post(`accounts/register`, body, config)
    .then(function (response) {
      return response.data;
    });

  axios.defaults.headers.common['Authorization'] = 'Bearer ' + res.accessToken;

  localStorage.setItem(storageKeys.ACCESS_TOKEN, res.accessToken);
  localStorage.setItem(storageKeys.REFRESH_TOKEN, res.refreshToken);
};

const login = async (token: string, fcmToken?: string): Promise<void> => {
  const clientId = localStorage.getItem(storageKeys.CLIENT_ID);
  const config = {
    headers: { Authorization: `Bearer ${token}` },
    params: {
      clientId,
      fcmToken,
    },
  };
  const res = await axios
    .get(`accounts/login`, config)
    .then(function (response) {
      return response.data;
    });

  axios.defaults.headers.common['Authorization'] = 'Bearer ' + res.accessToken;

  localStorage.setItem(storageKeys.ACCESS_TOKEN, res.accessToken);
  localStorage.setItem(storageKeys.REFRESH_TOKEN, res.refreshToken);
};

const logout = async (fcmToken?: string): Promise<void> => {
  return await axios
    .get(`accounts/logout`, {
      params: {
        fcmToken,
      },
    })
    .then(function (response) {
      return response.data;
    });
};

export { checkIdentify, signup, login, logout };
