import Avatar from 'components/Avatar';
import ConcatText from 'components/ConcatText';
import Text from 'components/Text';
import { TEXT_STRING } from '../../constants';
import Rating from 'components/Rating';
import TextField from 'components/TextField';
import { TResFemaleReview } from 'types';

const STR = TEXT_STRING.REVIEW_GIRL;

type Props = {
  female: TResFemaleReview;
  star?: number;
  onRating: (star: number) => void;
  review?: string;
  onComment?: (review: string) => void;
};

function ReviewForm({
  star = 0,
  review = '',
  onComment,
  onRating,
  female,
}: Props) {
  return (
    <div className="flex flex-col gap-5">
      <div className="flex items-center gap-5">
        <Avatar circle size="w-20" url={female.avatar} />
        <ConcatText
          prefixText={female.nickname}
          suffixText={female.femaleId}
          className="font-bold text-[14px]"
        />
      </div>
      <div className="flex items-center gap-20">
        <Text
          bold
          className="border-r border-solid border-neutral-800 pr-5"
          fontSize={16}
        >
          {STR.RATING}
        </Text>
        <Rating
          value={star}
          isEdit
          onClick={onRating}
          className="scale-[2] mb-2"
        />
      </div>
      <TextField
        name={'review'}
        value={review}
        onChange={(e) => onComment && onComment(e.target.value)}
        placeholder={STR.TEXT_COMMENT}
        type="textarea"
        rows={4}
      />
    </div>
  );
}

export default ReviewForm;
