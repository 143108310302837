import Text from 'components/Text';
import { MAX_ORDER_GIRL, RANK_STYLES, TEXT_STRING } from '../../constants';
import IconButton from 'components/IconButton';
import RankPremium from '../../assets/images/rankPremium.svg';
import { TRank } from 'types';
import { getFormattedAmount } from 'utils';

const COMMON_STR = TEXT_STRING.COMMON;

type Props = {
  count: number;
  onMinus: () => void;
  onPlus: () => void;
  rank: TRank;
  disabled?: boolean;
};

const CardRank = ({
  count = 0,
  onMinus,
  onPlus,
  rank,
  disabled = false,
}: Props): JSX.Element => {
  return (
    <div className="w-full rounded-2xl border shadow-md">
      <div
        className="rounded-t-2xl p-4 relative bg-cover"
        style={{
          backgroundImage: `url(${RANK_STYLES[rank.id].bg || RankPremium})`,
        }}
      >
        <Text
          textColor="text-white"
          bold
          fontSize={14}
        >
          {rank.description}
        </Text>
        <div className="flex justify-between">
          <Text
            textColor="text-white"
            fontSize={18}
            bold
          >
            {rank.rank}
          </Text>
          <Text
            textColor="text-white"
            fontSize={18}
            bold
          >
            {getFormattedAmount(rank.price)} {COMMON_STR.YEN_HOUR}
          </Text>
        </div>
      </div>
      <div className="w-full flex justify-end items-end px-4 pt-10 pb-4">
        <div className="flex items-center gap-4">
          <IconButton
            onClick={onMinus}
            icon={'minusCircle'}
            transparent
            disabled={count <= 0}
          />
          <Text
            fontSize={18}
            bold
          >
            {count} {COMMON_STR.PEOPLE}
          </Text>
          <IconButton
            onClick={onPlus}
            icon={'plusCircle'}
            transparent
            disabled={disabled || count >= MAX_ORDER_GIRL}
          />
        </div>
      </div>
    </div>
  );
};

export default CardRank;
