import { TEXT_STRING } from '../constants';

const formatNumber = (value: string | number) => {
  return new Intl.NumberFormat().format(+value);
};

const formatAmountNumber = (
  value: string | number,
  currency = TEXT_STRING.COMMON.YEN,
  style = 'decimal'
) => {
  return `${new Intl.NumberFormat('ja-JP', {
    style,
  }).format(+value)}${currency}`;
};

export { formatNumber, formatAmountNumber };
