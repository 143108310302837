import { useInfiniteQuery } from '@tanstack/react-query';
import { getCardPaymentList } from 'api';
import Button from 'components/Button';
import PaymentCardList from 'components/PaymentCardList';
import PaymentForm from 'components/PaymentForm';
import Text from 'components/Text';
import { PAGE_SIZE, TEXT_STRING, queryKeys } from '../../constants';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { TCard } from 'types';
import { getFormattedAmount } from 'utils';
import Loading from 'components/Loading';

type Props = {
  title?: string;
  paying: boolean;
  price?: number;
  onPay: (token: string) => Promise<void>;
};

const CardPayment = ({ title, paying, price, onPay }: Props) => {
  // State
  const [selectedCard, setSelectedCard] = useState<TCard>();
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [showForm, setShowForm] = useState<boolean>(false);

  const {
    data: cardData,
    isLoading: cardLoading,
    fetchNextPage,
    hasNextPage,
    refetch,
  } = useInfiniteQuery({
    queryKey: [queryKeys.CARD_PAYMENT],
    queryFn: async ({ pageParam }) => getCardPaymentList(pageParam, PAGE_SIZE),
    initialPageParam: 1,
    getNextPageParam: (lastPage, _, currentPage) => {
      return lastPage?.totalPage > currentPage ? currentPage + 1 : undefined;
    },
  });

  const cards = useMemo(
    () => cardData?.pages.map(({ data }) => data).flat() ?? [],
    [cardData?.pages]
  );

  const handlePayment = useCallback(async () => {
    const cardList = cards?.filter((item) => item.id !== selectedCard?.id);
    let error;
    if (selectedCard?.cardToken) {
      try {
        await onPay(selectedCard.cardToken);
      } catch (err) {
        // ※ 上記でご選択板ファイているカードで決済エラーとなる場合、自動的に他に登録いただいているカードで決済が行われるしようとなっております。
        for (const card of cardList) {
          try {
            await onPay(card.cardToken);
          } catch (err) {
            error = err;
          }
        }
        if (error instanceof Error) {
          setErrorMessage(error.message);
        }
      }
    }
  }, [cards, selectedCard, onPay, setErrorMessage]);

  useEffect(() => {
    cards?.forEach((card) => {
      if (card.isDefault) {
        setSelectedCard(card);
        return;
      }
    });

    return () => {};
  }, [cards]);

  return (
    <>
      <Text className="text-3xl py-6" center bold>
        {title || TEXT_STRING.PAYMENT.PAYMENT_METHOD}
      </Text>
      <Text className="text-3xl pb-5" center bold>
        {getFormattedAmount(price)}
        {TEXT_STRING.COMMON.YEN}
      </Text>
      <Text center fontSize={14}>
        {TEXT_STRING.CALL_WOMAN.PAYMENT_WARNING}
      </Text>

      {cardLoading ? (
        <div className="pt-6">
          <Loading />
        </div>
      ) : !!cards.length && !showForm ? (
        <>
          <PaymentCardList
            data={cards}
            selectedCard={selectedCard}
            hasMore={hasNextPage}
            onSelectCard={(card: TCard) => {
              setSelectedCard(card);
            }}
            fetchMore={fetchNextPage}
            refetchAPI={refetch}
          />
          <Button
            onClick={() => {
              setShowForm(true);
            }}
            bgColor="bg-transparent"
            textColor="text-primary"
            bold
            disabled={paying}
          >
            {TEXT_STRING.PAYMENT.ADD_METHOD}
          </Button>
          <Button
            onClick={handlePayment}
            block
            disabled={!selectedCard || paying}
            loading={paying}
          >
            {TEXT_STRING.COMMON.CONTINUE}
          </Button>
        </>
      ) : (
        <>
          <PaymentForm
            submitAction={{
              action: onPay,
              loading: paying,
              disabled: paying,
            }}
          />
          {showForm && (
            <Button
              onClick={() => {
                setShowForm(false);
              }}
              bgColor="bg-transparent"
              textColor="text-primary"
              block
              padding="py-0"
              disabled={paying}
            >
              {TEXT_STRING.COMMON.CANCEL}
            </Button>
          )}
        </>
      )}
      {errorMessage && <Text textColor="text-error py-2">{errorMessage}</Text>}
    </>
  );
};

export default CardPayment;
