import Text from 'components/Text';

interface DividerProps {
  title: string;
  className?: string;
  lineClassName?: string;
  titleClassName?: string;
  fontSize?: number;
}

function Divider({
  title,
  className,
  lineClassName,
  titleClassName,
  fontSize = 18,
}: DividerProps) {
  return (
    <div className={`${className} flex justify-center items-center`}>
      <span className={`${lineClassName} bg-black h-1px flex-1`} />
      <Text
        bold
        className={`${titleClassName} flex-1 px-2`}
        center
        nowrap
        fontSize={fontSize}
      >
        {title}
      </Text>
      <span className={`${lineClassName} bg-black h-1px flex-1`} />
    </div>
  );
}

export default Divider;
