import { Button, Modal, Text } from 'components';
import { TEXT_STRING } from '../../constants';

const COMMON_STR = TEXT_STRING.COMMON;

type TTextModal = {
  title: string;
  content: string;
  buttonConfirm: string;
};

const colors = {
  warning: { bgColor: 'bg-warning' },
  error: { bgColor: 'bg-error' },
};

type Props = {
  open?: boolean;
  loading?: boolean;
  onClose: () => void;
  onConfirm: () => void;
  text?: TTextModal;
  type?: keyof typeof colors;
};

function ModalConfirm({
  open = false,
  loading = false,
  onClose,
  onConfirm,
  text,
  type = 'error',
}: Props) {
  return (
    <Modal
      open={open}
      position="center"
      onClose={onClose}
      className="bg-white p-5 flex flex-col justify-between gap-5 "
      w="w-3/4"
    >
      <Text bold center className="text-lg">
        {text?.title}
      </Text>
      <Text center>{text?.content}</Text>
      <div className="flex gap-4">
        <Button
          onClick={onClose}
          className="w-full py-4"
          bgColor="bg-transparent"
          textColor="text-neutral-700"
          border="border-neutral border"
          disabled={loading}
        >
          {COMMON_STR.CANCEL}
        </Button>
        <Button
          onClick={onConfirm}
          className="w-full py-4"
          loading={loading}
          disabled={loading}
          {...colors[type]}
        >
          {text?.buttonConfirm || COMMON_STR.YES}
        </Button>
      </div>
    </Modal>
  );
}

export default ModalConfirm;
