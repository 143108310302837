import {
  CollectionsImage,
  ProfileBiography,
  Header,
  ProfileInfo,
  Loading,
  Text,
  ProfileRank,
  PostList,
} from 'components';
import {
  ONE_MAN_JPY,
  PAGE_SIZE,
  queryKeys,
  TEXT_STRING,
} from '../../../../constants';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useInfiniteQuery } from '@tanstack/react-query';
import { profileField } from 'helpers';
import { EStatusProfile } from 'types';
import { getDetailPosts } from 'api';
import { getFormattedAmount } from 'utils';
import { useUserStore } from 'store';
import { useContext, useMemo } from 'react';
import { EditProfileContext } from 'contexts';

const profileStr = TEXT_STRING.MALE_PAGE;
const commonStr = TEXT_STRING.COMMON;
const rankStr = TEXT_STRING.PROFILE_RANK;

function PreviewMale() {
  // Hooks
  const { user } = useUserStore();
  const { draftUser } = useContext(EditProfileContext);

  // Query
  const {
    data: postData,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery({
    queryKey: [queryKeys.MALE_POST, user?.id],
    queryFn: async ({ pageParam }) =>
      getDetailPosts({
        maleId: user?.id || 0,
        currentPage: pageParam,
        pageSize: PAGE_SIZE,
      }),
    initialPageParam: 1,
    getNextPageParam: (lastPage, _, currentPage) => {
      return lastPage?.totalPage > currentPage ? currentPage + 1 : undefined;
    },
    enabled: !!user?.id,
  });

  // Memo, callbacks
  const basicInfo = useMemo(() => {
    const { invoice, order } = user?.total || {};
    const TEN_MAN_JPY = 10 * ONE_MAN_JPY;
    const estimateAmount = Math.floor((invoice || 0) / TEN_MAN_JPY) * 10;

    const displayUtilizationAmount = () => {
      if (invoice === 0) return commonStr.UNUSED;
      if (estimateAmount > 0) {
        return rankStr.OVER_ONE_MAN.replace(
          '$amount',
          getFormattedAmount(estimateAmount)
        );
      } else {
        return rankStr.LESS_ONE_MAN.replace('$amount', '10');
      }
    };
    return [
      {
        label: profileStr.UTILIZATION_AMOUNT,
        value: displayUtilizationAmount(),
      },
      {
        label: profileStr.TOTAL_USES,
        value: !order
          ? commonStr.UNUSED
          : getFormattedAmount(order) + commonStr.COUNT,
      },
    ];
  }, [user?.total]);

  const postListView = useMemo(
    () => postData?.pages.map(({ data }) => data).flat() ?? [],
    [postData?.pages]
  );

  const listCollection = useMemo(() => {
    const newCollection = draftUser?.newCollection || [];
    const collections = (user?.profileCollection?.collection || [])
      .filter((item) => {
        const isPending = item.status === EStatusProfile.PENDING;
        return (
          (!draftUser?.cancelCollection?.includes(item.id) && isPending) ||
          (!draftUser?.deleteCollection?.includes(item.id) && !isPending)
        );
      })
      .map((data) => data.url);

    return [...newCollection, ...collections].map((item, index) => ({
      id: index + 1,
      url: item,
    }));
  }, [draftUser, user?.profileCollection?.collection]);

  return (
    <div className="h-full">
      <Header title={profileStr.PROFILE_DETAIL} textColor="text-primary" />
      <div className="h-full overflow-hidden">
        <InfiniteScroll
          next={() => fetchNextPage()}
          hasMore={hasNextPage}
          loader={
            <div className="py-[36px] flex justify-center items-center">
              <Loading />
            </div>
          }
          dataLength={postListView.length}
          scrollThreshold={0.5}
          height={'calc(100dvh - 68px - 46px)'}
        >
          <CollectionsImage
            avatar={draftUser?.avatar ?? user?.avatar}
            collections={listCollection}
          />
          <div className="p-4 flex flex-col gap-4">
            <ProfileRank className="mb-4" star={user?.total.ranking} />
            {!!(draftUser?.showInfo ?? user?.total.showInvoiceAndOrder) && (
              <ProfileInfo data={basicInfo} title={profileStr.USING_TECO} />
            )}
            {profileField.map((item, index) => {
              const value =
                draftUser?.[item.name] ?? getFormattedAmount(user?.[item.name]);
              return (
                <div className="flex flex-wrap mb-2" key={index}>
                  <Text bold fontSize={14} className="basis-2/5">
                    {item.label}
                  </Text>
                  <Text fontSize={14} className="basis-3/5 pl-4">
                    {+value !== 0
                      ? `${value} ${item.unit}`
                      : commonStr.UNREGISTERED}
                  </Text>
                </div>
              );
            })}
            <ProfileBiography
              note={
                draftUser?.biography ??
                user?.profileCollection?.biography?.content
              }
            />
            <Text
              bold
              center
              fontSize={14}
              className="py-8"
              textColor="text-primary"
            >
              {commonStr.RECENT_TWEETS}
            </Text>

            <PostList data={postListView} />
          </div>
        </InfiniteScroll>
      </div>
    </div>
  );
}

export default PreviewMale;
