import { EActionChat } from 'types';
import { create } from 'zustand';

type State = {
  actionChatStep?: EActionChat;
  showReview?: boolean;
  leveragePendingCreation?: string;
  leveragePendingConfirmationId?: number;
};

type Actions = {
  setActionChatStep: (value?: EActionChat) => void;
  setShowReview: (value: boolean) => void;
  setLeveragePendingCreation: (value?: string) => void;
  setLeveragePendingConfirmationId: (value: number) => void;
  removeLeveragePending: () => void;
};

const useChatStore = create<State & Actions>((set) => ({
  actionChatStep: undefined,
  showReview: false,
  setActionChatStep: (value?: EActionChat) =>
    set(() => ({ actionChatStep: value })),
  setShowReview: (value: boolean) => set(() => ({ showReview: value })),
  setLeveragePendingCreation: (value?: string) =>
    set(() => ({ leveragePendingCreation: value })),
  setLeveragePendingConfirmationId: (value: number) =>
    set(() => ({
      leveragePendingConfirmationId: value,
    })),
  removeLeveragePending: () =>
    set(() => ({
      leveragePendingCreation: undefined,
      leveragePendingConfirmationId: undefined,
    })),
}));

export default useChatStore;
