import { useCallback, useState } from 'react';
import NoAvatar from '../../assets/images/avatar.svg';
import Modal from 'components/Modal';
import IconButton from 'components/IconButton';

type Props = {
  circle?: boolean;
  size?: `w-${string}`;
  url?: string;
  onClick?: () => void;
  canViewFull?: boolean;
};

function Avatar({
  circle = false,
  size = `w-full`,
  url,
  onClick,
  canViewFull,
}: Props) {
  const [openFullScreen, setOpenFullScreen] = useState<boolean>(false);

  const handleClick = useCallback(() => {
    if (canViewFull) {
      setOpenFullScreen(true);
    } else onClick && onClick();
  }, [canViewFull, onClick]);

  return (
    <>
      <img
        className={`inline-block aspect-square object-cover object-top
			    ${size} ${circle ? 'rounded-full' : ''} ${
          onClick || canViewFull ? ' cursor-pointer' : ''
        }`}
        src={url || NoAvatar}
        alt=""
        onClick={handleClick}
        onError={(e) => (e.currentTarget.src = NoAvatar)}
      />
      <Modal
        open={openFullScreen && !!url}
        onClose={() => {
          setOpenFullScreen(false);
        }}
      >
        <div
          className="h-screen w-full flex items-center overflow-hidden"
          onClick={() => {
            setOpenFullScreen(false);
          }}
        >
          <IconButton
            icon="close"
            transparent
            className="fixed top-0 left-0 z-50 m-4"
            onClick={(e) => {
              e.stopPropagation();
              setOpenFullScreen(false);
            }}
            iconColor="white"
          />
          <img
            className={`w-full`}
            src={url || NoAvatar}
            alt=""
            onError={(e) => (e.currentTarget.src = NoAvatar)}
          />
        </div>
      </Modal>
    </>
  );
}

export default Avatar;
