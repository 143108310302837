import React, { PropsWithChildren } from 'react';
import { TPostMedia } from 'types';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { IMAGE_ACCEPTED } from '../../constants';

type Props = PropsWithChildren<{
  data: TPostMedia[];
  selectedItem?: number;
  autoPlay?: boolean;
  showThumbs?: boolean;
  showArrows?: boolean;
  infiniteLoop?: boolean;
  emulateTouch?: boolean;
  showStatus?: boolean;
}>;

function MediaSlide({
  data,
  selectedItem,
  autoPlay = false,
  showThumbs = false,
  showArrows = false,
  infiniteLoop = true,
  emulateTouch = true,
  showStatus = false,
}: Props) {
  return (
    <div
      className=""
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Carousel
        autoPlay={autoPlay}
        showThumbs={showThumbs}
        showArrows={showArrows}
        infiniteLoop={infiniteLoop}
        emulateTouch={emulateTouch}
        preventMovementUntilSwipeScrollTolerance
        swipeScrollTolerance={20}
        showStatus={showStatus}
        showIndicators={data.length > 1}
        selectedItem={selectedItem}
      >
        {data.map(({ url, type }, index) => (
          <div
            key={index}
            className="h-full flex m-auto"
          >
            {IMAGE_ACCEPTED.includes(type) ? (
              <img
                alt=""
                src={url}
                className="object-contain"
              />
            ) : (
              <video
                controls
                autoPlay
                muted
                playsInline
                loop
              >
                <source src={url} />
              </video>
            )}
          </div>
        ))}
      </Carousel>
    </div>
  );
}

export default MediaSlide;
