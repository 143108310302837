import Text from 'components/Text';
import { TOrderHistory } from 'types';
import { formatDate } from 'utils';
import ListItem from './ListItem';
import { Fragment } from 'react';

type Props = {
  data: TOrderHistory[];
  onSelectOrder(item: TOrderHistory): void;
};

const DATE_FORMAT = 'LL';

const OrderHistoryList = ({ data, onSelectOrder }: Props) => {
  return (
    <div className="flex flex-col gap-4 py-4">
      {data.map((item, index, arr) => {
        const date = formatDate(item.createdDatetime, DATE_FORMAT);
        return (
          <Fragment key={item.id}>
            {formatDate(arr[index - 1]?.createdDatetime, DATE_FORMAT) !==
              date && <Text className="mt-4">{date}</Text>}
            <ListItem data={item} onClick={onSelectOrder} />
          </Fragment>
        );
      })}
    </div>
  );
};

export default OrderHistoryList;
