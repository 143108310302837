import { useFollowStore } from 'store';
import { useCallback } from 'react';
import { useMutation } from '@tanstack/react-query';
import { followFemale, unFollowFemale } from 'api';

function useFollow() {
  const { loadingFollow, addToFollowingList, removeToFollowingList } =
    useFollowStore();

  const followFemaleMutation = useMutation({
    mutationFn: (id: number) => {
      return followFemale(id);
    },
  });
  const unFollowFemaleMutation = useMutation({
    mutationFn: (id: number) => {
      return unFollowFemale(id);
    },
  });

  const handleFollow = useCallback(
    async (id: number, followed: boolean) => {
      try {
        addToFollowingList(id);
        if (followed) {
          await unFollowFemaleMutation.mutateAsync(id);
        } else {
          await followFemaleMutation.mutateAsync(id);
        }
      } catch (e) {
        console.log(e);
      } finally {
        removeToFollowingList(id);
      }
    },
    [
      addToFollowingList,
      followFemaleMutation,
      removeToFollowingList,
      unFollowFemaleMutation,
    ]
  );

  return {
    handleFollow,
    loadingFollow,
  };
}

export default useFollow;
