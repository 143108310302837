import Text from 'components/Text';
import { TEXT_STRING } from '../../constants';
import { PropsWithChildren } from 'react';
import Icon from 'components/Icon';

type ChipProps = PropsWithChildren<{
  className?: string;
  isMeeting?: boolean;
}>;

const ChipMeeting = ({
  className = '',
  isMeeting = false,
}: ChipProps): JSX.Element => {
  return (
    <>
      {isMeeting && (
        <span className={`${className} flex items-center gap-2`}>
          <Icon
            icon={'groupUser'}
            color="black"
          />
          <Text textColor="text-primary">{TEXT_STRING.COMMON.MEETING}</Text>
        </span>
      )}
    </>
  );
};

export default ChipMeeting;
