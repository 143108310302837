import Text from 'components/Text';
import { RANK_STYLES } from '../../constants';
import { PropsWithChildren, useMemo } from 'react';
import { TRank } from 'types';

type ChipProps = PropsWithChildren<{
  className?: string;
  rank?: TRank;
}>;

const ChipRank = ({ className = '', rank }: ChipProps): JSX.Element => {
  const styles = useMemo(
    () => RANK_STYLES[rank?.id || 1] || RANK_STYLES[1],
    [rank?.id]
  );
  return (
    <>
      {rank && (
        <div
          className={`${className} rounded-lg py-1.5 px-3.5 relative w-32 bg-cover`}
          style={{
            backgroundImage: `url(${styles.bg})`,
          }}
        >
          <Text textColor={styles.color} bold center className="line-clamp-1">
            {rank.rank}
          </Text>
        </div>
      )}
    </>
  );
};

export default ChipRank;
