import { useEffect, useMemo, useState } from 'react';
import { TCollections } from 'types';
import { getImageURL } from 'utils';
import NoAvatar from '../../../assets/images/avatar.svg';

type Props = {
  avatar?: string | File;
  collections?: TCollections[];
};

function CollectionsImage({ avatar = '', collections = [] }: Props) {
  const [selectedImage, setSelectedImage] = useState<TCollections>({
    id: 0,
    url: '',
  });

  const listCollection = useMemo(() => {
    return [{ id: 0, url: avatar }, ...collections];
  }, [avatar, collections]);

  useEffect(() => {
    setSelectedImage({
      id: 0,
      url: avatar,
    });
  }, [avatar]);

  return (
    <div className="w-full">
      <>
        <img
          src={getImageURL(selectedImage?.url) || NoAvatar}
          alt=""
          className="w-full object-cover object-center"
          onError={(e) => (e.currentTarget.src = NoAvatar)}
        />
        <div className="pt-3 px-4">
          <div className="flex gap-[15px] overflow-x-auto w-full pb-16px">
            {listCollection?.map((image) => {
              const isSelected = image.id === selectedImage.id;
              return (
                <div
                  className={`max-w-[90px] aspect-square relative overflow-hidden h-full w-full flex-shrink-0 cursor-pointer ${
                    isSelected ? 'border-2 border-primary' : ''
                  }`}
                  key={image?.id}
                  onClick={() => setSelectedImage({ ...image })}
                >
                  <img
                    src={getImageURL(image.url) || NoAvatar}
                    alt=""
                    className="w-full h-full object-cover object-top"
                    onError={(e) => (e.currentTarget.src = NoAvatar)}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </>
    </div>
  );
}

export default CollectionsImage;
