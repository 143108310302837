import IconButton from 'components/IconButton';
import Text from 'components/Text';
import { TEXT_STRING } from '../../constants';
import React, { useMemo } from 'react';

type Props = {
  unit?: string;
  quantity: number;
  onIncrease: () => void;
  onDecrease: () => void;
  positionQuantity?: 'left' | 'right' | 'center';
  className?: string;
  showAction?: boolean;
  disabledDecrease?: boolean;
  disabledIncrease?: boolean;
  min?: number;
  max?: number;
};

function QuantitySelector({
  unit = TEXT_STRING.COMMON.INDIVUAL,
  quantity,
  onIncrease,
  onDecrease,
  positionQuantity = 'left',
  className = 'gap-4',
  showAction = true,
  disabledDecrease = false,
  disabledIncrease = false,
  min = 0,
  max = Infinity,
}: Props) {
  const quantityItem = useMemo(
    () => <Text className="px-3">{`${quantity}${unit}`}</Text>,
    [quantity, unit]
  );

  return (
    <div className={`${className} flex w-full items-center`}>
      {positionQuantity === 'left' && quantityItem}
      {showAction && (
        <IconButton
          onClick={onDecrease}
          icon={'minus'}
          iconColor="black"
          circle
          transparent
          className="border-2 border-solid border-black w-8 aspect-square justify-center"
          size="scale-[0.8]"
          disabled={quantity <= min || disabledDecrease}
        />
      )}
      {positionQuantity === 'center' && quantityItem}
      {showAction && (
        <IconButton
          onClick={onIncrease}
          icon={'plus'}
          iconColor="black"
          circle
          transparent
          className="border-2 border-solid border-black w-8 aspect-square justify-center"
          size="scale-[0.8]"
          disabled={quantity >= max || disabledIncrease}
        />
      )}
      {positionQuantity === 'right' && quantityItem}
    </div>
  );
}

export default QuantitySelector;
