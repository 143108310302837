import { Combobox } from '@headlessui/react';
import ConcatText from 'components/ConcatText';
import { MIN_NUMBER_SEARCH, TEXT_STRING } from '../../constants';
import { TFemale } from 'types';
import Text from 'components/Text';
import InfiniteScroll from 'react-infinite-scroll-component';
import Icon from 'components/Icon';

type Props = {
  data: TFemale[];
  searchValue: string;
  onSearchValue: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSelectValue: (value: TFemale) => void;
  selectedData: TFemale[];
  placeholder?: string;
  fetchMore: () => void;
  hasMore: boolean;
  loading?: boolean;
  disabledOptions?: boolean;
};

function InputSelect({
  data,
  selectedData,
  onSearchValue,
  placeholder = '',
  onSelectValue,
  searchValue,
  hasMore,
  fetchMore,
  loading = false,
  disabledOptions = false,
}: Props) {
  return (
    <Combobox>
      <div className="relative w-full">
        <Combobox.Button className="w-full">
          <Combobox.Input
            autoComplete="off"
            value={searchValue}
            placeholder={placeholder}
            onChange={onSearchValue}
            className={
              'w-full flex items-center py-3.5 rounded-lg ring-1 ring-neutral-300 focus-within:ring-2 focus-within:ring-primary border-0 bg-transparent placeholder:text-neutral-300'
            }
          />
        </Combobox.Button>
        <div
          className={`${
            searchValue.trim().length >= MIN_NUMBER_SEARCH ? 'block' : 'hidden'
          } absolute z-10 w-full mt-3 `}
        >
          <Combobox.Options
            className={`w-full h-full max-h-40 overflow-y-auto flex flex-col border border-solid border-neutral-400 rounded-xl shadow-xl bg-white`}
            id="scrollOptions"
          >
            {loading ? (
              <>
                {Array.from(Array(2)).map((_, index) => (
                  <div
                    key={index}
                    className="flex w-full items-center gap-4 p-4 pointer-events-none"
                  >
                    <div className="w-10 aspect-square rounded-full animate-pulse bg-neutral-400"></div>
                    <div className="flex-1 h-6 animate-pulse bg-neutral-400"></div>
                  </div>
                ))}
              </>
            ) : (
              <>
                {!!data?.length ? (
                  <InfiniteScroll
                    next={fetchMore}
                    hasMore={hasMore}
                    loader={
                      <div className="flex w-full items-center gap-4 p-4 pointer-events-none">
                        <div className="w-10 aspect-square rounded-full animate-pulse bg-neutral-400"></div>
                        <div className="flex-1 h-6 animate-pulse bg-neutral-400"></div>
                      </div>
                    }
                    dataLength={data?.length}
                    scrollThreshold={0.5}
                    scrollableTarget="scrollOptions"
                  >
                    <>
                      {data.map((item) => {
                        const isSelected = selectedData.some(
                          ({ id }) => id === item.id
                        );
                        return (
                          <Combobox.Option
                            key={item.id}
                            value={item.id}
                            className={`${
                              disabledOptions &&
                              'bg-neutral-50 pointer-events-none cursor-not-allowed'
                            } flex w-full items-center gap-4 p-4 cursor-pointer hover:bg-primary-50`}
                            onClick={(e) => {
                              e.preventDefault();
                              !isSelected && onSelectValue(item);
                            }}
                          >
                            <img
                              src={item.avatar}
                              alt=""
                              className="w-10 aspect-square rounded-full object-cover"
                            />
                            <ConcatText
                              prefixText={item.nickname}
                              suffixText={item.userId}
                              className="text-[14px] font-bold flex-1"
                            />
                            {isSelected && <Icon icon="check" />}
                          </Combobox.Option>
                        );
                      })}
                    </>
                  </InfiniteScroll>
                ) : (
                  <Combobox.Option
                    value={undefined}
                    className="flex w-full items-center gap-4 p-4 cursor-pointer pointer-events-none"
                  >
                    <Text center bold>
                      {TEXT_STRING.COMMON.NOT_FOUND_GIRL}
                    </Text>
                  </Combobox.Option>
                )}
              </>
            )}
          </Combobox.Options>
        </div>
      </div>
    </Combobox>
  );
}

export default InputSelect;
