import { PAGE_SIZE, TEXT_STRING, queryKeys, routes } from '../../constants';
import { useInfiniteQuery } from '@tanstack/react-query';
import { Loading, Header, Text } from 'components';
import { useNavigate } from 'react-router-dom';
import { getFollowingList } from 'api';
import { useFollowStore } from 'store';
import { useFollow } from 'hooks';
import React, { useCallback, useEffect, useState } from 'react';
import CardItem from 'pages/Search/CardItem';
import InfiniteScroll from 'react-infinite-scroll-component';
import { TFemale } from 'types';

function FollowingPage() {
  const { handleFollow } = useFollow();
  const { loadingFollow } = useFollowStore();
  const navigate = useNavigate();
  const [listView, setListView] = useState<{
    list: TFemale[];
    page: number;
  }>({
    list: [],
    page: 1,
  });
  const {
    data: listFemale,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isRefetching,
  } = useInfiniteQuery({
    queryKey: [queryKeys.FOLLOWING_LIST],
    queryFn: async ({ pageParam }) => getFollowingList(pageParam, PAGE_SIZE),
    initialPageParam: 1,
    getNextPageParam: (lastPage, _, currentPage) => {
      return lastPage?.totalPage > currentPage ? currentPage + 1 : undefined;
    },
  });

  const onFollowFemale = useCallback(
    (female: TFemale) => {
      handleFollow(female?.userId, female?.isFollowed);
      setListView((prev) => ({
        ...prev,
        list: listView.list.filter((obj) => obj.userId !== female.userId),
      }));
    },
    [handleFollow, listView.list]
  );

  useEffect(() => {
    if (listFemale?.pages) {
      setListView((prev) => {
        const listData =
          prev.page >= listFemale?.pages.length || prev.page === 1
            ? JSON.parse(
                JSON.stringify(listFemale?.pages.map(({ data }) => data).flat())
              )
            : [...prev.list, ...(listFemale?.pages.slice(-1)[0].data ?? [])];
        return { list: listData, page: listFemale?.pages.length };
      });
    }
  }, [listFemale?.pages, setListView]);

  return (
    <>
      {isLoading ? (
        <div className="h-full w-full flex justify-center items-center">
          <Loading />
        </div>
      ) : (
        <div className="flex flex-col h-full px-8px">
          <div className="overflow-y-auto" id="scrollFollowing">
            <Header title={TEXT_STRING.MY_PAGE.LIST_FOLLOWING} />
            <Text className="pl-2 py-16px" textColor="text-primary">
              {TEXT_STRING.FOLLOWING_PAGE.LIST}
            </Text>
            {!listView?.list.length ? (
              <div className="px-8px flex justify-center">
                {TEXT_STRING.COMMON.NO_DATA}
              </div>
            ) : (
              <InfiniteScroll
                next={() => fetchNextPage()}
                hasMore={hasNextPage}
                loader={
                  <div className="py-[36px] flex justify-center items-center">
                    <Loading />
                  </div>
                }
                dataLength={listView?.list.length}
                scrollThreshold={0.5}
                scrollableTarget="scrollFollowing"
              >
                <div className="grid grid-cols-3 gap-x-8px gap-y-16px px-8px pb-16px">
                  {listView?.list.map((female) => {
                    const isLoadingFollow = loadingFollow.includes(
                      female?.userId
                    )
                      ? true || isRefetching
                      : false;
                    return (
                      <CardItem
                        onFollow={() => onFollowFemale(female)}
                        onDetail={() =>
                          navigate(
                            `${routes.DETAIL_FEMALE.replace(
                              ':id',
                              `${female?.userId}`
                            )}`
                          )
                        }
                        disabledFollow={isLoadingFollow}
                        data={female}
                        key={female.id}
                      />
                    );
                  })}
                </div>
              </InfiniteScroll>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default FollowingPage;
