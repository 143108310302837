import { Text } from 'components';
import { TEXT_STRING } from '../../constants';
import { useCallback, useRef, useState } from 'react';
import { EGameType, TVideoGame } from 'types';
import { getFormattedAmount, validateGamePlayManyTime } from 'utils';

type Props = {
  data: TVideoGame;
};

const textBtn: Record<EGameType, string> = {
  [EGameType.COMMON_SPECIAL]: TEXT_STRING.GAME_PAGE.START_APP,
  [EGameType.SINGLE_SPECIAL]: '',
  [EGameType.GROUP_SPECIAL]: TEXT_STRING.GAME_PAGE.START_APP,
  [EGameType.GROUP_KING]: TEXT_STRING.GAME_PAGE.USEFUL_TIMER,
  [EGameType.COMMON]: '',
};

function VideoGame({ data }: Props) {
  const { thumbnail, price, videoUrl, name, currency, unit, redirect, type } =
    data;
  const [isPlaying, setIsPlaying] = useState(false);

  const videoRef = useRef<HTMLVideoElement>(null);
  const handlePlay = useCallback(() => {
    setIsPlaying(true);
    if (videoRef) {
      videoRef?.current?.play();
    }
  }, []);

  return (
    <>
      <div className="bg-primary-400 flex flex-col items-center gap-4 p-4 rounded-lg">
        <div>
          <Text bold center textColor="text-neutral-700">
            {name}
          </Text>
          <Text bold center textColor="text-neutral-900">
            {validateGamePlayManyTime(type) && '1'}
            {unit}
            {getFormattedAmount(price)}
            {currency}
          </Text>
        </div>
        <div className="relative aspect-video">
          <video controls playsInline ref={videoRef} controlsList="nodownload">
            <source src={videoUrl} />
          </video>

          {!isPlaying && (
            <div className="absolute top-0 left-0 w-full h-full">
              <>
                <img
                  src={thumbnail}
                  alt=""
                  className="object-cover w-full h-full absolute"
                />
                <div
                  className="w-full h-full absolute flex justify-center items-center cursor-pointer"
                  onClick={handlePlay}
                >
                  <div className="w-1/3 aspect-square rounded-full border-2 border-solid border-white"></div>
                  <div className="w-0 h-0 ml-1 absolute border-t-[10px] border-t-transparent border-l-[17px] border-l-white border-b-[10px] border-b-transparent"></div>
                </div>
              </>
            </div>
          )}
        </div>
        {redirect && (
          <a
            href={redirect}
            target="_blank"
            rel="noreferrer"
            className="py-2.5 w-10/12 bg-neutral-900 cursor-pointer text-center text-white"
          >
            {textBtn?.[type as EGameType]}
          </a>
        )}
      </div>
    </>
  );
}

export default VideoGame;
