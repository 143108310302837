import React from 'react';
import { routes } from '../constants';
import { Navigate, useLocation } from 'react-router-dom';
import { useUserStore } from 'store';

function ProtectedRoute({ children }: { children: JSX.Element }) {
  const auth = useUserStore();
  const location = useLocation();

  if (!auth.isAuthenticated) {
    // Redirect them to the /signup page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    // return <h1>Protected Route</h1>;
    return <Navigate to={routes.SIGNUP} state={{ from: location }} replace />;
  }

  return children;
}

export default ProtectedRoute;
