import React, { useEffect } from 'react';
import './App.css';
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import Layout from 'layout';
import { ProtectedRoute, routers } from 'routers';
import { routes } from './constants';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { detectMobile } from 'utils';
import { WarningModal, TutorialModal } from 'components';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { onMessageListener } from 'configs';
import ReactGA from 'react-ga4';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 3,
      refetchOnWindowFocus: false,
      refetchOnReconnect: true,
    },
  },
});

// Setup api payment
const PAYMENT_API_TOKEN = process.env.REACT_APP_PAYMENT_API_TOKEN;
const TRACKING_ID = process.env.REACT_APP_GA_MEASUREMENT_ID;

window.TokenPayment.setup(PAYMENT_API_TOKEN, () => {});

function App() {
  const isMobile = detectMobile();
  const navigate = useNavigate();

  React.useEffect(() => {
    (async () => {
      const registration = await navigator.serviceWorker
        .getRegistrations()
        .then((registrations) => {
          return registrations.find((item) =>
            item.scope.includes(window.location.origin)
          );
        });

      onMessageListener((msg) => {
        const location = window.location.pathname;
        if (
          !location.includes(msg.data?.key || '') &&
          msg.notification?.title &&
          registration
        ) {
          registration.showNotification(msg.notification?.title || '', {
            body: msg.notification?.body || '',
            data: msg.data,
          });
        }
      });
    })();
  }, []);

  useEffect(() => {
    const handleNotificationClick = (payload: any) => {
      navigate(payload.data);
    };
    const channel = new BroadcastChannel('notifications');
    channel.addEventListener('message', handleNotificationClick);

    return () => {
      channel.removeEventListener('message', handleNotificationClick);
    };
  }, [navigate]);

  useEffect(() => {
    if (TRACKING_ID) {
      ReactGA.initialize(TRACKING_ID);
      // Send pageview with a custom path
      ReactGA.send({
        hitType: 'pageview',
        page: '/',
        title: 'TECO APPLICATION',
      });
    }
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <div
        id="app"
        className={`${
          !isMobile ? `w-[50vh] relative mx-auto translate-x-0` : ``
        } h-[100dvh] overflow-hidden bg-white`}
      >
        <Routes>
          <Route element={<Layout />}>
            <Route path="/" element={<Navigate to={routes.SEARCH} />} />
            {routers.map(
              ({ path, component: PageComponent, isProtected, children }) => (
                <Route
                  key={path}
                  path={path}
                  element={
                    isProtected ? (
                      <ProtectedRoute>
                        <PageComponent />
                      </ProtectedRoute>
                    ) : (
                      <PageComponent />
                    )
                  }
                >
                  {children?.map((child) => {
                    const ChildComponent = child.component;
                    return (
                      <Route
                        key={child.path}
                        path={child.path}
                        element={<ChildComponent />}
                        index={child.index}
                      />
                    );
                  })}
                </Route>
              )
            )}
          </Route>
        </Routes>
        <WarningModal />
        <TutorialModal />
        <div id="recaptcha-container" className="invisible"></div>
      </div>
      <ToastContainer
        position="bottom-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover={false}
        theme="light"
      />
    </QueryClientProvider>
  );
}

export default App;
